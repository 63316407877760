import {Observable} from "rxjs";
import {map} from "rxjs/operators";

import {MapadminProvider, Coordinates} from "./mapadmin-provider";
import {AuthService} from "../../auth/auth.service";

import * as _ from "lodash";
import {Injectable, Inject} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ApiMapBaseUrlToken} from "src/app/map/base-map/shapefile-loader";

@Injectable({
  providedIn: "root"
})
export class ApiMapadminProvider implements MapadminProvider {
  constructor(private httpClient: HttpClient, private authService: AuthService, @Inject(ApiMapBaseUrlToken) private mapBaseUrl?: string) {}

  setCoordinates(coordinates: Coordinates): Observable<Object> {
    const url = this.mapBaseUrl + "/" + this.authService.realmId;
    return this.httpClient.put(url, coordinates);
  }
}
