import { Injectable } from "@angular/core";

import {Observable} from "rxjs";
import {map} from "rxjs/operators";

import {Planningvariant, PlanningvariantProvider, PlanningvariantWithPolygons} from "./planningvariant-provider";
import {
  Planningvariant as APIPlanningvariant,
  PerimeterFront,
  VertexFront,
  PerimeterVertexConnectionFront,
  PlanningvariantWithGisDataFront
} from "src/app/API/generated/clients";

import * as _ from "lodash";
import {v4 as uuid} from "uuid";

import {PlanungsvariantenGeneratedClient} from "src/app/API/generated/clients";
import {AuthService} from "src/app/services/auth/auth.service";

@Injectable({
  providedIn: "root"
})
export class ApiPlanningvariantProvider implements PlanningvariantProvider {
  constructor(private apiClient: PlanungsvariantenGeneratedClient, private authService: AuthService) {}

  getPlanningvariants(): Observable<Array<Planningvariant>> {
    return this.apiClient.planungsvarianten_Get(this.authService.realmId).pipe(map(pvs => pvs.map(p => this.mapApiMessage(p))));
  }

  createPlanningvariant(name: string): Observable<string> {
    const newPlanningvariant: APIPlanningvariant = {
      id: uuid(),
      name: name
    };

    return this.apiClient.planungsvarianten_Post(this.authService.realmId, newPlanningvariant).pipe(map(p => p.id));
  }

  updatePlanningvariant(planningvariant: PlanningvariantWithPolygons): Observable<void> {
    const vertices = _.uniqBy(_.flatMap(planningvariant.polygons, p => p.getAllVertices()), v => v.id);

    // [code adaptation - dec'20]
    const planningvariantToUpdate: PlanningvariantWithGisDataFront = {
      id: planningvariant.id,
      name: planningvariant.name,
      perimeter: planningvariant.polygons.map<PerimeterFront>(p => {
        return {
          id: p.id,
          planungsvarianteID: planningvariant.id,
          bezeichnung: p.name,
          farbe: p.color.toString(),
          perimeterConnections: p.getAllVertices().map<PerimeterVertexConnectionFront>(v => {
            return {
              perimeterId: p.id,
              vertexId: v.id
            };
          })
        };
      }),
      vertices: vertices.map<VertexFront>(v => {
        return {
          id: v.id,
          x: v.x,
          y: v.y,
          aufRoutingGeometrie: v.isOnRoutableGeometry,
          manuell: v.isHumanCreated,
          perimeterConnections: v.polygons.toArray().map<PerimeterVertexConnectionFront>(p => {
            return {
              perimeterId: p.id,
              vertexId: v.id
            };
          })
        };
      })
    };

    // [code adaptation - dec'20]
    return this.apiClient.planungsvarianten_Update(planningvariantToUpdate, this.authService.realmId, planningvariant.id);
  }

  renamePlanningvariant(id: string, newName: string): Observable<void> {
    return this.apiClient.planungsvarianten_Rename(id, newName, this.authService.realmId);
  }

  deletePlanningvariant(id: string): Observable<void> {
    return this.apiClient.planungsvarianten_Delete(id, this.authService.realmId);
  }

  copyPlanningvariant(id: string, newName: string): Observable<Planningvariant> {
    return this.apiClient.planungsvarianten_Copy(this.authService.realmId, id, newName).pipe(map(pv => this.mapApiMessage(pv)));
  }

  private mapApiMessage(p: APIPlanningvariant): Planningvariant {
    return {
      id: p.id,
      name: p.name
    };
  }
}
