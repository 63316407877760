import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from "@angular/core";
import {trigger, state, style, transition, animate, keyframes} from "@angular/animations";
import {AppstateService, AppState} from "../../services/appstate.service";
import {MatDialog} from "@angular/material";
import * as _ from "lodash";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmDialogData, ConfirmDialogComponent} from "../../defaultDialogs/confirm-dialog/confirm-dialog.component";
import {PolygonService} from "../../services/geometry/polygon.service";
import {Polygon} from "src/app/services/geometry/providers/geometry-provider";
import {zip, noop, concat} from "rxjs";
import {finalize} from "rxjs/operators";
import {NotifyService} from "src/app/services/notify.service";
import { AuthService } from "src/app/services/auth/auth.service";

@Component({
  selector: "app-schoolradius-menu",
  templateUrl: "./schoolradius-menu.component.html",
  styleUrls: ["./schoolradius-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("rotateIcon", [
      state("true", style({transform: "rotate(0deg)"})),
      state("false", style({transform: "rotate(180deg)"})),
      transition("* => *", [animate("100ms 250ms cubic-bezier(0.0, 0.0, 0.2, 1)")])
    ]),
    trigger("showHideAnimation", [
      state("in", style({transform: "translateY(0)"})),
      transition(":enter", [
        animate(
          300,
          keyframes([
            style({transform: "translateY(-100%)", offset: 0}),
            style({transform: "translateY(15px)", offset: 0.3}),
            style({transform: "translateY(0)", offset: 1})
          ])
        )
      ]),
      transition(":leave", [
        animate(
          300,
          keyframes([
            style({transform: "translateY(0)", offset: 0}),
            style({transform: "translateY(15px)", offset: 0.3}),
            style({transform: "translateY(-100%)", offset: 1})
          ])
        )
      ])
    ])
  ]
})
export class SchoolradiusMenuComponent implements OnInit {
  constructor(
    private cd: ChangeDetectorRef,
    private appStateService: AppstateService,
    public dialog: MatDialog,
    public translate: TranslateService,
    private polygonService: PolygonService,
    private notifyService: NotifyService,
    private authService: AuthService
  ) {}

  isOpen = true;
  isDraw = false;
  schoolAreas: Array<Polygon> = [];

  resetBusy = false;

  ngOnInit(): void {
    this.appStateService.getDrawChangeObservable().subscribe(isDrawMode => {
      this.isDraw = isDrawMode;
      this.cd.markForCheck();
    });

    this.polygonService.getPolygons().subscribe(areas => {
      this.schoolAreas = areas;
      this.cd.markForCheck();
    });
  }

  get canWrite(): boolean {
    return this.authService.schoolarea === "write";
  }

  setDrawMode(): void {
    this.appStateService.setToDraw();
  }

  setDefaultMode(): void {
    this.appStateService.setState(AppState.GisDefault);
  }

  resetSchoolAreas(): void {
    const dialogData: ConfirmDialogData = {
      dialogTitle: this.translate.instant("menu.schoolarea.delete_title"),
      message: this.translate.instant("menu.schoolarea.delete_text").split("||")
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      width: "400px"
    });

    dialogRef.beforeClosed().subscribe(confirmed => {
      if (confirmed) {
        this.resetBusy = true;
        this.cd.markForCheck();
        const deleteObservables = this.schoolAreas.map(s => this.polygonService.deletePolygon(s.id));
        concat(...deleteObservables)
          .pipe(
            finalize(() => {
              this.resetBusy = false;
              this.cd.markForCheck();
            })
          )
          .subscribe(
            () => noop,
            error => {
              this.notifyService.showError("apiErrors.schoolarea.reset");
            }
          );
      }
    });
  }
}
