import {Component, Inject, ChangeDetectionStrategy} from "@angular/core";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
    private translate: TranslateService
  ) {}

  get dialogTitle(): string  {
    return this.data.dialogTitle || this.translate.instant("defaultDialogs.confirm.defaultTitle");
  }

  get confirmButtonLabel(): string {
    return this.data.confirmButtonLabel || this.translate.instant("defaultDialogs.confirm.buttons.confirm");
  }
}

export interface ConfirmDialogData {
  dialogTitle: string;
  confirmButtonLabel?: string;
  // message to display in dialog, array will be joined with newlines (add multiple strings for multiple lines)
  message: Array<string>;
}
