import OlPolygon from "ol/geom/Polygon";
import Feature from "ol/Feature";

import Style from "ol/style/Style";
import StrokeStyle from "ol/style/Stroke";
import Map from "ol/Map";

import { AppstateService } from "../services/appstate.service";
import { Painter, DrawingLayer } from "./drawing-layer";
import { ApiZusatzebeneGeometryProvider } from "../services/geometry/providers/api-zusatzebene-geometry-provider";
import { GeometrySnap } from "../routing/geometry-snap";
import { GeometryService } from "../services/geometry/geometry.service";

export class SchulkreisRenderer implements Painter {

  private drawingLayer: DrawingLayer;
  private schulkreise = [];
  private styleClose: Style[];
  private styleFar: Style[];

  constructor (
    apiZusatzebeneGeometryProvider: ApiZusatzebeneGeometryProvider,
    geometrySnap: GeometrySnap,
    private appstateService: AppstateService,
    private map: Map,
    geometryService: GeometryService
  ) {
    this.styleClose = this.generateStyle(3.0, 3.5);
    this.styleFar = this.generateStyle(2.0, 2.5);

    // initially load zusatzebenen
    apiZusatzebeneGeometryProvider.getPolygons(geometrySnap).subscribe(schulkreise => {
      this.schulkreise = schulkreise;
      if (this.drawingLayer) this.drawingLayer.triggerRepaint();
    });

    geometryService.getPolygons(false).subscribe(polygons => {
      if (this.appstateService.isAdminState()) {
        this.schulkreise = polygons;
      }
    });
  }

  getFeatures(): Feature[] {
    const features: Feature[] = [];
    if (!this.appstateService.isAdminState()) {
      this.schulkreise.forEach(polygon => {
        const polygonCoordinates = polygon.getOpenlayersRoutedCoordinates();
        features.push(new Feature(new OlPolygon([polygonCoordinates])));
      });
    }
    return features;
  }

  setDrawingLayer(drawingLayer: DrawingLayer): void {
    this.drawingLayer = drawingLayer;
  }

  getStyle(feature: Feature): Style | Style[] {
    if (this.map.getView().getZoom() > 14) {
      return this.styleClose;
    }
    return this.styleFar;
  }

  private unitsPerPixel(): number {
    return this.map.getView().getResolution();
  }

  private generateStyle(lineWidth1: number, lineWidth2: number): Style[] {
    const schulkreisStyle1 = new Style({
      stroke: new StrokeStyle({color: [77, 77, 77, 1.0], width: lineWidth2}),
      zIndex: 0
    });
    const schulkreisStyle2 = new Style({
      stroke: new StrokeStyle({color: [255, 200, 30, 1.0], width: lineWidth1, lineDash: [14, 10]}),
      zIndex: 0
    });
    const schulkreisStyle3 = new Style({
      stroke: new StrokeStyle({color: [77, 77, 77, 1.0], width: lineWidth1, lineDash: [10, 14], lineDashOffset: 14}),
      zIndex: 0
    });
    return [schulkreisStyle1, schulkreisStyle2, schulkreisStyle3];
  }
}
