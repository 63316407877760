import {Injectable} from "@angular/core";
import {Observable, BehaviorSubject} from "rxjs";
import {finalize} from "rxjs/operators";

import * as _ from "lodash";

import {AppstateService} from "../appstate.service";
import {NotifyService} from "../notify.service";
import {Building, BuildingTypeName} from "./providers/building-provider";
import {ApiBuildingProvider} from "./providers/api-building-provider";

@Injectable({
  providedIn: "root"
})
export class BuildingDataService {
  private buildings: Array<Building> = [];
  private buildingsObservable = new BehaviorSubject<Array<Building>>(this.buildings);

  private buildingsTypeNames: Array<BuildingTypeName> = [];
  private buildingsTypeNamesObservable = new BehaviorSubject<Array<BuildingTypeName>>(this.buildingsTypeNames)

  constructor(private appStateService: AppstateService, private notifySerivce: NotifyService, private buildingProvider: ApiBuildingProvider) {
    this.appStateService.setMapLoading(true);

    this.buildingProvider
        .getBuildingsTypeNames()
        .subscribe(
          typesName => {
            this.buildingsTypeNames = typesName;
            this.notifyBuildingsTypesNameObservers();
          },
          error => {
            this.notifySerivce.showError("apiErrors.building.load");
          }
        );

    this.buildingProvider
      .getBuildings()
      .pipe(finalize(() => this.appStateService.setMapLoading(false)))
      .subscribe(
        buildings => {
          this.buildings = buildings;
          this.notifyBuildingObservers();
        },
        error => {
          this.notifySerivce.showError("apiErrors.building.load");
        }
      );


  }

  getBuildings(): Observable<Array<Building>> {
    return this.buildingsObservable.asObservable();
  }

  getBuildingsTypeNames(): Observable<Array<BuildingTypeName>> {
    return this.buildingsTypeNamesObservable.asObservable();
  }

  setRadius(id: number, radius: number): void {
    _.find(this.buildings, b => b.id === id).radius = radius;
    this.notifyBuildingObservers();
  }

  private notifyBuildingObservers(): void {
    this.buildingsObservable.next(this.buildings);
  }

  private notifyBuildingsTypesNameObservers(): void {
    this.buildingsTypeNamesObservable.next(this.buildingsTypeNames);
  }
}
