import Style from "ol/style/Style";
import Feature from "ol/Feature";
import CircleStyle from "ol/style/Circle";
import FillStyle from "ol/style/Fill";
import StrokeStyle from "ol/style/Stroke";

import { StudentWithCoordinates } from "./student-appearence-styler";
import * as Color from "color";
import { ObjectBackedMap } from "../../../tools/object-backed-map";
import { StackedStudentStyler } from "./stacked-student-styler";
import { RenderTools } from "../render-tools";
import { Student, Gender } from "../../../services/student/providers/student-provider";

export class ForeignLanguageColorStudentStyler extends StackedStudentStyler {

  private static readonly BASE_COLOR = Color.rgb(59, 179, 21);
  private static readonly FOREIGN_LANGUAGE_COLOR = Color.rgb(230, 79, 62);

  private stylesWithClass = new ObjectBackedMap<Style>();
  private stylesWithoutClass = new ObjectBackedMap<Style>();

  getStyle(feature: Feature, unitsPerPixel: number): Style {

    // get correctly colored style
    const hasForeignLanguage: boolean = feature.get("hasForeignLanguage");
    const isHovered: boolean = feature.get("hovered");
    const hasClass: boolean = feature.get("hasClass");
    const style = this.getOrCreateSyle(hasForeignLanguage, hasClass, isHovered, unitsPerPixel);

    // update zIndex
    const zIndex: number = parseInt(feature.get("zIndex"), 10);
    style.setZIndex(zIndex);

    return style;
  }

  getProperties(studentWithCoordinates: StudentWithCoordinates, hoveredStudent: Student): Object {
    return {id: studentWithCoordinates.student.id,
            zIndex: studentWithCoordinates.zIndex.toString(),
            hasForeignLanguage: (studentWithCoordinates.student.foreignLanguage),
            hasClass: (studentWithCoordinates.student.schoolClass !== undefined),
            hovered: (hoveredStudent && (studentWithCoordinates.student.id === hoveredStudent.id)) };
  }

  private getOrCreateSyle(hasForeignLanguage: boolean, hasClass: boolean, isHovered: boolean, unitsPerPixel: number): Style {
    let radius = RenderTools.getStudentRadius(unitsPerPixel);
    if (isHovered) radius = radius * RenderTools.SCALE_SELECTED_STUDENT_BY_FACTOR;
    const key = hasForeignLanguage.toString() + "_" + radius;

    const styles = hasClass ? this.stylesWithClass : this.stylesWithoutClass;
    if (!styles.contains(key)) {
      const style = this.createStyle(hasForeignLanguage, hasClass, radius);
      styles.put(key, style);
    }
    return styles.get(key);
  }

  private getColor(hasForeignLanguage: boolean): Color {
    if (hasForeignLanguage) {
      return ForeignLanguageColorStudentStyler.FOREIGN_LANGUAGE_COLOR;
    } else {
      return ForeignLanguageColorStudentStyler.BASE_COLOR;
    }
  }

  private createStyle(hasForeignLanguage: boolean, hasClass, radius: number): Style {
    const color = this.getColor(hasForeignLanguage);
    if (hasClass) {
      return new Style({
        image: new CircleStyle({
          fill: new FillStyle({color: color.array() as [number, number, number, number]}),
          stroke: new StrokeStyle({color: Color.rgb(0, 0, 0).array() as [number, number, number, number], width: 1.5}),
          radius: radius
        })
      });
    } else {
      return new Style({
        image: new CircleStyle({
          fill: new FillStyle({color: color.lighten(0.5).hex()}),
          stroke: new StrokeStyle({color: color.darken(0.2).hex(), width: 1.5}),
          radius: radius
        })
      });
    }

  }
}
