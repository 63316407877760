import {Injectable} from "@angular/core";
import {Observable, BehaviorSubject, Subject, noop, combineLatest} from "rxjs";
import {finalize, tap} from "rxjs/operators";

import {Vertex, Polygon, GisException, GeometryProvider} from "./providers/geometry-provider";

import Map from "ol/Map";
import {PolygonCreationTool} from "../../map/tools/polygon-creation-tool";
import {AppstateService} from "../appstate.service";

import {ColorPaletteFactory} from "../../tools/color-palette";

import * as _ from "lodash";
import {GeometrySnap} from "../../routing/geometry-snap";
import {Routing} from "../../routing/routing";
import {NotifyService} from "../notify.service";
import {ObjectBackedMap} from "../../tools/object-backed-map";
import {GeometryTools} from "../../tools/geometry-tools";
import {VertexMetadata} from "./vertex-metadata";
import {IPolygonService} from "./polygon.service";
import {Vector} from "../../tools/vector";
import {PlanningvariantService} from "../planningvariant/planningvariant.service";
import {Planningvariant, Coordinates} from "../../API/generated/clients";
import {ApiPerimeterGeometryProvider} from "./providers/api-perimeter-geometry-provider";
import { ApiZusatzebeneGeometryProvider } from "./providers/api-zusatzebene-geometry-provider";

@Injectable({
  providedIn: "root"
})
export class GeometryService implements IPolygonService {
  public static readonly SNAP_TOLERANCE_PIXELS = 10;
  public static readonly SAVING_INTERVAL_MS = 15 * 1000;

  private colorPalette = ColorPaletteFactory.getDefault();

  private planningVariant?: Planningvariant; // undefined if schoolareas are handled

  // list of polygons
  private polygons: Polygon[] = [];
  private polygonsObservable = new BehaviorSubject<Polygon[]>(this.polygons);
  private polygonsObservableWithMove = new BehaviorSubject<Polygon[]>(this.polygons);
  private polygonsOnHoverObservable = new Subject<Polygon>();

  // list of all vertices
  // private vertices: Set<Vertex> = Set<Vertex>();
  private vertices = new ObjectBackedMap<Vertex>();

  private _map: Map = undefined;
  public set map(m: Map) {
    this._map = m;
  }
  public get map(): Map {
    return this._map;
  }

  private _geometrySnap: GeometrySnap;
  public set geometrySnap(lineSnap: GeometrySnap) {
    this._geometrySnap = lineSnap;
  }
  public get geometrySnap(): GeometrySnap {
    return this._geometrySnap;
  }

  private _routing: Routing;
  public set routing(routing: Routing) {
    this._routing = routing;
  }
  public get routing(): Routing {
    return this._routing;
  }

  private _polygonCreationTool = undefined;
  public set polygonCreationTool(tool: PolygonCreationTool) {
    this._polygonCreationTool = tool;
  }
  public get polygonCreationTool(): PolygonCreationTool {
    return this._polygonCreationTool;
  }

  constructor(
    private appstateService: AppstateService,
    private notifyService: NotifyService,
    private planningvariantService: PlanningvariantService,
    private apiPerimeterGeometryProvider: ApiPerimeterGeometryProvider,
    private apiZusatzebeneGeometryProvider: ApiZusatzebeneGeometryProvider
  ) {

    // change of planningvariant: load polygons/vertices
    combineLatest([this.planningvariantService.getSelectedPlanningvariant(), this.appstateService.getAdminChangeObservable()]).subscribe(([pv, isAdmin]) => {
      if (isAdmin || pv) {
        this.planningVariant = isAdmin ? undefined : pv;
        this.appstateService.setMapLoading(true);
        this.getCurrentGeometryProider()
          .getPolygons(this.geometrySnap, pv ? pv.id : undefined)
          .pipe(finalize(() => this.appstateService.setMapLoading(false)))
          .subscribe(
            polygons => {
              this.polygons = polygons;
              this.vertices = new ObjectBackedMap<Vertex>();

              // add all vertices to set
              this.polygons.forEach(poly => {
                poly.setPolygonService(this);
                poly.getHumanCreatedVertices().forEach(v => this.vertices.put(v.key, v));
              });
              this.notifyPolygonObservers(false);
            },
            error => {
              this.polygons = [];
              this.notifyService.showError(isAdmin ? "apiErrors.schoolarea.load" : "apiErrors.perimeter.load");
              this.notifyPolygonObservers(false);
            }
          );
      } else {
        this.planningVariant = undefined;
        this.polygons = [];
        this.vertices = new ObjectBackedMap<Vertex>();
        this.notifyPolygonObservers(false);
      }
    });

    // listen to state changes
    this.appstateService.getDrawChangeObservable().subscribe(isDraw => {
      if (!isDraw && this.polygonCreationTool !== undefined) {
        this.polygons.filter(poly => poly.underConstruction).forEach(poly => {
          this.deletePolygon(poly.id, false);
        });
        this.polygonCreationTool.deactivate(true);
        this.notifyPolygonObservers(false);
        this.save();
      }
    });

    // save regularly
    setInterval(() => {
      if (this.appstateService.isEditState()) {
        this.save();
      }
    }, GeometryService.SAVING_INTERVAL_MS);
  }

  getMapBoundaries(): Observable<[number, number, number, number]> {
    return this.getCurrentGeometryProider().getMapBoundaries();
  }

  setMapBoundaries(coordinates: [number, number, number, number]): Observable<Coordinates> {
    return this.getCurrentGeometryProider().setMapBoundaries(coordinates);
  }

  //#region polygons
  getPolygons(notifyOnMove = false): Observable<Polygon[]> {
    if (notifyOnMove) {
      return this.polygonsObservableWithMove.asObservable();
    } else {
      return this.polygonsObservable.asObservable();
    }
  }

  getPolygonCount(): number {
    return this.polygons.length;
  }

  setPolygonOnHover(polygon: Polygon): void {
    this.polygonsOnHoverObservable.next(polygon);
  }

  getPolygonHoveredObservable(): Observable<Polygon> {
    return this.polygonsOnHoverObservable.asObservable();
  }

  renamePolygon(id: string, newName: string): Observable<void> {
    return this.getCurrentGeometryProider().renamePolygon(id, newName).pipe(
      tap(() => {
        _.find(this.polygons, p => p.id === id).name = newName;
        this.notifyPolygonObservers(false);
      })
    );
  }

  createPerimeterException(perimeterId: string, studentId: number): Observable<string> {
    return this.getCurrentGeometryProider().createPerimeterException(perimeterId, studentId).pipe(
      tap(newExceptionId => {
        const currentExceptions = _.find(this.polygons, p => p.id === perimeterId).gisExceptions;
        currentExceptions.push({
          id: newExceptionId,
          perimeterId: perimeterId,
          studentId: studentId
        });
        this.notifyPolygonObservers(false);
      })
    );
  }

  updatePerimeterException(oldPerimeterId: string, gisException: GisException): Observable<void> {
    return this.getCurrentGeometryProider().updatePerimeterException(gisException.id, gisException.perimeterId).pipe(
      tap(() => {
        // remove exception on old perimeter
        const exceptionOnOldPerimeter = _.find(this.polygons, p => p.id === oldPerimeterId).gisExceptions;
        _.remove(exceptionOnOldPerimeter, e => e.id === gisException.id);

        // add exception on new perimeter
        const currentExceptions = _.find(this.polygons, p => p.id === gisException.perimeterId).gisExceptions;
        currentExceptions.push(gisException);

        this.notifyPolygonObservers(false);
      })
    );
  }

  deletePerimeterException(gisException: GisException): Observable<void> {
    return this.getCurrentGeometryProider().deletePerimeterException(gisException.id).pipe(
      tap(() => {
        const currentExceptions = _.find(this.polygons, p => p.id === gisException.perimeterId).gisExceptions;
        _.remove(currentExceptions, e => e.id === gisException.id);
        this.notifyPolygonObservers(false);
      })
    );
  }

  /** Adds a polygon to the geometry service. Does not add it to the database.
   * Call onPolygonComplete() for thtat purpouse.
   */
  addPolygon(polygon: Polygon): void {
    polygon.setPolygonService(this);
    this.polygons.push(polygon);
    polygon.getHumanCreatedVertices().forEach(vertex => this.vertices.put(vertex.key, vertex));
    this.notifyPolygonObservers(false);
  }

  setUnderConstruction(polygon: Polygon, underContruction: boolean): void {
    polygon.underConstruction = underContruction;
    this.notifyPolygonObservers(false);
  }

  onPolygonComplete(polygon: Polygon): void {
    polygon.underConstruction = false;
    this.appstateService.setToDefault();
    this.notifyPolygonObservers(false);
  }

  /**
   * Delete a polygon
   * @param id id of polygon to delete
   * @param fromDatabase true by default
   */
  deletePolygon(id: string, fromDatabase?: boolean): Observable<void> {
    const removeFunction = () => {
      const polygonToDelete = this.polygons.filter(poly => poly.id === id);
      polygonToDelete.forEach(polyToDelete => {
        const index = this.polygons.indexOf(polyToDelete);
        if (index >= 0) this.polygons.splice(index, 1);
        this.removeUnneededVertices(polyToDelete);
      });
      this.polygons = this.polygons.filter(poly => poly.id !== id);
      this.notifyPolygonObservers(false);
    };

    // delete on provivder
    if (fromDatabase === undefined || fromDatabase) {
      return this.getCurrentGeometryProider().deletePolygon(id).pipe(tap(() => removeFunction()));
    } else {
      removeFunction();

      const retSubject = new Subject<void>();
      retSubject.complete();
      return retSubject.asObservable();
    }
  }

  /**
   * Creates a new polygon. Does not save it in the database yet. Call onPolygonComplete() for that purpose
   * @param name
   */
  createPolygon(name: string): void {
    if (this.polygonCreationTool !== undefined) {
      this.appstateService.setToNewPolygon();
      const lastPoly = _.last(this.polygons);
      const color = this.appstateService.isAdminState()
        ? this.colorPalette.getColorByIndex(0)
        : this.colorPalette.nextColor(_.get(lastPoly, "color", undefined));
      this.polygonCreationTool.drawNewPolygon(name, color);
    }
  }

  //#endregion

  //#region vertex

  /**
   * Adds a vertex to the polygon after the specified index.
   * If no index is provided, the vertex is added to the end
   * of the polygon.
   * @param polygon
   * @param vertexMeta
   * @param index
   */
  addVertexToPolygon(polygon: Polygon, vertexMeta: VertexMetadata, index?: number): void {
    const vertex = vertexMeta.vertex;
    if (index === undefined) {
      polygon.appendVertex(vertex);
    } else {
      polygon.addVertex(index, vertex);
    }
    this.vertices.put(vertex.key, vertex);
    vertex.polygons.put(polygon.id, polygon);
    if (vertexMeta.isOnLine()) this.addVertexToExistingLine(vertexMeta);
    this.notifyPolygonObservers(false);
  }

  /**
   * Only for vertices which snapped on a line. Adds this vertex to all
   * polygons that contain the line it snapped to.
   * @param vertexMeta
   */
  addVertexToExistingLine(vertexMeta: VertexMetadata): void {
    if (vertexMeta.isOnLine) {
      const polygonsWithBothVertices = _.intersection(vertexMeta.lineVertex1.polygons.toArray(), vertexMeta.lineVertex2.polygons.toArray());
      polygonsWithBothVertices.forEach(poly => {
        const indices = poly.getDirectVertexSuccessionLocations(vertexMeta.lineVertex1, vertexMeta.lineVertex2);
        let offset = 0;
        indices.forEach(index => {
          this.addVertexToPolygon(poly, new VertexMetadata(vertexMeta.vertex), index + offset);
          offset++;
        });
      });
    }
  }

  deleteVertex(vertex: Vertex): void {
    // check if removal does not cause a polygon to have less than 3 vertices
    let allPolygonsHaveEnoughVertices = true;
    let polygonWithNotEnoughVertices: Polygon;
    const polygonArr = vertex.polygons.toArray();
    for (let i = 0; i < polygonArr.length && allPolygonsHaveEnoughVertices; i++) {
      const poly = polygonArr[i];
      if (poly.getHumanCreatedVertices().length <= 4) {
        allPolygonsHaveEnoughVertices = false;
        polygonWithNotEnoughVertices = poly;
      }
    }

    if (allPolygonsHaveEnoughVertices) {
      for (let i = 0; i < polygonArr.length; i++) {
        polygonArr[i].deleteVertex(vertex);
      }
      this.vertices.remove(vertex.key);
      this.notifyPolygonObservers(false);
    } else {
      this.showNotEnoughVerticesMessage(polygonWithNotEnoughVertices);
    }
  }

  splitVertex(vertexToSplit: Vertex): void {
    if (vertexToSplit.polygons.length < 2) throw Error("A vertex can only be split if it is used in at least 2 polygons.");
    const polygonArr = vertexToSplit.polygons.toArray();
    for (const polygon of polygonArr) {
      const predecessor = polygon.getPredecessor(vertexToSplit);
      const successor = polygon.getSuccessor(vertexToSplit);

      if (predecessor && successor) {
        // calculate normalized vector to predecessor and successor
        const vPred = new Vector(predecessor.x - vertexToSplit.x, predecessor.y - vertexToSplit.y);
        vPred.normalizeOrSetToValue(1, 0);
        const vSucc = new Vector(successor.x - vertexToSplit.x, successor.y - vertexToSplit.y);
        vSucc.normalizeOrSetToValue(0, 1);

        const vOffset = vPred.add(vSucc);
        const resolution = this.map.getView().getResolution();
        vOffset.scale(this.snapToleranceMeters() * Math.sqrt(polygonArr.length));

        // Invert if this is a concave vertex for the polygon
        if (!polygon.containsPointUnrouted(vertexToSplit.x + vOffset.x, vertexToSplit.y + vOffset.y)) {
          vOffset.scale(-1);
        }

        const vertex = new Vertex(vertexToSplit.x + vOffset.x, vertexToSplit.y + vOffset.y);
        vertex.polygons.put(polygon.id, polygon);
        this.vertices.put(vertex.key, vertex);
        polygon.replaceVertex(vertexToSplit, vertex);
      } else {
        throw Error("A polygon was referenced by a vertex. However, the polygon does not contain said vertex");
      }
    }

    this.vertices.remove(vertexToSplit.key);
    this.notifyPolygonObservers(false);
  }

  /**
   * Merges two vertices. If the operation fails (because one perimeter would have less than 3 vertices, the
   * replacement is set to the location of the argument coordinatesIfMergeFailed)
   * @param toReplace
   * @param replacement
   */
  mergeVertices(toReplace: Vertex, replacement: Vertex, coordinatesIfMergeFailed: [number, number]): void {
    const polygonArr = toReplace.polygons.toArray();

    // if there are polygons that contain both vertices: check if the polygon still has enough vertices after the operation
    const polygonWithBothVertices = _.intersection(polygonArr, replacement.polygons.toArray());
    let ok = true;
    let notOkPolygon: Polygon;
    polygonWithBothVertices.forEach(poly => {
      if (poly.getHumanCreatedVertices().length - poly.getVertexOccuranceCount(toReplace) <= 3) {
        ok = false;
        notOkPolygon = poly;
      }
    });

    if (ok) {
      polygonArr.forEach(poly => {
        poly.replaceVertex(toReplace, replacement);
        replacement.polygons.put(poly.id, poly);
      });
      this.vertices.remove(toReplace.key);
    } else {
      toReplace.x = coordinatesIfMergeFailed[0];
      toReplace.y = coordinatesIfMergeFailed[1];
      this.showNotEnoughVerticesMessage(notOkPolygon);
    }

    this.notifyPolygonObservers(false);
  }

  /**
   * Update position of a vertex. Fires an event that the polygons have changed.
   * Does not save the changed position in the database. Use saveVertex() for this purpose
   * @param vertex
   */
  updateVertexPosition(vertex: Vertex, informOnlyMove: boolean): void {
    vertex.polygons.toArray().forEach(p => p.onVertexUpdated(vertex));
    this.notifyPolygonObservers(informOnlyMove);
  }

  /**
   * Search for an existing vertex at the given x and y coordinates.
   * If no vertex exists, undefined is returned
   * @param x
   * @param y
   */
  getExistingVertex(x: number, y: number, vertexToExclude?: Vertex): VertexMetadata {
    const v = new Vertex(x, y);
    let minDistSq = Math.pow(this.snapToleranceMeters(), 2);
    let minVertex: Vertex;

    // go through all existing vertices
    this.vertices.forEach(vExisting => {
      if (vExisting !== vertexToExclude) {
        const distSq = this.vertexDistanceSquared(v, vExisting);
        if (distSq <= minDistSq) {
          minDistSq = distSq;
          minVertex = vExisting;
        }
      }
    });
    if (minVertex) {
      return new VertexMetadata(minVertex);
    }
    return undefined;
  }

  /**
   * Search for an vertex (either existing or on a line) at the given x and y coordinates.
   * If no vertex exists, undefined is returned
   * @param x
   * @param y
   */
  getVertex(x: number, y: number, vertexToExclude?: Vertex): VertexMetadata {
    // check if an existing vertex within snapping distance exists
    const minVertexMeta = this.getExistingVertex(x, y, vertexToExclude);
    if (minVertexMeta) return minVertexMeta;

    // snap to line
    const vertexMeta = this.snapToPolygonLine(x, y, vertexToExclude);
    if (vertexMeta) return vertexMeta;

    // snap to geometry
    if (this.geometrySnap !== undefined) {
      const result = this.geometrySnap.snapTo(x, y, this.snapToleranceMeters());
      if (result.snapped) {
        const vertex = new Vertex(result.x, result.y, true, result.segment);
        return new VertexMetadata(vertex, true);
      }
    }

    return undefined;
  }

  /**
   * Search for an existing vertex at the given x and y coordinates.
   * If there is no existing vertex to snap to, a new vertex is created.
   * @param x cooridnate
   * @param y coordinate
   * @returns Vertex
   */
  getOrCreateVertex(x: number, y: number): VertexMetadata {
    const vertex = this.getVertex(x, y);
    if (vertex) {
      return vertex;
    } else {
      return new VertexMetadata(new Vertex(x, y), true);
    }
  }

  snapToPolygonLine(x: number, y: number, vertexToExclude?: Vertex): VertexMetadata {
    const coordinate = [x, y] as [number, number];
    let bestOnSegment: [number, number];
    let bestFromVertex: Vertex;
    let bestToVertex: Vertex;
    let bestDistance = Math.pow(this.snapToleranceMeters(), 2);
    let isOnRoutableGeometry: boolean;

    for (const polygon of this.polygons) {
      const vertices = polygon.getHumanCreatedVertices();
      for (let i = 1; i < vertices.length; i++) {
        if (vertices[i - 1] !== vertexToExclude && vertices[i] !== vertexToExclude) {
          const fromVertex = vertices[i - 1];
          const toVertex = vertices[i];
          const from = [fromVertex.x, fromVertex.y] as [number, number];
          const to = [toVertex.x, toVertex.y] as [number, number];

          let path: [number, number][];
          if (fromVertex.isOnRoutableGeometry && toVertex.isOnRoutableGeometry) {
            path = polygon.getRoutedPath(fromVertex, toVertex);
          }

          if (path && path.length > 0) {
            // Find closest point on routed path between the vertices
            for (let j = 1; j < path.length; j++) {
              const segment = [path[j - 1], path[j]];
              const posOnRoutedSegment = GeometryTools.closestOnSegment(coordinate, segment);
              const distToRoutedSegment = GeometryTools.squaredDistance(coordinate, posOnRoutedSegment);
              if (distToRoutedSegment <= bestDistance) {
                bestOnSegment = posOnRoutedSegment;
                bestDistance = distToRoutedSegment;
                bestFromVertex = fromVertex;
                bestToVertex = toVertex;
                isOnRoutableGeometry = true;
              }
            }
          } else {
            // Find closest point on direct line between the vertices
            const posOnSegment = GeometryTools.closestOnSegment(coordinate, [from, to]);
            const distToSegment = GeometryTools.squaredDistance(coordinate, posOnSegment);
            if (distToSegment <= bestDistance) {
              bestOnSegment = posOnSegment;
              bestDistance = distToSegment;
              bestFromVertex = fromVertex;
              bestToVertex = toVertex;
              isOnRoutableGeometry = false;
            }
          }
        }
      }
    }

    if (bestOnSegment) {
      let vertex: Vertex;
      if (isOnRoutableGeometry) {
        const result = this.geometrySnap.snapTo(bestOnSegment[0], bestOnSegment[1], 0.01);
        if (result.snapped) {
          vertex = new Vertex(result.x, result.y, true, result.segment);
        }
      }

      if (vertex === undefined) {
        vertex = new Vertex(bestOnSegment[0], bestOnSegment[1]);
      }

      return new VertexMetadata(vertex, true, bestFromVertex, bestToVertex);
    }
    return undefined;
  }

  //#endregion

  getRoutedPath(source: Vertex, dest: Vertex): Vertex[] {
    const path = this.routing.findPath(source, dest);
    const vertexPath = path.map(node => new Vertex(node[0], node[1], false, undefined, false));
    return vertexPath;
  }

  //#region private

  private vertexDistanceSquared(v1: Vertex, v2: Vertex): number {
    return Math.pow(v1.x - v2.x, 2) + Math.pow(v1.y - v2.y, 2);
  }

  private snapToleranceMeters(): number {
    if (this.map !== undefined) {
      // get resolution units per pixel. The unit is meters in our case;
      const resolution = this.map.getView().getResolution();
      return resolution * GeometryService.SNAP_TOLERANCE_PIXELS;
    }
    return Number.MAX_SAFE_INTEGER;
  }

  private notifyPolygonObservers(onlyMove: boolean): void {
    this.polygons.forEach(poly => poly.removeDuplicates());
    this.polygonsObservableWithMove.next(this.polygons);
    if (!onlyMove) {
      this.polygonsObservable.next(this.polygons);
    }
  }

  private removeUnneededVertices(removedPolygon: Polygon): void {
    // find polygons which we need to remove
    removedPolygon.getHumanCreatedVertices().forEach(v => {
      v.polygons.remove(removedPolygon.id);
      if (v.polygons.length === 0) {
        this.vertices.remove(v.key);
      }
    });
  }

  private showNotEnoughVerticesMessage(polygonWithNotEnoughVertices: Polygon): void {
    if (this.appstateService.isAdminState()) {
      this.notifyService.showError("geometry.geometryServer.adminCannotDeleteVertex", {polygonName: polygonWithNotEnoughVertices.name});
    } else {
      this.notifyService.showError("geometry.geometryServer.defaultCannotDeleteVertex", {polygonName: polygonWithNotEnoughVertices.name});
    }
  }

  private save(): void {
    const completedPolygons = this.polygons.filter(p => !p.underConstruction);
    this.getCurrentGeometryProider().savePolygons(completedPolygons, this.planningVariant).subscribe(
      () => noop,
      error => {
        this.notifyService.showError(this.appstateService.isAdminState() ? "apiErrors.schoolarea.save" : "apiErrors.planningvariant.save");
      }
    );
  }

  private getCurrentGeometryProider(): GeometryProvider {
    if (this.appstateService.isAdminState()) {
      return this.apiZusatzebeneGeometryProvider;
    } else {
      return this.apiPerimeterGeometryProvider;
    }
  }

  //#endregion
}
