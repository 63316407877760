import {Component, OnInit} from "@angular/core";
import {BuildingDataService} from "../../../services/building/building-data.service";
import {BuildingType, BuildingProvider, Building} from "../../../services/building/providers/building-provider";
import * as _ from "lodash";
import * as Color from "color";
import {AppearanceService, BuildingAppearance} from "../../../services/appearance.service";
import { StudentDataService } from "src/app/services/student/student-data.service";
import { Gender } from "src/app/services/student/providers/student-provider";
import { some } from "lodash";

@Component({
  selector: "app-visibility-menu",
  templateUrl: "./visibility-menu.component.html",
  styleUrls: ["./visibility-menu.component.scss"]
})
export class VisibilityMenuComponent implements OnInit {
  selectedFilter: number;
  filterList: Array<FilterOptions> = [
    {id: 1, label: "menu.visibilityPanel.filter1", hasLegend: false},
    {id: 2, label: "menu.visibilityPanel.filter2", hasLegend: true},
    {id: 3, label: "menu.visibilityPanel.filter3", hasLegend: true},
    {id: 4, label: "menu.visibilityPanel.filter4", hasLegend: true},
    {id: 5, label: "menu.visibilityPanel.filter5", hasLegend: true}
  ];

  constructor(private buildingProvider: BuildingDataService, private appearanceService: AppearanceService, private studentService: StudentDataService) {

    this.buildingProvider.getBuildingsTypeNames().subscribe(typesName => {
      if(typesName.length) { // checks if api has results
        this.buildingInfo = typesName.map(type => {
          return new BuildingInfo(BuildingProvider.BUILDING_COLORS[type.key][0], BuildingProvider.BUILDING_COLORS[type.key][1], true, type.value);
        });
      }
    });

    this.studentService.getStudents().subscribe(students => {
      this.showUnknownGenderLegend = students.some(s => s.gender === Gender.UNKNOWN);
    });
  }
  buildingInfo: Array<BuildingInfo>;
  showUnknownGenderLegend = false;

  setBuildingAppearance(): void {
   const buildingCheckboxes: BuildingAppearance = {
     // tslint:disable:max-line-length
    redBuildingVisible: some(this.buildingInfo, bi => bi.type === BuildingType.RED) ? _.find(this.buildingInfo, bi => bi.type === BuildingType.RED).visible : false,
    blueBuildingVisible: some(this.buildingInfo, bi => bi.type === BuildingType.BLUE) ? _.find(this.buildingInfo, bi => bi.type === BuildingType.BLUE).visible : false,
    greenBuildingVisible: some(this.buildingInfo, bi => bi.type === BuildingType.GREEN) ? _.find(this.buildingInfo, bi => bi.type === BuildingType.GREEN).visible : false,
    orangeBuildingVisible: some(this.buildingInfo, bi => bi.type === BuildingType.ORANGE) ? _.find(this.buildingInfo, bi => bi.type === BuildingType.ORANGE).visible : false
  };

   this.appearanceService.setBuildingAppearance(buildingCheckboxes);
  }

  setFilterState(): void {
    this.appearanceService.setStudentAppearence(this.selectedFilter - 1);
  }

  ngOnInit(): void {
    this.selectedFilter = this.filterList[0].id;
  }

  get isGenderFilter(): boolean {
    return this.selectedFilter === 2 || this.selectedFilter === 3;
  }

  get isColorFilter(): boolean {
    return !(this.selectedFilter % 2);
  }
}

export interface FilterOptions {
  id: number;
  label: string;
  hasLegend: boolean;
}

class BuildingInfo {
  constructor(public type: BuildingType, public color: Color, public visible = true, public buildingName: string) {}
}
