import {Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChanges, OnChanges, ElementRef, ViewChild} from "@angular/core";
import {trigger, transition, animate, style, group} from "@angular/animations";

import {Observable, noop} from "rxjs";
import {map, finalize, take} from "rxjs/operators";

import {PmiSelectItem, PmiSelectItemContainer} from "ag.pmi.dev.client.ng6.select";

import {PolygonService} from "../../../../services/geometry/polygon.service";
import {Polygon} from "../../../../services/geometry/providers/geometry-provider";

import {StudentDataService} from "../../../../services/student/student-data.service";
import {StudentMapService} from "../../../../services/student/student-map.service";
import {Student} from "../../../../services/student/providers/student-provider";

import {SchoolclassService} from "../../../../services/schoolclass/schoolclass.service";
import {Schoolclass} from "../../../../services/schoolclass/providers/schoolclass-provider";

import {NotifyService} from "../../../../services/notify.service";

import * as _ from "lodash";
import {AuthService} from "src/app/services/auth/auth.service";
import { AppearanceService, BuildingAppearance } from "src/app/services/appearance.service";

@Component({
  selector: "app-onclick-student-panel",
  templateUrl: "./onclick-student-panel.component.html",
  styleUrls: ["./onclick-student-panel.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("showHide", [
      transition(":enter", [
        group([
          style({opacity: "0"}),
          animate("200ms cubic-bezier(0.4, 0.0, 1, 1)", style({opacity: "1"}))
        ])
      ]),
      transition(":leave", [animate("150ms cubic-bezier(0.4, 0.0, 1, 1)", style({opacity: "0"}))])
    ])
  ]
})
export class OnclickStudentPanelComponent implements OnInit, OnChanges {
  constructor(
    private polygonService: PolygonService,
    private studentService: StudentDataService,
    private schoolclassService: SchoolclassService,
    private studentMapService: StudentMapService,
    private appearanceService: AppearanceService,
    private notifyService: NotifyService,
    private authService: AuthService,
    private cd: ChangeDetectorRef,
    private el: ElementRef
  ) {}

  @Input()
  student: Student;

  @Input()
  position: [number, number];

  public selected: string;
  selectedClass: string;
  selectedPlanClass: string;
  perimeters: Array<Polygon> = [];
  selectedPerimeterId: string;

  classBusy = false;
  perimeterBusy = false;

  ngOnInit(): void {
    this.polygonService.getPolygons().subscribe(perimeters => {
      this.perimeters = perimeters;
      this.cd.markForCheck();
    });
  }

  get canWrite(): boolean {
    return this.authService.planningvariant === "write";
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.student && changes.student.currentValue) {
      this.selectedPlanClass = this.student.schoolPlanClassId ? this.student.schoolPlanClassId.toString() : undefined;
      this.selectedPerimeterId = this.student.perimeterException ? this.student.perimeterException.perimeterId : undefined;
    } else if (changes.student) {
      this.selectedPlanClass = undefined;
      this.selectedPerimeterId = undefined;
    }

    if (changes.position && this.student) {
      // check if we fit on screen (after timeout, so the animation has run and we have a height)
      setTimeout(() => {
        const cardElement = this.el.nativeElement.children[0];
        const height = cardElement.offsetHeight;
        const width = cardElement.offsetWidth;

        if (this.position[0] + width > window.innerWidth) {
          this.position[0] = this.position[0] - width;
          this.cd.markForCheck();
        }

        if (this.position[1] + height > window.innerHeight) {
          this.position[1] = this.position[1] - height;
          this.cd.markForCheck();
        }
      });
    }
  }

  closePanel(): void {
    this.studentMapService.setClicked(undefined);
  }

  perimeterChange(): void {
    const previousPerimeterId = this.student.perimeterException ? this.student.perimeterException.perimeterId : undefined;
    if (!this.perimeterBusy && this.selectedPerimeterId !== previousPerimeterId) {
      this.perimeterBusy = true;
      this.cd.markForCheck();

      let perimeterObservable: Observable<any>;
      if (!previousPerimeterId) {
        perimeterObservable = this.polygonService.createPerimeterException(this.selectedPerimeterId, this.student.id);
      } else if (!this.selectedPerimeterId) {
        perimeterObservable = this.polygonService.deletePerimeterException(this.student.perimeterException);
      } else {
        perimeterObservable = this.polygonService.updatePerimeterException(previousPerimeterId, {
          id: this.student.perimeterException.id,
          perimeterId: this.selectedPerimeterId,
          studentId: this.student.id
        });
      }

      perimeterObservable
        .pipe(
          finalize(() => {
            this.perimeterBusy = false;
            this.cd.markForCheck();
          })
        )
        .subscribe(
          () => noop,
          error => {
            this.notifyService.showError("apiErrors.student.saveGisException");
          }
        );
    }
  }

  planClassChange(newClass: PmiSelectItem<Schoolclass>): void {
    const newKey = newClass ? newClass.key : undefined;
    if (!this.classBusy && this.selectedPlanClass !== newKey) {
      this.selectedPlanClass = newKey;
      this.classBusy = true;
      this.cd.markForCheck();

      let setClassObs: Observable<void>;
      if (newClass) {
        setClassObs = this.studentService.setClass(newClass ? newClass.data : undefined, [this.student.id], true);
      } else {
        setClassObs = this.studentService.removePlanClass(this.student.id);
      }

      setClassObs.pipe(
        finalize(() => {
          this.classBusy = false;
          this.cd.markForCheck();
        })
      )
      .subscribe(
        () => noop,
          error => {
            this.notifyService.showError("apiErrors.schoolclass.saveAssignments");
          }
      );
    }
  }

  getPlanClassesForSelect(): Observable<PmiSelectItemContainer<Schoolclass>> {
    const classes: Observable<Schoolclass[]> = this.schoolclassService.getPlanSchoolclasses();
    return classes.pipe(
      map<Schoolclass[], PmiSelectItemContainer<Schoolclass>>(apiClasses => {
        return {
          loadingState: false,
          items: apiClasses.map<PmiSelectItem<Schoolclass>>(c => {
            return {
              key: c.id.toString(),
              value: c.name,
              data: c
            };
          })
        };
      })
    );
  }

  openSchoolroutes(): void {
    var buildings : BuildingAppearance;
    this.appearanceService.getBuildingAppearanceObservable().subscribe(appearance => buildings = appearance );
    this.studentService.geSchoolroute(this.student.id, this.student.perimeter.id).subscribe(schoolroutes => {
      schoolroutes.forEach(schoolroute => {
        if(buildings.redBuildingVisible && schoolroute.buildingType == 0) {
          window.open(schoolroute.url);
        }
        if(buildings.blueBuildingVisible && schoolroute.buildingType == 1) {
          window.open(schoolroute.url);
        }
        if(buildings.greenBuildingVisible && schoolroute.buildingType == 2) {
          window.open(schoolroute.url);
        }
        if(buildings.orangeBuildingVisible && schoolroute.buildingType == 3) {
          window.open(schoolroute.url);
        }
      });
    });
  }
}
