import { IStudentAppearanceStyler, StudentWithCoordinates } from "./student-appearence-styler";
import Style from "ol/style/Style";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { Student } from "../../../services/student/providers/student-provider";
import { ObjectBackedMap } from "../../../tools/object-backed-map";
import { RenderTools } from "../render-tools";
import { ShapeStudentStyler } from "./shape-student-styler";

export abstract class StackedStudentStyler implements IStudentAppearanceStyler {

  abstract getStyle(feature: Feature, unitsPerPixel: number): Style;
  abstract getProperties(studentWithCoordinates: StudentWithCoordinates, hoveredStudent: Student): Object;

  getFeatures(students: Student[], unitsPerPixel: number, hoveredStudent: Student): Feature[] {

    // stack students
    const deltaY = RenderTools.getStudentRadius(unitsPerPixel) * ShapeStudentStyler.STUDENT_Y_SPACING * unitsPerPixel;
    const groupedStudents = this.getStudentsGroupedByLocation(students);
    const studentsWithCoordinates: StudentWithCoordinates[] = [];
    groupedStudents.forEach((stackedStudents) => {
      const studentStack: StudentWithCoordinates[] = stackedStudents.map( (student, index) => {
        return {student: student, coordinates: [student.x , student.y + index * deltaY] as [number, number], zIndex: index};
      });
      studentsWithCoordinates.push(...studentStack);
    });

    // create a feature for each point
    const studentFeatures = studentsWithCoordinates.map(studentWithCoordinates => {
      const feature = new Feature(new Point(studentWithCoordinates.coordinates));
      feature.setProperties(this.getProperties(studentWithCoordinates, hoveredStudent));
      return feature;
    });
    return studentFeatures;
  }

  protected getStudentsGroupedByLocation(students: Student[]): ObjectBackedMap<Student[]> {

    const groupedByLocation = students.reduce<ObjectBackedMap<Student[]>>((map, student) => {
      const key = student.x + "_" + student.y;
      if (map.contains(key)) {
        map.get(key).push(student);
      } else {
        map.put(key, [student]);
      }
      return map;
    }, new ObjectBackedMap<Student[]>());

    return groupedByLocation;
  }
}
