import Feature from "ol/Feature";
import Style from "ol/style/Style";
import FillStyle from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import { LodHelper } from "./lod-helper";

/**
 * Base styler. All stylers inherit from this class
 */
export abstract class Styler {

  protected invisibleStyle = new Style({
    stroke: new Stroke({ color: [0, 0, 0, 0] }),
    zIndex: 100
  });

  abstract getStyle(feature: Feature, lod: number): Style | Style[];
}

/**
 * Stores a style per level of detail
 */
abstract class LodStyler extends Styler {
  protected readonly lodStyles: Array<Style | Array<Style>> = [];

  constructor() {
    super();
    this.makeAllStyles();
  }

  public getStyle(feature: Feature, lod: number): Style | Style[] {
    return this.lodStyles[lod];
  }

  protected makeAllStyles(): void {
    for (let i = 0; i <= LodHelper.MAX_LOD; i++) {
      this.lodStyles[i] = this.makeStyle(i);
    }
  }

  protected abstract makeStyle(lod: number): Style | Array<Style>;
}

/**
 * Hides features with this style
 */
export class InvisibleStyler extends Styler {

  getStyle(feature: Feature, lod: number): Style[] {
    return [this.invisibleStyle];
  }
}

export class MotorwayRoadStyler extends LodStyler {

  protected makeStyle(lod: number): Style | Array<Style> {

    const orangeLineWidth = [12, 9, 6, 5, 5, 4, 3, 3, 3, 2];
    const redLineWidth = [16, 12, 8, 7, 6, 6, 5, 4, 0, 0];

    const redStyle = new Style({
      stroke: new Stroke({ color: "#FF0000", width: redLineWidth[lod] }),
      zIndex: 19
    });
    const orangeStyle = new Style({
      stroke: new Stroke({ color: "#FD9235", width: orangeLineWidth[lod] }),
      zIndex: 20
    });

    if (lod === 9) {
      return orangeStyle;
    } else {
      return [redStyle, orangeStyle, this.invisibleStyle];
    }
  }
}

export class MotorwayLinkRoadStyler extends LodStyler {

  protected makeStyle(lod: number): Style | Array<Style> {

    const orangeLineWidth = [7, 6, 4, 4, 3, 2, 2, 2, 2, 0];
    const redLineWidth = [10, 7, 5, 5, 4, 3, 0, 0, 0, 0];

    const redStyle = new Style({
      stroke: new Stroke({ color: "#FF0000", width: redLineWidth[lod] }),
      zIndex: 19
    });
    const orangeStyle = new Style({
      stroke: new Stroke({ color: "#FD9235", width: orangeLineWidth[lod] }),
      zIndex: 20
    });

    if (lod === 9) {
      return this.invisibleStyle;
    } else if (lod >= 6 && lod < 9) {
      return orangeStyle;
    } else {
      return [redStyle, orangeStyle, this.invisibleStyle];
    }
  }
}

export class PrimaryRoadStyler extends LodStyler {

  protected makeStyle(lod: number): Style | Array<Style> {

    const yellowLineWidth = [12, 9, 5, 4, 4, 3, 3, 3, 2, 0];
    const grayLineWidth = [16, 12, 7, 6, 6, 5, 4, 4, 3, 0];

    const style1 = new Style({
      stroke: new Stroke({ color: "#D8D8D8", width: grayLineWidth[lod] }),
      zIndex: 17
    });
    const style2 = new Style({
      stroke: new Stroke({ color: lod < 8 ? "#FFFF80" : "#FFFD8B", width: yellowLineWidth[lod] }),
      zIndex: 18
    });

    if (lod === 9) {
      return this.invisibleStyle;
    } else if (lod === 8) {
      return style2;
    } else {
      return [style1, style2, this.invisibleStyle];
    }
  }
}

export class SecondaryRoadStyler extends LodStyler {

  protected makeStyle(lod: number): Style | Array<Style> {
    const yellowLineWidth = [12, 8, 4, 3, 3, 3, 3, 2, 0, 0];
    const grayLineWidth = [15, 11, 6, 5, 5, 4, 4, 3, 0, 0];

    const style1 = new Style({
      stroke: new Stroke({ color: "#D8D8D8", width: grayLineWidth[lod] }),
      zIndex: 15
    });
    const style2 = new Style({
      stroke: new Stroke({ color: "#FFFF80", width: yellowLineWidth[lod] }),
      zIndex: 16
    });

    if (lod >= 8) {
      return this.invisibleStyle;
    } else {
      return [style1, style2, this.invisibleStyle];
    }
  }
}

export class TertiaryRoadStyler extends LodStyler {

  protected makeStyle(lod: number): Style | Array<Style> {
    const yellowLineWidth = [12, 8, 4, 3, 3, 3, 2, 0, 0, 0];
    const grayLineWidth = [15, 11, 6, 5, 4, 4, 3, 0, 0, 0];

    const style1 = new Style({
      stroke: new Stroke({ color: "#D8D8D8", width: grayLineWidth[lod] }),
      zIndex: 13
    });
    const style2 = new Style({
      stroke: new Stroke({ color: "#FFFF80", width: yellowLineWidth[lod] }),
      zIndex: 14
    });

    if (lod >= 7) {
      return this.invisibleStyle;
    } else {
      return [style1, style2, this.invisibleStyle];
    }
  }
}

export class MinorRoadStyler extends LodStyler {


  protected makeStyle(lod: number): Style | Array<Style> {

    const whiteLineWidth = [12, 8, 4, 3, 3, 2, 0, 0, 0, 0];
    const grayLineWidth = [15, 11, 6, 5, 3, 3, 1, 0, 0, 0];

    const style1 = new Style({
      stroke: new Stroke({ color: "#E2E2E2", width: grayLineWidth[lod] }),
      zIndex: 11
    });
    const style2 = new Style({
      stroke: new Stroke({ color: "#FFFFFF", width: whiteLineWidth[lod] }),
      zIndex: 12
    });

    if (lod >= 7) {
      return this.invisibleStyle;
    } else if (lod === 6) {
      style1.getStroke().setColor("#D2D2D2");
      return style1;
    } else {
      style1.getStroke().setColor("#E2E2E2");
      return [style1, style2, this.invisibleStyle];
    }
  }
}


export class ForestStyler extends Styler {

  private readonly forestStyle = new Style({
    fill: new FillStyle({ color: "#B3DAA4" }),
    zIndex: 5
  });

  getStyle(feature: Feature, lod: number): Style[] {
    return [this.forestStyle];
  }
}

export class RiverStyler extends LodStyler {

  protected makeStyle(lod: number): Style | Array<Style> {

    const lineWidth = [15, 10, 6, 4, 3, 3, 2, 1, 0, 0];

    if (lod >= 8) {
      return this.invisibleStyle;
    } else {
      const style = new Style({
        stroke: new Stroke({ color: "#a5bfdd", width: lineWidth[lod] }),
        zIndex: 6
      });
      return style;
    }
  }

}

export class StreamStyler extends LodStyler {

  protected makeStyle(lod: number): Style | Array<Style> {
    const lineWidth = [5, 3, 3, 2, 2, 1, 0, 0, 0, 0];

    if (lod >= 6) {
      return this.invisibleStyle;
    } else {
      const style = new Style({
        stroke: new Stroke({ color: "#a5bfdd", width: lineWidth[lod] }),
        zIndex: 6
      });
      return style;
    }
  }
}

export class RailwaysStyler extends LodStyler {

  protected makeStyle(lod: number): Style | Array<Style> {

    const whiteLineWidth = [11, 7, 3, 2, 1, 1, 0, 0, 0, 0];
    const blackLineWidth = [13, 9, 4, 3, 2, 2, 1, 0, 0, 0];
    const lineDashes = [60, 43, 21, 17, 10, 8, 4, 0, 0, 0, 0]

    const style1 = new Style({
      stroke: new Stroke({ color: "#404040", width: blackLineWidth[lod], lineCap: "square" }),
      zIndex: 11
    });
    const style2 = new Style({
      stroke: new Stroke({ color: "#e8e4c9", width: whiteLineWidth[lod], lineDash: [lineDashes[lod]], lineCap: "butt" }),
      zIndex: 12
    });


    return [style1, style2, this.invisibleStyle];


  }
}

export class CommunalBordersStyler extends LodStyler {
  protected makeStyle(lod: number): Style | Array<Style> {

    const lineWidth = [8, 6, 5, 4, 3, 2, 2, 1, 0, 0];

    if (lod >= 8) {
      return this.invisibleStyle;
    } else {
      const style = new Style({
        stroke: new Stroke({ color: "#dc9fbd", width: lineWidth[lod] }),
        zIndex: 7
      });
      return style;
    }
  }
}

export class CantonalBordersStyler extends LodStyler {
  protected makeStyle(lod: number): Style | Array<Style> {

    const lineWidth = [8, 6, 5, 4, 3, 2, 2, 2, 2, 2];

    const style = new Style({
      stroke: new Stroke({ color: "#dc9fbd", width: lineWidth[lod] }),
      zIndex: 7
    });
    return style;
  }
}

export class NationalBordersStyler extends LodStyler {
  protected makeStyle(lod: number): Style | Array<Style> {

    const lineWidth = [14, 14, 12, 10, 7, 5, 5, 4, 3, 3];

    const style = new Style({
      stroke: new Stroke({ color: "#dc9fbd", width: lineWidth[lod] }),
      zIndex: 7
    });
    return style;
  }
}

export class FootwayRoadStyler extends LodStyler {
  protected makeStyle(lod: number): Style | Array<Style> {
    if (lod >= 4) {
      return this.invisibleStyle;
    } else {
      const style = new Style({
        stroke: new Stroke({ color: "#ee2f32", width: 1, lineDash: [2] }),
        zIndex: 10
      });
      return style;
    }
  }
}


export class BuildingStyler extends LodStyler {

  protected makeStyle(lod: number): Style | Array<Style> {
    if (lod >= 5) {
      return this.invisibleStyle;
    } else {
      const style = new Style({
        fill: new FillStyle({color: "#A9A9A9"}),
        zIndex: 6
      });
      return style;
    }
  }
}

