import MapBrowserEvent from "ol/MapBrowserEvent";
import DoubleClickZoomInteraction from "ol/interaction/DoubleClickZoom";
import OlPolygon from "ol/geom/Polygon";
import OlLineString from "ol/geom/LineString";
import Map from "ol/Map";
import MultiPoint from "ol/geom/MultiPoint";
import Feature from "ol/Feature";
import Style from "ol/style/Style";
import FillStyle from "ol/style/Fill";
import StrokeStyle from "ol/style/Stroke";
import RegularShape from "ol/style/RegularShape";

import {GeometryService} from "../../services/geometry/geometry.service";
import {Polygon, Vertex} from "../../services/geometry/providers/geometry-provider";

import * as Color from "color";

import * as _ from "lodash";
import {MousePositionService} from "../../services/mouse-position.service";
import {NotifyService} from "../../services/notify.service";
import {AppstateService} from "../../services/appstate.service";
import {Painter, DrawingLayer} from "../drawing-layer";
import {VertexMetadata} from "../../services/geometry/vertex-metadata";

export class PolygonCreationTool implements Painter {
  private static readonly DBL_CLICK_TOLERANCE_SQUARED = 64;
  private lastClickEvt: MapBrowserEvent;
  private secondLastClickEvt: MapBrowserEvent;

  private polygon: Polygon;

  private isActive = false;
  private hoverVertex: Vertex;
  private drawingLayer: DrawingLayer;

  private readonly polygonStyle = new Style({
    fill: new FillStyle({color: [255, 255, 255, 0.2]}),
    stroke: new StrokeStyle({color: [255, 255, 255, 0.2], width: 0})
  });

  private readonly lineStringStyle = new Style({
    stroke: new StrokeStyle({color: "black", width: 2})
  });

  private readonly routedPointStyle = new Style({
    image: new RegularShape({
      fill: new FillStyle({color: "white"}),
      stroke: new StrokeStyle({color: "black", width: 1.8}),
      points: 4,
      radius: 6,
      angle: Math.PI / 4
    })
  });

  private readonly notRoutedPointStyle = new Style({
    image: new RegularShape({
      fill: new FillStyle({color: "white"}),
      stroke: new StrokeStyle({color: "black", width: 1.0}),
      points: 4,
      radius: 6,
      angle: Math.PI / 4
    })
  });

  constructor(
    private geometryService: GeometryService,
    private zoomInteraction: DoubleClickZoomInteraction,
    private mousePositionService: MousePositionService,
    private notifyService: NotifyService,
    private appStateService: AppstateService,
    private map: Map,
    private mapOffsetFromTop: number
  ) {}

  public drawNewPolygon(name: string, color: Color): void {
    this.polygon = new Polygon(name, color, [], true);
    this.geometryService.addPolygon(this.polygon);
    this.activate();

    // hack: get mouse position from dialog
    const mouseScreenCoordinates = _.clone(this.mousePositionService.currentCoordinates);
    if (mouseScreenCoordinates) {
      mouseScreenCoordinates[1] -= this.mapOffsetFromTop; // adjust for map offset from the top
      const coordinates = this.map.getCoordinateFromPixel(mouseScreenCoordinates);
      this.onMousePositionChanged(coordinates[0], coordinates[1]);
    }
  }

  public onClick(evt: MapBrowserEvent): void {
    if (this.isActive && this.polygon !== undefined) {
      this.secondLastClickEvt = this.lastClickEvt;
      this.lastClickEvt = evt;
      const vertexMeta = this.geometryService.getOrCreateVertex(evt.coordinate[0], evt.coordinate[1]);
      if (vertexMeta !== undefined && !vertexMeta.vertex.equals(_.last(this.polygon.getHumanCreatedVertices()))) {
        if (vertexMeta.vertex.equals(_.first(this.polygon.getHumanCreatedVertices()))) {
          if (this.tryClosePolygon()) {
            this.geometryService.addVertexToPolygon(this.polygon, vertexMeta);
            this.afterPolygonClosed();
          }
        } else {
          this.geometryService.addVertexToPolygon(this.polygon, vertexMeta);
        }
        this.triggerRepaintIfPossible();
      }
    }
  }

  public onDblClick(evt: MapBrowserEvent): void {
    // make sure that both clicks were close together
    if (!this.isWithinDoubleClickTolerance(evt, this.secondLastClickEvt)) {
      return;
    }

    if (this.isActive && this.polygon !== undefined) {
      if (this.polygon.getHumanCreatedVertices().length > 0) {
        if (this.tryClosePolygon()) {
          this.geometryService.addVertexToPolygon(this.polygon, new VertexMetadata(_.first(this.polygon.getHumanCreatedVertices())));
          this.afterPolygonClosed();
          evt.stopPropagation(); // avoid zoom interaction getting this event
        }
        this.triggerRepaintIfPossible();
      }
    }
  }

  public onPointerMove(evt: MapBrowserEvent): void {
    // if map was dragged, we do not have changed coordinates
    if (evt.dragging) return;

    // update map
    this.onMousePositionChanged(evt.coordinate[0], evt.coordinate[1]);
  }

  private onMousePositionChanged(x: number, y: number): void {
    // get hover vertex
    if (this.isActive && this.polygon !== undefined) {
      const vertexMeta = this.geometryService.getOrCreateVertex(x, y);
      if (vertexMeta !== undefined) {
        this.triggerRepaintIfPossible(vertexMeta.vertex);
      }
    }
  }

  private isWithinDoubleClickTolerance(evt1: MapBrowserEvent, evt2: MapBrowserEvent): boolean {
    if (!evt1 || !evt2) return false;
    const x1 = evt1.pixel[0];
    const x2 = evt2.pixel[0];
    const y1 = evt1.pixel[1];
    const y2 = evt2.pixel[1];
    const distSq = Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2);
    return distSq <= PolygonCreationTool.DBL_CLICK_TOLERANCE_SQUARED;
  }

  /**
   * Attempts to complete the polygon. This is only possible if it has at
   * least 3 vertices. Returns true if polygon was closed, and false if it
   * was not possible to close the polygon.
   */
  private tryClosePolygon(): boolean {
    if (this.polygon.getHumanCreatedVertices().length >= 3) {
      return true;
    } else {
      if (this.appStateService.isAdminState()) {
        this.notifyService.showError("geometry.polygonCreationTool.adminNotEnoughVertices");
      } else {
        this.notifyService.showError("geometry.polygonCreationTool.defaultNotEnoughVertices");
      }
      return false;
    }
  }

  private afterPolygonClosed(): void {
    this.deactivate();
    this.geometryService.onPolygonComplete(this.polygon);
  }

  setDrawingLayer(drawingLayer: DrawingLayer): void {
    this.drawingLayer = drawingLayer;
  }

  getFeatures(): Feature[] {
    if (this.isActive && this.polygon !== undefined) {
      // get coordinates
      const polygonCoordinates = this.polygon.getOpenlayersRoutedCoordinates(this.hoverVertex);
      const routedVertexCoordinates = this.polygon.getOpenlayersCoordinates(this.hoverVertex, v => v.isOnRoutableGeometry);
      const notRoutedVertexCoordinates = this.polygon.getOpenlayersCoordinates(this.hoverVertex, v => !v.isOnRoutableGeometry);

      // create polygon
      const polygonFeature = new Feature(new OlPolygon([polygonCoordinates]));
      polygonFeature.setProperties({type: "polygon"});

      // create line string
      const lineFeature = new Feature(new OlLineString(polygonCoordinates));
      lineFeature.setProperties({type: "line"});

      // create vertices
      const routedPointFeature = new Feature(new MultiPoint(routedVertexCoordinates));
      routedPointFeature.setProperties({type: "points", routed: "routed"});
      const notRoutedPointFeature = new Feature(new MultiPoint(notRoutedVertexCoordinates));
      notRoutedPointFeature.setProperties({type: "points"});

      return [polygonFeature, lineFeature, routedPointFeature, notRoutedPointFeature];
    } else {
      return [];
    }
  }

  getStyle(feature: Feature): Style | Style[] {
    const type: string = feature.get("type");
    const color = this.polygon.color;

    switch (type) {
      case "polygon":
        this.polygonStyle.getFill().setColor(color.alpha(0.3).array() as [number, number, number, number]);
        return this.polygonStyle;
      case "line":
        this.lineStringStyle.getStroke().setColor(color.alpha(0.8).array() as [number, number, number, number]);
        return this.lineStringStyle;
      case "points":
        const routed = feature.get("routed");
        if (routed) {
          return this.routedPointStyle;
        } else {
          return this.notRoutedPointStyle;
        }
      default:
        return undefined;
    }
  }

  private triggerRepaintIfPossible(hoverVertex?: Vertex): void {
    this.hoverVertex = hoverVertex;
    if (this.drawingLayer !== undefined) this.drawingLayer.triggerRepaint();
  }

  private activate(): void {
    this.zoomInteraction.setActive(false); // disable double click zoom
    this.isActive = true;
  }

  public deactivate(updateSource?: boolean): void {
    this.isActive = false;
    this.zoomInteraction.setActive(true); // enable double click zoom
  }
}
