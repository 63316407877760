import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import GeoJSONFormat from "ol/format/GeoJSON";
import Feature from "ol/Feature";

import Style from "ol/style/Style";
import FillStyle from "ol/style/Fill";

import {RoadStyleManager, NaturalStyleManager, LineStyleManager, VillabeLabelStyleManager, BuildingStyleManager} from "./style-manager";
import {AppstateService} from "../../services/appstate.service";
import {HttpClient} from "@angular/common/http";
import {InjectionToken, Inject, Injectable} from "@angular/core";
import {AuthService} from "src/app/services/auth/auth.service";
import { NotifyService } from "src/app/services/notify.service";

export const ApiMapBaseUrlToken = new InjectionToken<string>("ApiMapBaseUrlToken");

@Injectable({
  providedIn: "root"
})
export class ShapefileLoader {
  private static readonly RENDER_MODE = "image"; // image or vector
  private static readonly UPDATE_WHILE_INTERACTING = false; // only together with RENDER_MODE="vector"
  private static readonly UPDATE_WHILE_ANIMATING = false; // only together with RENDER_MODE="vector"

  constructor(
    private authService: AuthService,
    private appStateService: AppstateService,
    private notifyService: NotifyService,
    private httpClient: HttpClient,
    @Inject(ApiMapBaseUrlToken) private mapBaseUrl?: string
  ) {}

  loadMap(): VectorLayer {
    // create style managers
    const roadStyleManager = new RoadStyleManager();
    const naturalStyleManager = new NaturalStyleManager();
    const lineStyleManager = new LineStyleManager();
    const villageLabelStyleManager = new VillabeLabelStyleManager();
    const buildingStyleManager = new BuildingStyleManager();

    // create fixed style
    const waterStyle = new Style({
      fill: new FillStyle({color: "#a5bfdd"}),
      zIndex: 6
    });



    const compositeSource = new VectorSource({
      format: new GeoJSONFormat(),
      loader: (extent, resolution, proj) => {
        const url = this.mapBaseUrl + "/" + this.authService.realmId;
        const format = new GeoJSONFormat();

        this.httpClient.get<string>(url).subscribe(responseText => {
          compositeSource.addFeatures(format.readFeatures(responseText));
        }, err => {
          this.notifyService.showError("apiErrors.map.loadMap");
          this.appStateService.setMapLoading(false);
        });
      }
    });

    const compositeLayer = new VectorLayer({
      source: compositeSource,
      declutter: true,
      renderMode: ShapefileLoader.RENDER_MODE,
      style: (feature: Feature, resolution: number) => {
        const virtualShapefile = feature.getProperties()["virtualShapefile"];
        switch (virtualShapefile) {
          case "road":
            return roadStyleManager.manageStyle(feature, resolution);
          case "natural":
            return naturalStyleManager.manageStyle(feature, resolution);
          case "line":
            return lineStyleManager.manageStyle(feature, resolution);
          case "water":
            return waterStyle;
          case "place":
            return villageLabelStyleManager.manageStyle(feature, resolution);
          case "building":
            return buildingStyleManager.manageStyle(feature, resolution);
          default:
            return undefined;
        }
      },
      updateWhileAnimating: ShapefileLoader.UPDATE_WHILE_ANIMATING,
      updateWhileInteracting: ShapefileLoader.UPDATE_WHILE_INTERACTING
    });
    compositeLayer.set("name", "map");

    compositeSource.once("change", () => {
      if (compositeSource.getState() === "ready") {
        this.appStateService.setMapLoading(false);
      } else {
        throw Error("Vector source not ready - Should not happen!");
      }
    });

    return compositeLayer;
  }
}
