import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActiveFiltersService {

  constructor() {}

  private activeFilters: ActiveFilters = {
    foreignLanguage : null,
    klasse : null,
    planKlasse : null,
    stufe : null,
    gender : null,
    schoolBuildingType : null
  };

  private activeFiltersSubject: BehaviorSubject<ActiveFilters> = new BehaviorSubject(this.activeFilters);
  private isLoadingData: BehaviorSubject<boolean> = new BehaviorSubject(false);


  setActiveFilters(rawFilters: RawFilters): void {
    const filters = this.mapRawToActive(rawFilters);
    this.activeFiltersSubject.next(filters);
    this.setLoadingDataFilters(true);
  }

  resetActiveFilters(): void {
    this.activeFiltersSubject.next(this.activeFilters);
  }

  get emptyFilters(): ActiveFilters {
    return this.activeFilters;
  }

  public getActiveFilters(): Observable<ActiveFilters> {
    return this.activeFiltersSubject.asObservable();
  }

  setLoadingDataFilters(value: boolean): void {
    this.isLoadingData.next(value);
  }

  public isLoadingDataFilters(): Observable<boolean> {
    return this.isLoadingData.asObservable();
  }

  private mapRawToActive(s: RawFilters): ActiveFilters {
    let activeFilters: ActiveFilters;
    activeFilters = {
      foreignLanguage: s.Fremdsprachig === "true" ? true : (s.Fremdsprachig === "false" ? false : null),
      klasse: s.Klasse === "true" ? true : (s.Klasse === "false" ? false : null),
      planKlasse: s.Planklasse === "true" ? true : (s.Planklasse === "false" ? false : null),
      stufe: s.Stufe ? s.Stufe : null,
      gender: s.Geschlecht ? s.Geschlecht : null,
      schoolBuildingType: s.Schulhaustyp ? s.Schulhaustyp : null
    };
    return activeFilters;
  }

}

export interface ActiveFilters {
  foreignLanguage?: boolean;
  gender?: string;
  klasse?: boolean;
  planKlasse?: boolean;
  schoolBuildingType?: string;
  stufe?: string;
}

export interface RawFilters {
  Fremdsprachig?: string;
  Geschlecht?: string;
  Klasse?: string;
  Planklasse?: string;
  Schulhaustyp?: string;
  Stufe?: string;
}
