import {Component, OnInit, Inject} from "@angular/core";
import {MAT_SNACK_BAR_DATA} from "@angular/material";
import {trigger, transition, animate, keyframes, style} from "@angular/animations";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
  animations: [
    trigger("pulse", [
      transition(":enter", [
        animate(800, keyframes([style({transform: "scale3d(1, 1, 1)"}), style({transform: "scale3d(0.7, 0.7, 0.7)"}), style({transform: "scale3d(1, 1, 1)"})]))
      ])
    ])
  ]
})
export class NotificationComponent implements OnInit {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public notification: Notification) {}

  icon: string;
  type: string;

  ngOnInit(): void {
    switch (this.notification.type) {
      case NotificationType.INFO:
        this.icon = "info";
        this.type = "info";
        break;
      case NotificationType.SUCCESS:
        this.icon = "check_circle";
        this.type = "success";
        break;
      case NotificationType.WARNING:
        this.icon = "warning";
        this.type = "warning";
        break;
      case NotificationType.ERROR:
        this.icon = "error";
        this.type = "error";
        break;
    }
  }
}

export interface Notification {
  message: string;
  type: NotificationType;
}

export enum NotificationType {
  INFO,
  SUCCESS,
  WARNING,
  ERROR
}
