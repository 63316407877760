import {Component, OnInit, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef, Output, EventEmitter} from "@angular/core";
import {StudentDataService} from "../../../services/student/student-data.service";
import {Observable, combineLatest} from "rxjs";
import {MatInput} from "@angular/material";
import {FormControl} from "@angular/forms";
import {startWith, map} from "rxjs/operators";
import * as _ from "lodash";
import {Student} from "../../../services/student/providers/student-provider";
import {StudentMapService} from "../../../services/student/student-map.service";

@Component({
  selector: "app-students-list",
  templateUrl: "./students-list.component.html",
  styleUrls: ["./students-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentsListComponent implements OnInit {
  constructor(private studentDataService: StudentDataService, private studentMapService: StudentMapService, private cd: ChangeDetectorRef) {}

  public studentObservable: Observable<Array<StudentGroups>>;
  noMatchesAssigned: boolean;
  noMatchesUnassigned: boolean;
  studentGroups: Array<StudentGroups> = [];

  @ViewChild("searchMatInput", {read: MatInput})
  searchMatInput: MatInput;
  searchInput: FormControl = new FormControl();

  get searchFocused(): boolean {
    return this.searchMatInput.focused;
  }

  setStudentOnHover(student: Student): void {
    return this.studentMapService.setMenuHovered(student);
  }

  ngOnInit(): void {
    // Filter the students in the 2 groups with the filtered list
    combineLatest([this.studentDataService.getStudents(), this.searchInput.valueChanges.pipe(startWith(""))])
      .pipe(
        map(([students, filter]) => {
          const filteredStudents = students.filter(s => {
            const searchTerms: Array<string> = filter.toLowerCase().split(" ");
            const matchString = `${s.firstName || ""} ${s.lastName || ""}`.toLowerCase();
            return searchTerms.every(t => matchString.indexOf(t) >= 0);
          });

          const filteredAssigned = filteredStudents.filter(a => {
            return a.perimeter;
          });

          const filteredUnassigned = filteredStudents.filter(a => {
            return !a.perimeter;
          });

          if (!filteredAssigned.length) {
            this.noMatchesAssigned = true;
          } else {
            this.noMatchesAssigned = false;
          }

          if (!filteredUnassigned.length) {
            this.noMatchesUnassigned = true;
          } else {
            this.noMatchesUnassigned = false;
          }

          const groups: Array<StudentGroups> = [
            {
              assigned: false,
              students: filteredStudents.filter(s => !s.perimeter)
            },
            {
              assigned: true,
              students: filteredStudents.filter(s => !!s.perimeter)
            }
          ];

          return groups;
        })
      )
      .subscribe(groups => {
        this.studentGroups = groups;
        this.cd.markForCheck();
      });
  }
}

export interface StudentGroups {
  assigned: boolean;
  students: Array<Student>;
}
