/**
 * Implements a binary heap. Supports the following operations:
 * extractMin(): O(log n)
 * decreaseKey(): O(log n)
 * add(): O(log n)
 */
export class BinaryMinHeap<T> {
  // Holds the elements in the heap
  private heap: T[] = [];

  // Maps objects ids to indexes in the heap
  private map = {};

  // Map elements to unique keys
  private idFunc: (item: T) => string;

  // Map elments to their current score
  scoreFunc: (item: T) => number;

  constructor(idFunc: (item: T) => string, scoreFunc: (item: T) => number) {
    this.idFunc = idFunc;
    this.scoreFunc = scoreFunc;
  }

  public get length(): number {
    return this.heap.length;
  }

  public add(item: T) {
    const heapLengh = this.heap.length;
    this.heap.push(item);
    this.updateMap(heapLengh);
    this.siftUp(heapLengh);
  }

  public extractMin(): T {
    if (this.length === 0) throw Error("Cannot extract element when heap is empty.");
    const min = this.heap[0];
    if (this.heap.length > 1) {
      this.heap[0] = this.heap.pop();
      this.updateMap(0);
      this.siftDown(0);
    } else {
      this.heap.pop();
    }
    return min;
  }

  public decreaseKey(item: T) {
    this.siftUp(this.map[this.idFunc(item)]);
  }

  private siftDown(current: number): void {
    let child = this.indexOfSmallerChild(current);
    while (child < this.heap.length && this.getScore(current) > this.getScore(child)) {
      this.swapElements(current, child);
      current = child;
      child = this.indexOfSmallerChild(current);
    }
  }

  private siftUp(current: number): void {
    let parent = this.parentIndex(current);
    while (current > 0 && this.getScore(current) < this.getScore(parent)) {
      this.swapElements(parent, current);
      current = parent;
      parent = this.parentIndex(current);
    }
  }

  private swapElements(a: number, b: number): void {
    const temp = this.heap[a];
    this.heap[a] = this.heap[b];
    this.heap[b] = temp;
    this.updateMap(a);
    this.updateMap(b);
  }

  private updateMap(index: number): void {
    this.map[this.idFunc(this.heap[index])] = index;
  }

  private indexOfSmallerChild(current: number): number {
    const child = 2 * current + 1;
    if (child + 1 < this.heap.length && this.getScore(child) >= this.getScore(child + 1)) {
      return child + 1;
    }
    return child;
  }

  private getScore(index: number) {
    return this.scoreFunc(this.heap[index]);
  }

  private parentIndex(current: number): number {
    return ~~((current - 1) / 2);
  }
}
