import {Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef} from "@angular/core";
import {Observable, noop, Subject, of} from "rxjs";
import {PmiSelectItem, PmiSelectItemContainer} from "ag.pmi.dev.client.ng6.select";
import {trigger, style, transition, animate, query, stagger, keyframes} from "@angular/animations";
import {EditDialogComponent, EditDialogData} from "../../defaultDialogs/edit-dialog/edit-dialog.component";
import {MatDialog} from "@angular/material";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmDialogData, ConfirmDialogComponent} from "../../defaultDialogs/confirm-dialog/confirm-dialog.component";
import {PlanningvariantService} from "../../services/planningvariant/planningvariant.service";
import {Planningvariant} from "../../services/planningvariant/providers/planningvariant-provider";
import * as _ from "lodash";
import {NotifyService} from "../../services/notify.service";
import {finalize, map, tap} from "rxjs/operators";
import { AuthService } from "src/app/services/auth/auth.service";

@Component({
  selector: "app-planningvariant-picker",
  templateUrl: "./planningvariant-picker.component.html",
  styleUrls: ["./planningvariant-picker.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("fader", [
      transition(":enter", [
        style({opacity: 0}),
        animate(150, keyframes([style({display: "none", opacity: 0, offset: 0}), style({display: "block", offset: 1}), style({opacity: 1, offset: 1})]))
      ]),
      transition(":leave", [style({opacity: 1}), animate("75ms cubic-bezier(0.4, 0.0, 1, 1)", style({opacity: 0}))])
    ]),
    trigger("buttonAnimationAdd", [
      transition(":enter", [style({transform: "scale(0)"}), animate("100ms cubic-bezier(0.0, 0.0, 0.2, 1)", style({transform: "scale(1)"}))]),
      transition(":leave", animate("0ms cubic-bezier(0.4, 0.0, 1, 1)", style({transform: "scale(0)"})))
    ]),
    trigger("buttonAnimationEdit", [
      transition(":enter", [
        query("button", style({transform: "scale(0)"})),
        query("button", stagger(100, [animate("100ms cubic-bezier(0.0, 0.0, 0.2, 1)", style({transform: "scale(1)"}))]))
      ]),
      transition(":leave", [
        query("button", style({transform: "scale(1)"})),
        query("button", stagger(100, [animate("100ms cubic-bezier(0.4, 0.0, 1, 1)", style({transform: "scale(0)"}))]))
      ])
    ])
  ]
})
export class PlanningvariantPickerComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    public translate: TranslateService,
    public planningvariantService: PlanningvariantService,
    private notifyService: NotifyService,
    private authService: AuthService,
    private cd: ChangeDetectorRef
  ) {}

  private planningvariants: Array<Planningvariant>;
  private planningvariantToSelectboxMapper = map<Planningvariant[], PmiSelectItemContainer<Planningvariant>>(pvFromApi => {
    return {
      loadingState: false,
      items: pvFromApi.map<PmiSelectItem<Planningvariant>>(p => {
        return {
          key: p.id,
          value: p.name,
          data: p
        };
      })
    };
  });

  selectboxSubject: Subject<PmiSelectItemContainer<Planningvariant>> = new Subject();
  selectedPlanningvariantId: string;
  busy = false;

  @Input()
  readOnly: boolean;

  ngOnInit(): void {
    this.planningvariantService.getPlanningvariants().subscribe(pvs => {
      this.planningvariants = pvs;
      this.selectedPlanningvariantId = pvs.some(p => p.selected) ? _.find(pvs, p => p.selected).id : undefined;

      of(this.planningvariants)
        .pipe(this.planningvariantToSelectboxMapper)
        .subscribe(items => this.selectboxSubject.next(items));
    });
  }

  selectBoxRefresh(): void {
    this.planningvariantService
      .getPlanningvariantsWithRefresh()
      .pipe(this.planningvariantToSelectboxMapper)
      .subscribe(items => this.selectboxSubject.next(items), error => {
        this.selectboxSubject.error(error);
      });
  }

  get selectedPlanningvariant(): Planningvariant {
    return _.find(this.planningvariants, p => p.selected);
  }

  get canWrite(): boolean {
    return this.authService.planningvariant === "write";
  }

  getPlanningvariants(): Observable<PmiSelectItemContainer<Planningvariant>> {
    return this.selectboxSubject.asObservable();
  }

  planningvariantLoadError(error: any): void {
    this.notifyService.showError("apiErrors.planningvariant.load");
  }

  planningvariantChange(newVariant: PmiSelectItem<Planningvariant>): void {
    const newKey = newVariant ? newVariant.key : null;
    if (this.selectedPlanningvariantId !== newKey) {
      this.planningvariantService.setActivePlanningvariant(newKey);
    }
  }

  rename(): void {
    const dialogData: EditDialogData = {
      dialogTitle: this.translate.instant("navbar.planningvariant.planningvariantRenameDialog.title"),
      label: this.translate.instant("navbar.planningvariant.planningvariantRenameDialog.label"),
      value: this.selectedPlanningvariant.name,
      invalidValues: this.planningvariants.map(p => p.name)
    };

    const dialogRef = this.dialog.open(EditDialogComponent, {
      data: dialogData,
      width: "400px"
    });

    dialogRef.afterClosed().subscribe(newName => {
      if (newName) {
        this.busy = true;
        this.cd.markForCheck();
        this.planningvariantService
          .renamePlanningvariant(this.selectedPlanningvariantId, newName)
          .pipe(
            finalize(() => {
              this.busy = false;
              this.cd.markForCheck();
            })
          )
          .subscribe(
            () => noop,
            error => {
              this.notifyService.showError("apiErrors.planningvariant.rename");
            }
          );
      }
    });
  }

  delete(): void {
    const dialogData: ConfirmDialogData = {
      dialogTitle: this.translate.instant("navbar.planningvariant.planningvariantDeleteDialog.title", {planningvariant: this.selectedPlanningvariant.name}),
      message: this.translate.instant("navbar.planningvariant.planningvariantDeleteDialog.message").split("||")
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      width: "400px"
    });

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.busy = true;
        this.cd.markForCheck();
        this.planningvariantService
          .deletePlanningvariant(this.selectedPlanningvariantId)
          .pipe(
            finalize(() => {
              this.busy = false;
              this.cd.markForCheck();
            })
          )
          .subscribe(
            () => noop,
            error => {
              this.notifyService.showError("apiErrors.planningvariant.delete");
            }
          );
      }
    });
  }

  copy(): void {
    const dialogData: EditDialogData = {
      dialogTitle: this.translate.instant("navbar.planningvariant.planningvariantCopyDialog.title"),
      label: this.translate.instant("navbar.planningvariant.planningvariantCopyDialog.label"),
      value: this.selectedPlanningvariant.name + " (2)",
      invalidValues: this.planningvariants.map(p => p.name)
    };

    const dialogRef = this.dialog.open(EditDialogComponent, {
      data: dialogData,
      width: "400px"
    });

    dialogRef.afterClosed().subscribe(newName => {
      if (newName) {
        this.busy = true;
        this.cd.markForCheck();
        this.planningvariantService
          .copyPlanningVariant(this.selectedPlanningvariantId, newName)
          .pipe(
            finalize(() => {
              this.busy = false;
              this.cd.markForCheck();
            })
          )
          .subscribe(
            () => noop,
            error => {
              this.notifyService.showError("apiErrors.planningvariant.copy");
            }
          );
      }
    });
  }

  create(): void {
    // if no planningvariants loaded, trigger refresh to populate list for duplicate checking
    if (!(this.planningvariants || []).length) {
      this.busy = true;
      this.cd.markForCheck();
      this.planningvariantService
        .getPlanningvariantsWithRefresh()
        .pipe(
          finalize(() => {
            this.busy = false;
            this.cd.markForCheck();
          })
        )
        .subscribe(
          () => this.openCreateDialog(),
          error => {
            this.notifyService.showError("apiErrors.planningvariant.load");
          }
        );
    } else {
      this.openCreateDialog();
    }
  }

  private openCreateDialog(): void {
    const dialogData: EditDialogData = {
      dialogTitle: this.translate.instant("navbar.planningvariant.planningvariantAddDialog.title"),
      label: this.translate.instant("navbar.planningvariant.planningvariantAddDialog.label"),
      saveButtonLabel: this.translate.instant("navbar.planningvariant.planningvariantAddDialog.button"),
      value: "",
      invalidValues: this.planningvariants.map(p => p.name)
    };

    const dialogRef = this.dialog.open(EditDialogComponent, {
      data: dialogData,
      width: "400px"
    });

    dialogRef.afterClosed().subscribe(newName => {
      if (newName) {
        this.busy = true;
        this.cd.markForCheck();
        this.planningvariantService
          .createPlanningvariant(newName)
          .pipe(
            finalize(() => {
              this.busy = false;
              this.cd.markForCheck();
            })
          )
          .subscribe(
            () => noop,
            error => {
              this.notifyService.showError("apiErrors.planningvariant.create");
            }
          );
      }
    });
  }
}
