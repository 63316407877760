import Feature from "ol/Feature";
import Style from "ol/style/Style";
import FillStyle from "ol/style/Fill";
import StrokeStyle from "ol/style/Stroke";
import TextStyle from "ol/style/Text";
import {
  MotorwayRoadStyler,
  PrimaryRoadStyler,
  SecondaryRoadStyler,
  TertiaryRoadStyler,
  InvisibleStyler,
  Styler,
  MinorRoadStyler,
  ForestStyler,
  RiverStyler,
  StreamStyler,
  RailwaysStyler,
  CommunalBordersStyler,
  CantonalBordersStyler,
  NationalBordersStyler,
  MotorwayLinkRoadStyler,
  FootwayRoadStyler,
  BuildingStyler
} from "./stylers";
import { LodHelper } from "./lod-helper";
import { balancePreviousStylesIntoKeyframes } from "@angular/animations/browser/src/util";



export class RoadStyleManager {

  // road label style
  private readonly textStyle = new TextStyle({
    font: "bold 12px \"Arial\"",
    placement: "line",
    fill: new FillStyle({ color: "black" }),
    stroke: new StrokeStyle({ color: "#FFFFFF", width: 3 }),
  });

  // stylers
  readonly motorwayRoadStyler = new MotorwayRoadStyler();
  readonly motorwayLinkRoadStyler = new MotorwayLinkRoadStyler();
  readonly primaryRoadStyler = new PrimaryRoadStyler();
  readonly secondaryRoadStyler = new SecondaryRoadStyler();
  readonly tertiaryRoadStyler = new TertiaryRoadStyler();
  readonly minorRoadStyler = new MinorRoadStyler();
  readonly invisibleStyler = new InvisibleStyler();
  readonly footwayRoadStyler = new FootwayRoadStyler();

  manageStyle(feature: Feature, resolution: number): Style | Style[] {
    // determine type of road
    const roadType = feature.getProperties()["type"];
    const lod = LodHelper.getLod(resolution);

    let roadStyler: Styler;
    switch (roadType) {

      case "motorway":
        roadStyler = this.motorwayRoadStyler;
        break;
      case "motorway_link":
        roadStyler = this.motorwayLinkRoadStyler;
        break;
      case "primary":
        roadStyler = this.primaryRoadStyler;
        break;
      case "secondary":
        roadStyler = this.secondaryRoadStyler;
        break;
      case "tertiary":
        roadStyler = this.tertiaryRoadStyler;
        break;
      case "minor":
        roadStyler = this.minorRoadStyler;
        break;
      case "footway":
        roadStyler = this.footwayRoadStyler;
        break;
      default:
        roadStyler = this.invisibleStyler;

    }

    const style = roadStyler.getStyle(feature, lod);

    // add street labels
    const text = feature.get("name");
    let styleToSet: TextStyle;
    if (text !== undefined) {
      styleToSet = this.textStyle;
      this.textStyle.setText(text);
    }
    if (style instanceof Array) {
      style[style.length - 1].setText(styleToSet);
    }

    return style;
  }
}

export class NaturalStyleManager {

  // stylers
  readonly forestStyler = new ForestStyler();
  readonly invisibleStyler = new InvisibleStyler();

  manageStyle(feature: Feature, resolution: number): Style | Style[] {
    // determine type of natural
    const naturalType = feature.getProperties()["type"];
    const lod = LodHelper.getLod(resolution);

    let naturalStyler: Styler;
    switch (naturalType) {
      case "forest":
        naturalStyler = this.forestStyler;
        break;
      default:
        naturalStyler = this.invisibleStyler;
    }

    return naturalStyler.getStyle(feature, lod);
  }
}

export class BuildingStyleManager {

  // stylers
  readonly buildingStyler = new BuildingStyler();

  manageStyle(feature: Feature, resolution: number): Style | Style[] {
    // determine type of building
    const buildingType = feature.getProperties()["virtualShapefile"];
    const lod = LodHelper.getLod(resolution);

    let buildingStyler: Styler;
    if(buildingType === "building") {
      buildingStyler = this.buildingStyler;
    }

    return buildingStyler.getStyle(feature, lod);
  }
}


export class VillabeLabelStyleManager {

  private readonly villageLabelStyle = new Style({
    text: new TextStyle({
      font: "bold 14px \"Arial\"",
      fill: new FillStyle({ color: "black" }),
      stroke: new StrokeStyle({ color: "#FFFFFF", width: 3 }),
      padding: [30, 30, 30, 30]
    })
  });

  manageStyle(feature: Feature, resolution: number): Style {
      const name = feature.get("name");
      const type = feature.get("type");
      this.villageLabelStyle.setZIndex(this.getPriorityByVillageType(type) + 50);
      this.villageLabelStyle.getText().setText(name);
      return this.villageLabelStyle;
  }

  /**
   * Get priority by village type. When using decluttering, labels with a lower
   * z-index have highter priority in openlayers.
   */
  private getPriorityByVillageType(villageType: string): number {
    switch (villageType) {
      case "city":
        return 0;
      case "town":
        return 1;
      case "village":
        return 2;
      case "hamlet":
        return 3;
      default:
        return 4;
    }
  }
}

export class LineStyleManager {

  // stylers
  readonly riverStyler = new RiverStyler();
  readonly streamStyler = new StreamStyler();
  readonly railwaysStyler = new RailwaysStyler();
  readonly communalStyler = new CommunalBordersStyler();
  readonly cantonalStyler = new CantonalBordersStyler();
  readonly nationalStyler = new NationalBordersStyler();
  readonly invisibleStyler = new InvisibleStyler();

  manageStyle(feature: Feature, resolution: number): Style | Style[] {
    // determine type of road
    const lineType = feature.getProperties()["type"];
    const lod = LodHelper.getLod(resolution);

    let lineStyler: Styler;
    switch (lineType) {
      case "rail":
        lineStyler = this.railwaysStyler;
        break;
      case "river":
        lineStyler = this.riverStyler;
        break;
      case "stream":
        lineStyler = this.streamStyler;
        break;
      case "nationalBoundary":
        lineStyler = this.nationalStyler;
        break;
      case "cantonalBoundary":
        lineStyler = this.cantonalStyler;
        break;
      case "communalBoundary":
        lineStyler = this.communalStyler;
        break;
      default:
        break;
    }

    return lineStyler.getStyle(feature, lod);
  }
}
