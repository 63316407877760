import {Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef} from "@angular/core";
import {Student} from "../../../services/student/providers/student-provider";
import * as _ from "lodash";

@Component({
  selector: "app-student-panel",
  templateUrl: "./student-panel.component.html",
  styleUrls: ["./student-panel.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentPanelComponent implements OnInit {
  @Input()
  student: Student;

  ngOnInit(): void {}
}
