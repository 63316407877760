import {Injectable} from "@angular/core";
import {OAuthService, AuthConfig, JwksValidationHandler} from "angular-oauth2-oidc";
import {ConfigGeneratedClient, UserGeneratedClient, ReadWrite} from "../../API/generated/clients";
import {NotifyService} from "../notify.service";

import {Observable, of, from} from "rxjs";
import {mergeMap, tap, map} from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  constructor(
    private oauthService: OAuthService,
    private configClient: ConfigGeneratedClient,
    private userClient: UserGeneratedClient,
    private notifyService: NotifyService
  ) {}

  realmId: string;
  selectedLayer: number;

  planningvariant: Permission = "none";
  schoolarea: Permission = "none";
  mapAdmin: Permission = "none";
  dbAdmin: Permission = "none";

  get authenticated(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  initAndTryLogin(): Observable<boolean> {
    // first we have to load the config
    return this.configClient.config_Get().pipe(
      mergeMap(config => {
        const authConfig: AuthConfig = {
          issuer: config.identityBaseUrl,
          redirectUri: window.location.origin,
          silentRefreshRedirectUri: window.location.origin + "/silent-refresh.html",
          clientId: "ag.pmi.api.gis",
          scope: "openid email gis"
        };
        this.oauthService.configure(authConfig);
        this.oauthService.tokenValidationHandler = new JwksValidationHandler();

        return from(this.oauthService.loadDiscoveryDocumentAndLogin()).pipe(
          mergeMap(() => {
            if (this.authenticated) {
              this.oauthService.setupAutomaticSilentRefresh();

              return this.userClient.user_GetRealms().pipe(
                mergeMap(realms => {
                  // workaround, take first realmid
                  this.realmId = realms[0];

                  return this.userClient.user_GetCapabilities(this.realmId).pipe(
                      tap(user => {
                        this.selectedLayer = user.selectedLayers;

                        this.planningvariant = this.mapReadWriteToPermission(user.planningvariant);
                        this.schoolarea = this.mapReadWriteToPermission(user.schoolarea);
                        this.mapAdmin = this.mapReadWriteToPermission(user.mapcut);
                        this.dbAdmin = this.mapReadWriteToPermission(user.dbConfig);
                      })
                    )
                    .pipe(map(() => true));
                })
              );
            } else {
              return of(false);
            }
          })
        );
      })
    );
  }

  login(): void {
    this.oauthService.initImplicitFlow();
  }

  logout(): void {
    this.oauthService.logOut();
  }

  private mapReadWriteToPermission(rw: ReadWrite): Permission {
    switch (rw) {
      case ReadWrite.NONE:
        return "none";
      case ReadWrite.READ:
        return "read";
      case ReadWrite.WRITE:
        return "write";
      default:
        return "none";
    }
  }
}

export type Permission = "none" | "read" | "write";
