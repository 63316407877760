export class Vector {

  private readonly EPSILON = 0.001;

  public constructor(public x: number, public y: number) {}

  public add(other: Vector): Vector {
    return new Vector(this.x + other.x, this.y + other.y);
  }

  public normalize(): void {
    const denom = Math.sqrt(this.lengthSquared());
    this.scale(1 / denom);
  }

  /**
   * Normalizes the vector. If the vector length is almost 0, the vector
   * is set to zero instead in order to avoid a division by zero.
   */
  public normalizeOrSetToValue(x: number, y: number): void {
    if (this.lengthSquared() > this.EPSILON) { // prevent div by 0
      this.normalize();
    } else {
      this.x = x;
      this.y = x;
    }
  }

  public lengthSquared(): number {
    return this.x * this.x + this.y * this.y;
  }

  public scale(factor: number): void {
    this.x *= factor;
    this.y *= factor;
  }
}
