import Feature from "ol/Feature";
import Style from "ol/style/Style";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Map from "ol/Map";
import * as _ from "lodash";
import { AppstateService } from "../services/appstate.service";

export class DrawingLayer {

  private source = new VectorSource();
  private layer: VectorLayer;

  public constructor(private map: Map, private appStateService: AppstateService, private painters: Painter[], layerName: string, renderMode: string) {
    this.layer = new VectorLayer({
      source: this.source,
      renderMode: renderMode,
      style: (feature: Feature) => this.getFeatureStyle(feature)
    });
    this.layer.set("name", layerName);

    if (painters === undefined) {
      this.painters = [];
    } else {
      this.painters.forEach(painter => painter.setDrawingLayer(this));
    }

    // redraw after loading complete
    appStateService.getIsMapLoadingObservable().subscribe(isLoading => {
      if (!isLoading) this.paint();
    });

    // redraw after zoom
    this.map.on("moveend", evt => {
      this.paint();
    });

    this.paint();
  }

  getLayer(): VectorLayer {
    return this.layer;
  }

  triggerRepaint(): void {
    this.paint();
  }

  private paint(): void {

    // inactive if map is loading
    if (this.appStateService.isLoading()) return;

    const allFeatures: Feature[] = [];
    for (let i = 0; i < this.painters.length; i++) {

      // get features and remember from which painter they are from
      const features = this.painters[i].getFeatures();
      features.forEach(feature => {
        const properties = feature.getProperties();
        properties["_painterIndex"] = i;
        feature.setProperties(properties);
        allFeatures.push(feature);
      });
    }

    // add all features to source
    this.source.clear(true);
    this.source.addFeatures(allFeatures);
  }

  private getFeatureStyle(feature: Feature):  Style | Style[] {
    const painterIndex: number = feature.get("_painterIndex");
    if (painterIndex !== undefined) {
      return this.painters[painterIndex].getStyle(feature);
    } else {
      throw Error("property _painterIndex must be set on feature");
    }
  }
}

export interface Painter {

  getFeatures(): Feature[];
  getStyle(feature: Feature):  Style | Style[];
  setDrawingLayer(drawingLayer: DrawingLayer): void;
}
