import {trigger, style, transition, animate, state, keyframes, query, animateChild, group} from "@angular/animations";
import {Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {AppstateService} from "../services/appstate.service";
import {PolygonService} from "../services/geometry/polygon.service";
import {Subject} from "rxjs";
import {ConfirmDialogComponent, ConfirmDialogData} from "../defaultDialogs/confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("changeWidth", [
      state(
        "wide",
        style({
          width: "300px"
        })
      ),
      state(
        "narrow",
        style({
          width: "12px"
        })
      ),
      transition("wide => narrow", [group([query("@flyInOut", [animateChild()]), animate("200ms cubic-bezier(0.0, 0.0, 0.2, 1)")])]),
      transition("narrow => wide", [group([query("@flyInOut", [animateChild()]), animate("200ms cubic-bezier(0.0, 0.0, 0.2, 1)")])])
    ]),
    trigger("flyInOut", [
      state("in", style({transform: "translateX(0)"})),
      transition(":enter", [
        animate(
          "300ms 200ms",
          keyframes([
            style({transform: "translateX(-300px)", offset: 0}),
            style({transform: "translateX(15px)", offset: 0.3}),
            style({transform: "translateX(0)", offset: 1})
          ])
        )
      ]),
      transition(":leave", [animate("100ms cubic-bezier(0.4, 0.0, 1, 1)", style({transform: "translateX(-300px)"}))])
    ]),
    trigger("fader", [
      transition(":enter", [style({opacity: 0}), animate("100ms cubic-bezier(0.0, 0.0, 0.2, 1)", style({opacity: 1}))]),
      transition(":leave", [style({opacity: 1}), animate("100ms cubic-bezier(0.4, 0.0, 1, 1)", style({opacity: 0}))])
    ])
  ]
})
export class NavbarComponent implements OnInit {
  constructor(
    private cb: ChangeDetectorRef,
    private appStateService: AppstateService,
    public translate: TranslateService,
    private polygonService: PolygonService,
    private dialogService: MatDialog
  ) {}

  isAdmin: boolean;
  isDraw: boolean;
  needsConfirmationToExitDrawMode = false;

  ngOnInit(): void {
    this.appStateService.getDrawChangeObservable().subscribe(isDrawMode => {
      this.isDraw = isDrawMode;
      this.cb.markForCheck();
    });
    this.appStateService.getAdminChangeObservable().subscribe(isAdminMode => {
      this.isAdmin = isAdminMode;
      this.cb.markForCheck();
    });

    this.polygonService.getPolygons().subscribe(polygons => (this.needsConfirmationToExitDrawMode = polygons.some(p => p.underConstruction)));
  }

  setLang(lang: string): void {
    this.translate.use(lang);
  }

  quitDrawing(): void {
    const closeSubject = new Subject();
    closeSubject.subscribe(() => {
      this.appStateService.setToDefault();
    });

    if (this.needsConfirmationToExitDrawMode) {
      const dialogData: ConfirmDialogData = {
        dialogTitle: this.translate.instant("navbar.leaveDrawingButton.confirmDialogTitle"),
        message: this.translate.instant("navbar.leaveDrawingButton.confirmDialogMessage").split("||")
      };

      const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
        data: dialogData,
        width: "400px"
      });

      dialogRef.beforeClosed().subscribe(confirmed => {
        if (confirmed) {
          closeSubject.next();
          closeSubject.complete();
        }
      });
    } else {
      closeSubject.next();
      closeSubject.complete();
    }
  }
}
