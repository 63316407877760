import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from "@angular/core";
import {Observable} from "rxjs";
import {map, finalize} from "rxjs/operators";
import {PmiSelectItem, PmiSelectItemContainer} from "ag.pmi.dev.client.ng6.select";
import {trigger, state, style, transition, animate, keyframes} from "@angular/animations";
import {AppstateService, AppState} from "../../services/appstate.service";
import {PlanningvariantService} from "../../services/planningvariant/planningvariant.service";
import {FilterService} from "../../services/filter/filter.service";
import {Group} from "../../services/filter/providers/filter-provider";
import {NotifyService} from "../../services/notify.service";
import {StudentDataService} from "src/app/services/student/student-data.service";
import {AuthService} from "src/app/services/auth/auth.service";
import {GeometryService} from "src/app/services/geometry/geometry.service";
import {MapadminService} from "src/app/services/mapadmin/mapadmin.service";
import {ForecastService} from "src/app/services/forecast/forecast.service";
import {ChangeCoordinatesDialogComponent} from "./change-coordinates-dialog/change-coordinates-dialog.component";
import {Coordinates} from "src/app/services/mapadmin/providers/mapadmin-provider";
import {MatDialog} from "@angular/material";
import {ActiveFiltersService} from "src/app/services/active-filters.service";
import { ComboboxDialogComponent, ComboboxDialogData } from "src/app/defaultDialogs/combobox-dialog/combobox-dialog.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("rotateIcon", [
      state("true", style({transform: "rotate(0deg)"})),
      state("false", style({transform: "rotate(180deg)"})),
      transition("* => *", [animate("100ms 250ms cubic-bezier(0.0, 0.0, 0.2, 1)")])
    ]),
    trigger("showHideAnimation", [
      transition(":enter", [
        animate(
          300,
          keyframes([
            style({transform: "translateY(-100%)", offset: 0}),
            style({transform: "translateY(15px)", offset: 0.3}),
            style({transform: "translateY(0)", offset: 1})
          ])
        )
      ]),
      transition(":leave", [
        animate(
          300,
          keyframes([
            style({transform: "translateY(0)", offset: 0}),
            style({transform: "translateY(15px)", offset: 0.3}),
            style({transform: "translateY(-100%)", offset: 1})
          ])
        )
      ])
    ])
  ]
})
export class MenuComponent implements OnInit {
  constructor(
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private appStateService: AppstateService,
    private planningvariantService: PlanningvariantService,
    private studentService: StudentDataService,
    private filterService: FilterService,
    private notifyService: NotifyService,
    private authService: AuthService,
    private geometryService: GeometryService,
    private mapAdminService: MapadminService,
    private activeFilterService: ActiveFiltersService,
    private forecastService: ForecastService,
    private translate: TranslateService,
  ) {}

  isOpen = true;
  isHidden = false;
  hidePlanningvariantMenuEntries = true;
  hiddenImmediately = false;
  hideVisibilityMenu = true;

  selectedGroup: string;
  private activePlanningvariant = false;
  expandedHeight: string;

  hasStudents = false;

  studentAssignmentBusy = false;
  coordinatesBusy = false;
  databaseBusy = false;

  get canWrite(): boolean {
    return this.authService.planningvariant === "write";
  }

  get showSchoolarea(): boolean {
    return this.authService.schoolarea !== "none";
  }

  get isMapAdmin(): boolean {
    return this.authService.mapAdmin === "write";
  }

  get isDbAdmin(): boolean {
    return this.authService.dbAdmin === "write";
  }

  get isAdmin(): boolean {
    return this.showSchoolarea || this.isMapAdmin || this.isDbAdmin;
  }

  ngOnInit(): void {
    // sets the height of the mat-expansion-header for Darstellung to 40px when expanded
    this.expandedHeight = "40px";
    this.appStateService.getDrawChangeObservable().subscribe(drawingMode => {
      /*
      only alter hidden state if a planningvariant is selected
      otherwise the initial call would hide the menu delayed (because of setTimeout)

      setTimeout is needed to hide the expansion panel a tick before the whole menu is hidden,
      otherwise the expansionPanel will be opened during the leave animation, even if it was closed before
      */
      if (this.isHidden !== drawingMode && this.activePlanningvariant) {
        this.hideVisibilityMenu = true;
        this.hiddenImmediately = drawingMode;
        this.cd.markForCheck();
        setTimeout(() => {
          this.isHidden = drawingMode;
          this.cd.markForCheck();
          setTimeout(() => {
            this.hideVisibilityMenu = drawingMode;
            this.cd.markForCheck();
          });
        });
      }
    });

    this.planningvariantService.getSelectedPlanningvariant().subscribe(p => {
      this.hidePlanningvariantMenuEntries = !p;
      this.hideVisibilityMenu = !p;
      this.activePlanningvariant = !this.hidePlanningvariantMenuEntries;
      this.cd.markForCheck();
    });

    this.filterService.getSelectedGroup().subscribe(filter => {
      this.selectedGroup = filter ? filter.id.toString() : undefined;
    });

    this.studentService.getStudents().subscribe(students => {
      this.hasStudents = !!students.length;
      this.cd.markForCheck();
    });
  }

  getGroups(): Observable<PmiSelectItemContainer<Group>> {
    return this.filterService.getGroup().pipe(
      map<Group[], PmiSelectItemContainer<Group>>(apiFilter => {
        return {
          loadingState: false,
          items: apiFilter.map<PmiSelectItem<Group>>(f => {
            return {
              key: f.id.toString(),
              value: f.name,
              data: f
            };
          })
        };
      })
    );
  }

  selectGroupChange(newGroup: PmiSelectItem<Group>): void {
    const newGroupKey = newGroup ? newGroup.key : null;
    if (this.selectedGroup !== newGroupKey) {
      this.filterService.setActiveGroup(newGroup ? newGroup.data.id : null);
      if (newGroupKey == null) {
        this.activeFilterService.resetActiveFilters();
      }
    }
  }

  filterLoadError(error: any): void {
    this.notifyService.showError("apiErrors.filter.load");
  }

  setPerimeterOnStudents(): void {
    this.studentAssignmentBusy = true;
    this.cd.markForCheck();

    this.studentService
      .setPerimeters()
      .pipe(
        finalize(() => {
          this.studentAssignmentBusy = false;
          this.cd.markForCheck();
        })
      )
      .subscribe(
        () => {
          this.notifyService.showSuccess("menu.write_assignments_success_message");
        },
        error => {
          this.notifyService.showError("apiErrors.student.savePerimeters");
        }
      );
  }

  setDrawMode(): void {
    this.appStateService.setToDraw();
  }

  setAdminMode(): void {
    this.appStateService.setState(AppState.AdminDefault);
  }

  getPrognoseYears(): void {
    this.forecastService.getYears().subscribe(years => {
      this.planningvariantService.getSelectedPlanningvariant().subscribe(p => {
        var stringYears = years.map(y => y.toString() + "/" + (y+1).toString());
        const dialogData: ComboboxDialogData = {
          dialogTitle: this.translate.instant("menu.forecast.dialogTitle"),
          saveButtonLabel: this.translate.instant("menu.forecast.dialogSaveButton"),
          values: stringYears,
          additionalInformation: this.translate.instant("menu.forecast.dialogInformation")
        };

        const dialogRef = this.dialog.open(ComboboxDialogComponent, {
          data: dialogData,
          width: "400px"
        });
        dialogRef.afterClosed().subscribe(year => {
          if(year) {
            window.open("/api/rlm/" + this.authService.realmId + "/forecast/" + p.id + "/" + year.substring(0, 4), );
          }

        });

      });
    });
  }

  changeMapCoordinates(): void {
    this.coordinatesBusy = true;
    this.cd.markForCheck();

    this.geometryService
      .getMapBoundaries()
      .pipe(
        finalize(() => {
          this.coordinatesBusy = false;
          this.cd.markForCheck();
        })
      )
      .subscribe(
        coords => {
          const dialogRef = this.dialog.open(ChangeCoordinatesDialogComponent, {
            data: coords,
            width: "400px"
          });

          dialogRef.beforeClosed().subscribe((newCoordinates: Coordinates) => {
            if (newCoordinates) {
              this.coordinatesBusy = true;
              this.cd.markForCheck();

              this.mapAdminService
                .setCoordinates(newCoordinates)
                .pipe(
                  finalize(() => {
                    this.coordinatesBusy = false;
                    this.cd.markForCheck();
                  })
                )
                .subscribe(
                  response => {
                    this.notifyService.showSuccess("menu.changeCoordinates_success");
                  },
                  error => {
                    this.notifyService.showError("apiErrors.map.saveCoordinates");
                  }
                );
            }
          });
        },
        err => {
          this.notifyService.showError("apiErrors.map.loadBoundaries");
        }
      );
  }
}
