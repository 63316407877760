import {Observable} from "rxjs";
import * as Color from "color";

export enum BuildingType {
  RED = 0,
  BLUE = 1,
  GREEN = 2,
  ORANGE = 3
}

export abstract class BuildingProvider {
  static readonly BUILDING_COLORS = [
    [BuildingType.RED, Color.rgb(228, 0, 61)] as [BuildingType, Color],
    [BuildingType.BLUE, Color.rgb(12, 34, 211)] as [BuildingType, Color],
    [BuildingType.GREEN, Color.rgb(53, 176, 14)] as [BuildingType, Color],
    [BuildingType.ORANGE, Color.rgb(241, 168, 56)] as [BuildingType, Color]
  ];
  abstract getBuildings(): Observable<Array<Building>>;
}

export interface Building {
  id: number;
  name: string;
  street: string;
  zipcode: string;
  town: string;
  type: BuildingType;
  x: number;
  y: number;
  radius?: number;
}

export interface BuildingTypeName {
  key?: string;
  value?: string;
}
