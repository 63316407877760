import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Polygon, Vertex, GisException} from "./geometry-provider";
import {
  PlanningvariantWithGisDataFront as APIPlanningvariantWithGisData,
  // PlanningvariantWithGisData as APIPlanningvariantWithGisData,
  // Perimeter as APIPerimeter,
  PerimeterFront as APIPerimeter,
  // Vertex as APIVertex,
  VertexFront as APIVertex,
  // PerimeterVertexConnection as APIPerimeterVertexConnection,
  PerimeterVertexConnectionFront as APIPerimeterVertexConnection,
  PerimeterGeneratedClient,
  AusnahmenGeneratedClient,
  SchulkreisGeneratedClient,
  ZusatzebeneGeneratedClient,
  KarteGeneratedClient,

} from "src/app/API/generated/clients";

import * as Color from "color";
import * as _ from "lodash";
import {map} from "rxjs/operators";

import {PlanungsvariantenGeneratedClient} from "src/app/API/generated/clients";
import {Planningvariant} from "../../planningvariant/providers/planningvariant-provider";
import {GeometrySnap} from "../../../routing/geometry-snap";
import {AuthService} from "../../auth/auth.service";
import {AppstateService} from "../../appstate.service";
import { ApiBaseGeometryProvider } from "./api-base-geometry-provider";

@Injectable({
  providedIn: "root"
})
export class ApiPerimeterGeometryProvider extends ApiBaseGeometryProvider {
  public static readonly SNAP_TOLERANCE = 0.001;

  constructor(
    private planningvariantApiClient: PlanungsvariantenGeneratedClient,
    private apiClient: PerimeterGeneratedClient,
    gisExceptionApiClient: AusnahmenGeneratedClient,
    mapApiClient: KarteGeneratedClient,
    authService: AuthService,
  ) {
    super(gisExceptionApiClient, mapApiClient, authService);
  }

  getPolygons(geometrySnap: GeometrySnap, planningvariantId?: string): Observable<Polygon[]> {
    return this.planningvariantApiClient.planungsvarianten_GetById(planningvariantId, this.authService.realmId).pipe(
      map(pv => {
        const vertices = pv.vertices.map<Vertex>(v => {
          const vertex = new Vertex(v.x, v.y, v.aufRoutingGeometrie, undefined, v.manuell, v.id.toString());
          if (vertex.isOnRoutableGeometry) {
            vertex.routingSegment = geometrySnap.snapTo(vertex.x, vertex.y, ApiPerimeterGeometryProvider.SNAP_TOLERANCE).segment;
          } else {
            vertex.isOnRoutableGeometry = false;
          }
          return vertex;
        });

        return pv.perimeter.map<Polygon>(
          p => {
            return new Polygon(
              p.bezeichnung,
              Color(p.farbe),
              p.perimeterConnections.map(pc => _.find(vertices, v => v.id === pc.vertexId.toString())),
              false,
              p.id.toString(),
              p.gisExceptions.map<GisException>(e => {
                return {
                  id: e.id.toString(),
                  perimeterId: e.perimeterID.toString(),
                  studentId: e.schülerID
                };
              })
            )
          }
        );
      })
    );
  }

  renamePolygon(id: string, newName: string): Observable<void> {
    return this.apiClient.perimeter_Rename(id, newName, this.authService.realmId);
  }

  deletePolygon(id: string): Observable<void> {
    return this.apiClient.perimeter_Delete(id, this.authService.realmId);
  }

  savePolygons(polygons: Polygon[], planningvariant?: Planningvariant): Observable<void> {
    const apiPlanningvariantWithGisData: APIPlanningvariantWithGisData = {
      id: planningvariant.id,
      name: planningvariant.name,
      perimeter: polygons.map<APIPerimeter>(p => {
        return {
          id: p.id,
          planungsvarianteID: planningvariant.id,
          bezeichnung: p.name,
          farbe: p.color.hex(),
          perimeterConnections: p.getAllVertices().map<APIPerimeterVertexConnection>(v => {
            return {
              perimeterId: p.id,
              vertexId: v.id
            };
          })
        };
      }),
      vertices: _.uniqBy(_.flatMap(polygons, p => p.getAllVertices()), v => v.id).map<APIVertex>(v => {
        return {
          id: v.id,
          x: v.x,
          y: v.y,
          aufRoutingGeometrie: v.isOnRoutableGeometry,
          manuell: v.isHumanCreated
        };
      })
    };

    return this.planningvariantApiClient.planungsvarianten_Update(apiPlanningvariantWithGisData, this.authService.realmId, planningvariant.id);
  }
}
