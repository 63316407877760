import { Vertex } from "./providers/geometry-provider";

export class VertexMetadata {

  constructor(
    public readonly vertex: Vertex,
    public readonly isNewVertex?: boolean,
    public readonly lineVertex1?: Vertex,
    public readonly lineVertex2?: Vertex
  ) {
    if (isNewVertex === undefined) this.isNewVertex = false;
  }

  public isOnLine(): boolean {
    return (this.lineVertex1 !== undefined && this.lineVertex2 !== undefined);
  }
}
