import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Polygon, Vertex, GisException} from "./providers/geometry-provider";
import {GeometryService} from "./geometry.service";

@Injectable({
  providedIn: "root"
})
export class PolygonService implements IPolygonService {
  constructor(private geometryService: GeometryService) {}

  public createPolygon(name: string): void {
    this.geometryService.createPolygon(name);
  }

  public renamePolygon(id: string, newName: string): Observable<void> {
    return this.geometryService.renamePolygon(id, newName);
  }

  public deletePolygon(id: string): Observable<void> {
    return this.geometryService.deletePolygon(id, true);
  }

  public createPerimeterException(perimeterId: string, studentId: number): Observable<string> {
    return this.geometryService.createPerimeterException(perimeterId, studentId);
  }

  public updatePerimeterException(oldPerimeterId: string, gisException: GisException): Observable<void> {
    return this.geometryService.updatePerimeterException(oldPerimeterId, gisException);
  }

  public deletePerimeterException(gisException: GisException): Observable<void> {
    return this.geometryService.deletePerimeterException(gisException);
  }

  public getPolygons(notifyOnMove = false): Observable<Polygon[]> {
    return this.geometryService.getPolygons(notifyOnMove);
  }

  public getRoutedPath(source: Vertex, dest: Vertex): Vertex[] {
    return this.geometryService.getRoutedPath(source, dest);
  }

  public deleteVertex(vertex: Vertex): void {
    this.geometryService.deleteVertex(vertex);
  }

  public splitVertex(vertexToSplit: Vertex): void {
    this.geometryService.splitVertex(vertexToSplit);
  }

  public setPolygonOnHover(polygon: Polygon): void {
    this.geometryService.setPolygonOnHover(polygon);
  }

  public getPolygonHoveredObservable(): Observable<Polygon> {
    return this.geometryService.getPolygonHoveredObservable();
  }
}

export interface IPolygonService {
  createPolygon(name: string): void;
  renamePolygon(id: string, newName: string): Observable<void>;
  deletePolygon(id: string): Observable<void>;
  createPerimeterException(perimeterId: string, studentId: number): Observable<string>;
  updatePerimeterException(oldPerimeterId: string, gisException: GisException): Observable<void>;
  deletePerimeterException(gisException: GisException): Observable<void>;
  getPolygons(notifyOnMove: boolean): Observable<Polygon[]>;
  getRoutedPath(source: Vertex, dest: Vertex): Vertex[];
  deleteVertex(vertex: Vertex): void;
  splitVertex(vertexToSplit: Vertex): void;
  setPolygonOnHover(polygon: Polygon): void;
  getPolygonHoveredObservable(): Observable<Polygon>;
}
