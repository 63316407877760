import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from "@angular/core";
import {MousePositionService} from "../../../services/mouse-position.service";
import * as _ from "lodash";
import {StudentMapService} from "../../../services/student/student-map.service";
import {BuildingMapService} from "../../../services/building/building-map.service";

import {Building} from "../../../services/building/providers/building-provider";
import {Student} from "../../../services/student/providers/student-provider";

@Component({
  selector: "app-onclick-panel",
  templateUrl: "./onclick-panel.component.html",
  styleUrls: ["./onclick-panel.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnclickPanelComponent implements OnInit {
  constructor(
    private studentMapService: StudentMapService,
    private buildingMapService: BuildingMapService,
    private mousePositionService: MousePositionService,
    private cd: ChangeDetectorRef
  ) {}

  selectedStudent: Student;
  selectedBuilding: Building;

  xyPos: [number, number] = [0, 0];

  getMousePosition(): [number, number] {
    return _.clone(this.mousePositionService.currentCoordinates);
  }

  ngOnInit(): void {
    this.buildingMapService.getClickObservable().subscribe(building => {
      this.selectedBuilding = building;
      this.selectedStudent = undefined;

      if (building) {
        this.updatePositions();
      }
      this.cd.markForCheck();
    });

    this.studentMapService.getClickObservable().subscribe(student => {
      this.selectedStudent = student;
      this.selectedBuilding = undefined;

      if (student) {
        this.updatePositions();
      }
      this.cd.markForCheck();
    });
  }

  private updatePositions(): void {
    this.xyPos = this.getMousePosition();
  }
}
