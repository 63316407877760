import { Painter, DrawingLayer } from "../drawing-layer";
import { Building, BuildingProvider, BuildingType } from "../../services/building/providers/building-provider";
import { BuildingDataService } from "../../services/building/building-data.service";
import { ObjectBackedMap } from "../../tools/object-backed-map";
import { AppstateService } from "../../services/appstate.service";

import Map from "ol/Map";
import Polygon from "ol/geom/Polygon";
import Circle from "ol/geom/Circle";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import Style from "ol/style/Style";
import FillStyle from "ol/style/Fill";
import CircleStyle from "ol/style/Circle";
import StrokeStyle from "ol/style/Stroke";
import { AppearanceService, BuildingAppearance } from "../../services/appearance.service";
import { RenderTools } from "./render-tools";
import * as Color from "color";


// HACK: import fromCircle without @types
const fromCircle = require("ol/geom/Polygon").fromCircle;

export class BuildingRenderer implements Painter {
  private drawingLayer: DrawingLayer;
  private buildings: Building[];
  private buildingAppearance: BuildingAppearance;

  private readonly buildingStyle = new Style({
    fill: new FillStyle({color: [255, 255, 255, 1.0]}),
    stroke: new StrokeStyle({color: "black", width: 1}),
  });

  private readonly circleStyle = new Style({
    fill: new FillStyle({color: [255, 255, 255, 1.0]}),
    stroke: new StrokeStyle({color: "black", width: 2.99, lineDash: [8, 5]}),
  });

  constructor(private map: Map, buildingDataService: BuildingDataService, private appStateService: AppstateService, appearanceService: AppearanceService) {
    buildingDataService.getBuildings().subscribe(buildings => {
      this.buildings = buildings;
      this.repaint();
    });

    appearanceService.getBuildingAppearanceObservable().subscribe(appearance => {
      this.buildingAppearance = appearance;
      this.repaint();
    });
  }

  setDrawingLayer(drawingLayer: DrawingLayer): void {
    this.drawingLayer = drawingLayer;
  }

  getFeatures(): Feature[] {
    // make sure we are ready
    if (this.buildings === undefined || this.buildingAppearance === undefined) return [];

    // stack buildings
    const unitsPerPixel = this.unitsPerPixel();
    const scaleFactor = unitsPerPixel * RenderTools.getBuildingUnitSize(unitsPerPixel);
    const deltaY = 5 * scaleFactor;
    const deltaX = 3 * scaleFactor;
    const buildings = this.getVisibleBuildingsGroupedByLocation();
    const buildingsWithCoordinates: BuildingWithCoordinates[] = [];
    buildings.forEach((stackedBuildings) => {
      const buildingStack: BuildingWithCoordinates[] = stackedBuildings.map( (building, index) => {
        return {building: building, coordinates: [building.x + index * deltaX, building.y + index * deltaY] as [number, number], zIndex: index};
      });
      buildingsWithCoordinates.push(...buildingStack);
    });

    // create a feature for each point
    const buildingFeatures = buildingsWithCoordinates.map(buildingWithCoords => {
      const feature = this.getFeatureForBuilding(buildingWithCoords);
      feature.setProperties({id: buildingWithCoords.building.id,
                             buildingType: buildingWithCoords.building.type.toString(),
                             type: "building",
                             zIndex: buildingWithCoords.zIndex.toString()});
      return feature;
    });

    // create featuers for buildings with a radius
    this.buildings.filter(b => (this.isBuildingVisible(b) && b.radius && b.radius > 0)).forEach(building => {
      const radius = building.radius * 1000;
      const feature = new Feature(fromCircle(new Circle([building.x, building.y], radius), 180));
      feature.setProperties({buildingType: building.type.toString(),
                             type: "radius",
                             zIndex: -1});
      buildingFeatures.push(feature);
    });

    return buildingFeatures;
  }

  getStyle(feature: Feature): Style | Style[] {

    // get color
    const colorIndex: number = parseInt( feature.get("buildingType"), 10);
    const color = BuildingProvider.BUILDING_COLORS[colorIndex][1];

    // get style for building / radius
    const type = feature.get("type");
    const style = type === "building" ? this.getBuildingStyle(feature, color) : this.getRadiusStyle(feature, color);

    // update zIndex
    const zIndex: number = parseInt(feature.get("zIndex"), 10);
    style.setZIndex(zIndex);

    return style;
  }

  private getBuildingStyle(feature: Feature, color: Color): Style {
    this.buildingStyle.getFill().setColor(color.array() as [number, number, number, number]);
    return this.buildingStyle;
  }

  private getRadiusStyle(feature: Feature, color: Color): Style {
    let strokeColor = color;
    let alpha = 0.06;
    if (strokeColor.luminosity() > 0.3) {
      alpha = 0.1;
      strokeColor = strokeColor.darken(0.2);
    }

    this.circleStyle.getFill().setColor(color.alpha(alpha).array() as [number, number, number, number]);
    this.circleStyle.getStroke().setColor(strokeColor.hex());

    return this.circleStyle;
  }

  private repaint(): void {
    if (this.drawingLayer) this.drawingLayer.triggerRepaint();
  }

  private isBuildingVisible(building: Building): boolean {
    switch (building.type) {
      case BuildingType.RED:
        return this.buildingAppearance.redBuildingVisible;
      case BuildingType.BLUE:
        return this.buildingAppearance.blueBuildingVisible;
      case BuildingType.GREEN:
        return this.buildingAppearance.greenBuildingVisible;
      case BuildingType.ORANGE:
        return this.buildingAppearance.orangeBuildingVisible;
    }
    return false;
  }

  private getVisibleBuildingsGroupedByLocation(): ObjectBackedMap<Building[]> {

    const visibleBuildings = this.buildings.filter(building => this.isBuildingVisible(building));
    const groupedByLocation = visibleBuildings.reduce<ObjectBackedMap<Building[]>>((map, building) => {
      const key = building.x + "_" + building.y;
      if (map.contains(key)) {
        map.get(key).push(building);
      } else {
        map.put(key, [building]);
      }
      return map;
    }, new ObjectBackedMap<Building[]>());

    return groupedByLocation;
  }

  private getFeatureForBuilding(buildingWithcoordinates: BuildingWithCoordinates): Feature {
    const unitsPerPixel = this.unitsPerPixel();
    const buildingUnitSize = RenderTools.getBuildingUnitSize(unitsPerPixel);
    const house = [[-6, -8], [6, -8], [6, 3], [0, 8], [-6, 3], [-6, -8]]; // basic house shape
    const featureCoordinates = house.map(c => {
      return [c[0] * unitsPerPixel * buildingUnitSize + buildingWithcoordinates.coordinates[0],
              c[1] * unitsPerPixel * buildingUnitSize + buildingWithcoordinates.coordinates[1]] as [number, number];
    });
    return new Feature(new Polygon([featureCoordinates]));
  }

  private unitsPerPixel(): number {
    return this.map.getView().getResolution();
  }
}

interface BuildingWithCoordinates {
  building: Building;
  coordinates: [number, number];
  zIndex: number;
}
