export class RenderTools {

  private static readonly STUDENT_MIN_SIZE = 4;
  private static readonly STUDENT_MAX_SIZE = 10;
  private static readonly MIN_SIZE_ABOVE = 10; // units per pixel
  private static readonly MAX_SIZE_BELOW = 1; // units per pixel

  private static readonly BUILDING_MIN_UNITLENGTH = 0.7;
  private static readonly BUILDING_MAX_UNITLENGTH = 1.5;

  public static readonly SCALE_SELECTED_STUDENT_BY_FACTOR = 3;

  static getStudentRadius(unitsPerPixel: number): number {
    const scale = this.getScale(unitsPerPixel);
    const slack = RenderTools.STUDENT_MAX_SIZE - RenderTools.STUDENT_MIN_SIZE;
    return RenderTools.STUDENT_MIN_SIZE + scale * slack;
  }

  static getBuildingUnitSize(unitsPerPixel): number {
    const scale = this.getScale(unitsPerPixel);
    const slack = RenderTools.BUILDING_MAX_UNITLENGTH - RenderTools.BUILDING_MIN_UNITLENGTH;
    return RenderTools.BUILDING_MIN_UNITLENGTH + scale * slack;
  }

  /**
   * Retuns a number between 0 and 1
   * @param unitsPerPixel
   */
  static getScale(unitsPerPixel: number): number {
    const ratio = Math.min((unitsPerPixel - RenderTools.MAX_SIZE_BELOW) / (RenderTools.MIN_SIZE_ABOVE - RenderTools.MAX_SIZE_BELOW), 1);
    const scale = Math.pow(1 - ratio, 2);
    return Math.min(Math.max(scale, 0), 1);
  }
}
