import {Component, Output, EventEmitter, ChangeDetectionStrategy, OnInit, ChangeDetectorRef} from "@angular/core";
import {StudentDataService} from "../../services/student/student-data.service";
import {AppstateService} from "src/app/services/appstate.service";

@Component({
  selector: "app-bottom-panel",
  templateUrl: "./bottom-panel.component.html",
  styleUrls: ["./bottom-panel.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BottomPanelComponent implements OnInit {
  constructor(
    private studentService: StudentDataService,
    private appStateService: AppstateService,
    private cd: ChangeDetectorRef
  ) {}

  assignedStudentsCount: number;
  unassignedStudentsCount: number;

  firstTabLangKey: string;
  showStudentTab = true;

  @Output()
  tabChange = new EventEmitter<boolean>();

  tabIndexChange(selectedIndex: number): void {
    // TODO set this to index of the forecast panel (when forecast panel is reintroduced)
    this.tabChange.emit(selectedIndex === 2);
  }

  ngOnInit(): void {
    this.studentService.getStudents().subscribe(students => {
      this.assignedStudentsCount = students.filter(s => !!s.perimeter).length;
      this.unassignedStudentsCount = students.filter(s => !s.perimeter).length;
      this.cd.markForCheck();
    });

    this.appStateService.getAdminChangeObservable().subscribe(isAdmin => {
        this.firstTabLangKey = isAdmin ? "bottomPanel.tabs.schoolareas" : "bottomPanel.tabs.perimeter";
        this.showStudentTab = !isAdmin;

        this.cd.markForCheck();
      }
    );
  }
}
