import {Observable} from "rxjs";
import {GeometryProvider, Polygon} from "./geometry-provider";
import {
  AusnahmenGeneratedClient,
  KarteGeneratedClient,
  Coordinates as APICoordinates
} from "src/app/API/generated/clients";
import {v4 as uuid} from "uuid";
import * as _ from "lodash";
import {map} from "rxjs/operators";
import {Planningvariant} from "../../planningvariant/providers/planningvariant-provider";
import {GeometrySnap} from "../../../routing/geometry-snap";
import {AuthService} from "../../auth/auth.service";
import {ColorPaletteFactory} from "src/app/tools/color-palette";
export abstract class ApiBaseGeometryProvider implements GeometryProvider {
  public static readonly SNAP_TOLERANCE = 0.001;
  protected colorPalette = ColorPaletteFactory.getDefault();
  constructor(
    protected gisExceptionApiClient: AusnahmenGeneratedClient,
    protected mapApiClient: KarteGeneratedClient,
    protected authService: AuthService,
  ) {}
  abstract getPolygons(geometrySnap: GeometrySnap, planningvariantId?: string): Observable<Polygon[]>;
  abstract renamePolygon(id: string, newName: string): Observable<void>;
  abstract deletePolygon(id: string): Observable<void>;
  abstract savePolygons(polygons: Polygon[], planningvariant?: Planningvariant): Observable<void>;
  getMapBoundaries(): Observable<[number, number, number, number]> {
    return this.mapApiClient.karte_Get(this.authService.realmId).pipe(
      map(coordinates => {
        return [coordinates.x1, coordinates.y1, coordinates.x2, coordinates.y2] as [number, number, number, number];
      })
    );
  }
  setMapBoundaries(coordinates: [number, number, number, number]): Observable<APICoordinates> {
    return this.mapApiClient.karte_Put({
      x1: coordinates[0],
      y1: coordinates[1],
      x2: coordinates[2],
      y2: coordinates[3]
    }, this.authService.realmId);
  }
  createPerimeterException(perimeterId: string, studentId: number): Observable<string> {
    return this.gisExceptionApiClient
      .ausnahmen_Post(this.authService.realmId, {
        id: uuid(),
        perimeterID: perimeterId,
        schülerID: studentId
      })
      .pipe(map(newException => newException.id));
  }
  updatePerimeterException(gisExceptionId: string, perimeterId: string): Observable<void> {
    return this.gisExceptionApiClient.ausnahmen_Change(gisExceptionId, perimeterId, this.authService.realmId);
  }
  deletePerimeterException(id: string): Observable<void> {
    return this.gisExceptionApiClient.ausnahmen_Delete(id, this.authService.realmId);
  }
}
