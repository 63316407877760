import {Observable} from "rxjs";
import {Polygon} from "../../geometry/providers/geometry-provider";

export interface PlanningvariantProvider {
  getPlanningvariants(): Observable<Array<Planningvariant>>;

  renamePlanningvariant(id: string, newName: string): Observable<void>;

  deletePlanningvariant(id: string): Observable<void>;

  createPlanningvariant(name: string): Observable<string>;

  updatePlanningvariant(planningvariant: Planningvariant): Observable<void>;

  copyPlanningvariant(id: string, newName: string): Observable<Planningvariant>;
}

export class Planningvariant {
  public constructor(public id: string, public name: string, public selected?: boolean) { }
}

export class PlanningvariantWithPolygons extends Planningvariant {
  public polygons: Array<Polygon>;
}
