import {Injectable} from "@angular/core";
import {Observable, BehaviorSubject} from "rxjs";

export enum AppState {
  GisDefault,
  GisDraw,
  GisNewPolygon,
  AdminDefault,
  AdminDraw,
  AdminNewPolygon
}

/**
 * Manages the state of the application (drawing, admin, etc.)
 */
@Injectable({
  providedIn: "root"
})
export class AppstateService {
  private state = AppState.GisDefault;
  private stateBehaviorSubject = new BehaviorSubject<AppState>(this.state);
  private drawBehaviorSubject = new BehaviorSubject<boolean>(AppstateService.isDrawState(this.state));
  private adminBehaviorSubject = new BehaviorSubject<boolean>(AppstateService.isAdminStateStatic(this.state));

  // initially loading
  private isMapLoadingSubject = new BehaviorSubject<boolean>(true);
  private mapLoadingCounter = 1;

  private static isDrawState(state: AppState): boolean {
    return state === AppState.GisDraw || state === AppState.AdminDraw || state === AppState.GisNewPolygon || state === AppState.AdminNewPolygon;
  }

  public static isEditStateStatic(state: AppState): boolean {
    return state === AppState.GisDraw || state === AppState.AdminDraw;
  }

  private static isAdminStateStatic(state: AppState): boolean {
    return state === AppState.AdminDefault || state === AppState.AdminNewPolygon || state === AppState.AdminDraw;
  }

  public isAdminState(): boolean {
    return AppstateService.isAdminStateStatic(this.state);
  }

  public isEditState(): boolean {
    return AppstateService.isEditStateStatic(this.state);
  }

  public isDrawState(): boolean {
    return AppstateService.isDrawState(this.state);
  }

  constructor() {
    this.setState(this.state);
  }

  getStateObservable(): Observable<AppState> {
    return this.stateBehaviorSubject.asObservable();
  }

  getDrawChangeObservable(): Observable<boolean> {
    return this.drawBehaviorSubject.asObservable();
  }

  getAdminChangeObservable(): Observable<boolean> {
    return this.adminBehaviorSubject.asObservable();
  }

  getIsMapLoadingObservable(): Observable<boolean> {
    return this.isMapLoadingSubject.asObservable();
  }

  setState(newState: AppState): void {
    const newStateIsDraw = AppstateService.isDrawState(newState);
    const newStateIsAdmin = AppstateService.isAdminStateStatic(newState);
    const oldStateIsDraw = AppstateService.isDrawState(this.state);
    const oldStateIsAdmin = AppstateService.isAdminStateStatic(this.state);

    this.state = newState;
    this.stateBehaviorSubject.next(this.state);

    if (oldStateIsDraw !== newStateIsDraw) {
      this.drawBehaviorSubject.next(newStateIsDraw);
    }

    if (oldStateIsAdmin !== newStateIsAdmin) {
      this.adminBehaviorSubject.next(newStateIsAdmin);
    }
  }

  setToDraw(): void {
    if (this.isAdminState()) {
      this.setState(AppState.AdminDraw);
    } else {
      this.setState(AppState.GisDraw);
    }
  }

  setToDefault(): void {
    if (this.isAdminState()) {
      this.setState(AppState.AdminDefault);
    } else {
      this.setState(AppState.GisDefault);
    }
  }

  setToNewPolygon(): void {
    if (this.isAdminState()) {
      this.setState(AppState.AdminNewPolygon);
    } else {
      this.setState(AppState.GisNewPolygon);
    }
  }

  setMapLoading(loading: boolean): void {
    if (loading) {
      this.mapLoadingCounter++;
    } else {
      this.mapLoadingCounter--;
    }

    loading = this.mapLoadingCounter > 0;

    if (loading !== this.isMapLoadingSubject.value) {
      this.isMapLoadingSubject.next(loading);
    }
  }

  isLoading(): boolean {
    return this.isMapLoadingSubject.value;
  }
}
