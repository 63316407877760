import {Injectable} from "@angular/core";
import {Vertex} from "./geometry/providers/geometry-provider";
import {Observable, BehaviorSubject, of, Subject} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class VertexTooltipService {
  private vertexTooltipSubject: Subject<Vertex> = new Subject();

  constructor() {}

  getObservable(): Observable<Vertex> {
    return this.vertexTooltipSubject.asObservable();
  }

  showTooltip(vertex: Vertex): void {
    this.vertexTooltipSubject.next(vertex);
  }
}
