export class ObjectBackedMap<T> {
  private items: { [index: string]: T} = {};
  private count = 0;

  public get length(): number {
    return this.count;
  }

  public contains(key: string): boolean {
    return this.items[key] !== undefined;
  }

  public get(key: string): T {
    return this.items[key];
  }

  public remove(key: string): void {
    if (this.contains(key)) {
      this.count--;
      delete this.items[key];
    }
  }

  public put(key: string, value: T): void {
    if (!this.contains(key)) {
      this.count++;
    }
    this.items[key] = value;
  }

  public forEach(func: (value: T) => void): void {
    this.toArray().forEach(obj => func(obj));
  }

  public toArray(): T[] {
    return Object.values(this.items);
  }

}
