import {Injectable} from "@angular/core";
import {Polygon, GisExceptionWithPerimeter} from "../geometry/providers/geometry-provider";
import {Observable, combineLatest} from "rxjs";
import {Student, StudentsAndPerimeter} from "./providers/student-provider";
import {BehaviorSubject} from "rxjs";
import {PolygonService} from "../geometry/polygon.service";

import * as _ from "lodash";
import {SchoolclassService} from "../schoolclass/schoolclass.service";
import {tap, finalize} from "rxjs/operators";
import {FilterService} from "../filter/filter.service";
import {Schoolclass} from "../schoolclass/providers/schoolclass-provider";
import {AppstateService} from "../appstate.service";
import {NotifyService} from "../notify.service";
import {ApiStudentProvider} from "./providers/api-student-provider";
import { ActiveFilters, ActiveFiltersService } from "../active-filters.service";
import { Schulweg } from "src/app/API/generated/clients";

@Injectable({
  providedIn: "root"
})
export class StudentDataService {

  private students: Array<Student> = [];
  private studentsObservable = new BehaviorSubject<Array<Student>>(this.students);
  private selectedGroup: number;
  private selectedFilters: ActiveFilters;

  constructor(
    private polygonService: PolygonService,
    private schoolclassService: SchoolclassService,
    private filterService: FilterService,
    private appStateService: AppstateService,
    private notifySerivce: NotifyService,
    private studentProvider: ApiStudentProvider,
    private activeFilterService: ActiveFiltersService
  ) {

    // load students when period & classes or filter changes
    combineLatest([this.schoolclassService.getSchoolclasses(), this.schoolclassService.getPlanSchoolclasses(), this.filterService.getSelectedGroup(), this.polygonService.getPolygons(), this.activeFilterService.getActiveFilters()]).subscribe(
      ([schoolclasses, planSchoolclasses, group, polygons, filters]) => {
        if (group) {
          if (group.id !== this.selectedGroup || filters !== this.selectedFilters) {
            this.selectedFilters = filters;
            this.selectedGroup = group.id;
            // load students from API
            this.appStateService.setMapLoading(true);
            this.studentProvider
              .getStudents(
                group.id,
                filters.foreignLanguage,
                filters.klasse,
                filters.planKlasse,
                filters.stufe,
                filters.gender,
                filters.schoolBuildingType)
              .pipe(finalize(() => this.appStateService.setMapLoading(false)))
              .subscribe(
                students => {
                  // add schoolclass to student
                  students.forEach(s => {
                    s.schoolClass = _.find(schoolclasses, sc => sc.id === s.schoolClassId);
                    s.schoolPlanClass = _.find(planSchoolclasses, sc => sc.id === s.schoolPlanClassId);
                  });

                  this.students = _.orderBy(students, [
                    s => (s.lastName || "").toLowerCase(),
                    s => (s.firstName || "").toLowerCase(),
                    s => s.birthdate.valueOf()
                  ]);
                  this.setStudentPerimeters(polygons);
                  this.notifyObservers();
                },
                error => {
                  // if the calls goes in Error, we unlock the view
                  this.activeFilterService.setLoadingDataFilters(false);
                  this.notifySerivce.showError("apiErrors.student.load");
                }
              );

          } else {
            // just update perimeter
            this.setStudentPerimeters(polygons);
            this.notifyObservers();
          }
        } else {
          this.selectedFilters = filters;
          this.selectedGroup = undefined;
          this.students = [];
          this.notifyObservers();
        }
      }
    );
  }

  private notifyObservers(): void {
    this.studentsObservable.next(this.students);
    // removes loading overlay as soon data arrives
    this.activeFilterService.setLoadingDataFilters(false);
  }

  getStudents(): Observable<Array<Student>> {
    return this.studentsObservable.asObservable();
  }

  geSchoolroute(studentId: number, perimeterId: string): Observable<Array<Schulweg>> {
    return this.studentProvider.getSchoolroute(studentId, perimeterId);
  }

  setClass(schoolclass: Schoolclass, studentIds: Array<number>, isClassPlan: boolean): Observable<void> {
    return this.studentProvider.setSchoolclassAssignments(schoolclass, studentIds, isClassPlan).pipe(
      tap(() => {
        this.students.filter(s => studentIds.some(id => id === s.id)).forEach(s => {
            s.schoolPlanClass = schoolclass;
            s.schoolPlanClassId = schoolclass.id;
        });

        this.notifyObservers();
      })
    );
  }

  removePlanClass(studentId: number): Observable<void> {
    return this.studentProvider.removePlanSchoolclassAssignment(studentId).pipe(
      tap(() => {
        const student = _.find(this.students, s => s.id === studentId);
        student.schoolPlanClassId = undefined;
        this.notifyObservers();
      })
    );
  }

  setPerimeters(): Observable<void> {
    const newAssignments = this.students.map<StudentsAndPerimeter>(s => {
      return {
        studentId: s.id,
        perimeterName: s.perimeter ? s.perimeter.name : undefined
      };
    });

    return this.studentProvider.setPerimeters(newAssignments);
  }

  private setStudentPerimeters(polygons: Array<Polygon>): void {
    this.students.forEach(s => {
      // s.x = 2646414.6;
      // s.y = 1249129.3;
      s.setSpatialPerimeter(this.getContainingPolygon(polygons, s));
      // Here we check if there are some gisExceptions on the Polygons array, that match a studentID in our students array
      // if so, we will add this Exception to the Student, if not it will stay undefined (the exception)
      const exceptionPerimeter = _.find(polygons, p => p.gisExceptions.some(e => e.studentId === s.id));
      if (exceptionPerimeter) {
        s.perimeterException = _.extend<GisExceptionWithPerimeter>(_.find(exceptionPerimeter.gisExceptions, e => e.studentId === s.id), {
          perimeter: exceptionPerimeter
        });
      } else {
        s.perimeterException = undefined;
      }
    });
    this.notifyObservers();
  }

  private getContainingPolygon(polygons: Array<Polygon>, student: Student): Polygon {
    return _.find(polygons, p => p.containsPointRouted(student.x, student.y));
  }
}
