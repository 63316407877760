import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChildren, QueryList, ViewChild} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog, MatMenuTrigger} from "@angular/material";

import {PmiSelectItem, PmiSelectItemContainer, PmiSelectComponent, PmiSelectItemGroup} from "ag.pmi.dev.client.ng6.select";

import {noop, fromEvent, BehaviorSubject, Observable, combineLatest} from "rxjs";
import {take, map, first, finalize} from "rxjs/operators";

import * as _ from "lodash";
import {Polygon} from "../../../services/geometry/providers/geometry-provider";
import {PolygonService} from "../../../services/geometry/polygon.service";
import {AppstateService} from "../../../services/appstate.service";
import {EditDialogComponent, EditDialogData} from "../../../defaultDialogs/edit-dialog/edit-dialog.component";
import {ConfirmDialogData, ConfirmDialogComponent} from "../../../defaultDialogs/confirm-dialog/confirm-dialog.component";
import {NotifyService} from "../../../services/notify.service";
import {StudentDataService} from "../../../services/student/student-data.service";
import {Student, Gender} from "../../../services/student/providers/student-provider";
import {Schoolclass} from "../../../services/schoolclass/providers/schoolclass-provider";
import {SchoolclassService} from "../../../services/schoolclass/schoolclass.service";
import {AuthService} from "src/app/services/auth/auth.service";

@Component({
  selector: "app-perimeter-list",
  templateUrl: "./perimeter-list.component.html",
  styleUrls: ["./perimeter-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PerimeterListComponent implements OnInit {
  constructor(
    private cd: ChangeDetectorRef,
    private polygonService: PolygonService,
    public dialog: MatDialog,
    public translate: TranslateService,
    private appstateService: AppstateService,
    private notifyService: NotifyService,
    private studentService: StudentDataService,
    private schoolclassService: SchoolclassService,
    private authService: AuthService
  ) {}

  loading: boolean;
  perimeters: Array<Perimeter>;
  hideButtons = false;
  schoolareaMode = false;
  classes: Array<Schoolclass>;
  recentlyUsedClasses: Array<Schoolclass> = [];
  emptyGroups: PmiSelectItemContainer<Schoolclass>;
  perimeterStudents: Array<Student> = [];

  private selectclassSubject = new BehaviorSubject<PmiSelectItemContainer<Schoolclass>>({
    loadingState: false,
    groups: []
  });
  private recentClassesSubject = new BehaviorSubject<Array<Schoolclass>>(this.recentlyUsedClasses);

  @ViewChildren(MatMenuTrigger)
  perimeterMenuTriggers: QueryList<MatMenuTrigger>;

  @ViewChild("assignClassDialog")
  assignClassDialog: PmiSelectComponent<Schoolclass>;

  ngOnInit(): void {
    this.loading = true;

    this.translate.onLangChange.subscribe(() => {
      this.setStudentCaptions(this.perimeters);
    });

    combineLatest([this.polygonService.getPolygons(), this.studentService.getStudents()])
      .pipe(
        map(([perimeters, students]) => {
          return perimeters.map<Perimeter>(p => {
            const studentsInThisPerimeter = (students || []).filter(s => s.perimeter && s.perimeter.id === p.id);

            const femaleCount = studentsInThisPerimeter.filter(s => s.gender === Gender.FEMALE).length;
            const maleCount = studentsInThisPerimeter.filter(s => s.gender === Gender.MALE).length;
            const unknownCount = studentsInThisPerimeter.filter(s => s.gender === Gender.UNKNOWN).length;

            return _.extend(p, {
              displayName: p.name + (p.underConstruction ? "*" : ""),
              busy: false,
              studentIds: studentsInThisPerimeter.map(s => s.id),
              studentCaption: "", // will be set in next map
              studentCount: [femaleCount, maleCount, unknownCount] as [number, number, number]
            });
          });
        })
      )
      .pipe(map(p => this.setStudentCaptions(p)))
      .subscribe(perimeters => {
        this.perimeters = perimeters;

        this.loading = false;
        this.cd.markForCheck();
      });

    this.appstateService.getDrawChangeObservable().subscribe(isDraw => {
      this.hideButtons = !this.canWrite || isDraw;
      this.cd.markForCheck();
    });

    this.appstateService.getAdminChangeObservable().subscribe(isAdminMode => {
      this.schoolareaMode = isAdminMode;
      this.hideButtons = !this.canWrite;
      this.cd.markForCheck();
    });

    combineLatest([this.schoolclassService.getPlanSchoolclasses(), this.getRecentClasses()]).subscribe(([classes, recent]) => {
      // filters out the recent classes from the main list, and puts them back when they get cut out as they become the 6th element in recentUsed
      this.classes = _.cloneDeep(classes).filter(c => {
        return !recent.some(r => r.id === c.id);
      });

      this.selectclassSubject.next({
        loadingState: false,
        groups: [recent, this.classes].reduce((groups, klassen, index) => {
          if (klassen.length) {
            groups.push({
              title: index === 0 ? this.translate.instant("bottomPanel.perimeter.recentClasses") : this.translate.instant("bottomPanel.perimeter.allClasses"),
              items: _.sortBy(klassen, "name").map<PmiSelectItem<Schoolclass>>(c => {
                return {
                  key: c.id.toString(),
                  value: c.name + " - " + c.teacher,
                  data: c
                };
              }),
              sort: index
            });
          }
          return groups;
        }, Array<PmiSelectItemGroup<Schoolclass>>())
      });
    });
  }

  get canWrite(): boolean {
    if (this.appstateService.isAdminState()) {
      return this.authService.schoolarea === "write";
    } else {
      return this.authService.planningvariant === "write";
    }
  }

  private setStudentCaptions(perimeters: Array<Perimeter>): Array<Perimeter> {
    perimeters.forEach(p => {
      const [femaleCount, maleCount, unknownCount] = p.studentCount;
      const totalStudents = _.sum(p.studentCount);

      const genderCount: Array<string> = [];
      if (femaleCount) genderCount.push(femaleCount.toString() + this.translate.instant("bottomPanel.perimeter.female_short"));
      if (maleCount) genderCount.push(maleCount.toString() + this.translate.instant("bottomPanel.perimeter.male_short"));
      if (unknownCount) genderCount.push(unknownCount.toString() + this.translate.instant("bottomPanel.perimeter.unknown_short"));

      let studentCaption: string;
      if (totalStudents) {
        studentCaption =
          totalStudents.toString() +
          " " +
          (totalStudents === 1 ? this.translate.instant("bottomPanel.perimeter.student") : this.translate.instant("bottomPanel.perimeter.students"));

        if (genderCount.length) {
          studentCaption += " (" + genderCount.join(", ") + ")";
        }
      } else {
        studentCaption = this.translate.instant("bottomPanel.perimeter.no_students");
      }
      p.studentCaption = studentCaption;
    });

    return perimeters;
  }

  get translatePrefix(): string {
    return this.appstateService.isAdminState() ? "schoolarea" : "perimeter";
  }

  addPerimeter(): void {
    const dialogData: EditDialogData = {
      dialogTitle: this.translate.instant(`bottomPanel.${this.translatePrefix}.add_dialog.title`),
      label: this.translate.instant(`bottomPanel.${this.translatePrefix}.add_dialog.label`),
      saveButtonLabel: this.translate.instant(`bottomPanel.${this.translatePrefix}.add_dialog.button`),
      value: "",
      invalidValues: this.perimeters.map(p => p.name)
    };

    const dialogRef = this.dialog.open(EditDialogComponent, {
      data: dialogData,
      width: "400px"
    });

    dialogRef.beforeClosed().subscribe(perimeterName => {
      if (perimeterName) {
        this.polygonService.createPolygon(perimeterName);
      }
    });
  }

  renamePerimeter(perimeter: Perimeter): void {
    const dialogData: EditDialogData = {
      dialogTitle: this.translate.instant(`bottomPanel.${this.translatePrefix}.rename_dialog.title`),
      label: this.translate.instant(`bottomPanel.${this.translatePrefix}.rename_dialog.label`),
      value: perimeter.name,
      invalidValues: this.perimeters.map(p => p.name)
    };

    const dialogRef = this.dialog.open(EditDialogComponent, {
      data: dialogData,
      width: "400px"
    });

    dialogRef.beforeClosed().subscribe(newPerimeterName => {
      if (newPerimeterName && newPerimeterName !== perimeter.name) {
        perimeter.busy = true;
        this.cd.markForCheck();
        this.polygonService.renamePolygon(perimeter.id, newPerimeterName).subscribe(
          () => noop,
          errorMsg => {
            this.notifyService.showInfo(errorMsg);
            perimeter.busy = false;
          }
        );
      } else {
        perimeter.busy = false;
      }
    });
  }

  deletePerimeter(perimeter: Perimeter): void {
    const dialogData: ConfirmDialogData = {
      dialogTitle: this.translate.instant(`bottomPanel.${this.translatePrefix}.delete_dialog.title`, {name: perimeter.name}),
      message: this.translate.instant(`bottomPanel.${this.translatePrefix}.delete_dialog.message`).split("||")
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      width: "400px"
    });

    dialogRef.beforeClosed().subscribe(confirmed => {
      perimeter.busy = true;
      this.cd.markForCheck();
      if (confirmed) {
        this.polygonService.deletePolygon(perimeter.id).subscribe(
          () => noop,
          errorMsg => {
            this.notifyService.showError(errorMsg);
            perimeter.busy = false;
          }
        );
      } else {
        perimeter.busy = false;
      }
    });
  }

  getAssignedStudentsToPerimeter(perimeter: Polygon): Student[] {
    this.studentService.getStudents().subscribe(students => {
      this.perimeterStudents = students.filter(s => {
        if (s.perimeterException && s.perimeterException.id) return s.perimeterException.id === perimeter.id;
        return false;
      });
    });
    return this.perimeterStudents;
  }

  getClasses(): Observable<PmiSelectItemContainer<Schoolclass>> {
    return this.selectclassSubject.asObservable();
  }

  getHoveredPerimeter(): Observable<Polygon> {
    return this.polygonService.getPolygonHoveredObservable();
  }

  selectClassChange(perimeter: Perimeter, newClass: PmiSelectItem<Schoolclass>): void {
    if (newClass) {
      // pushes the selected class in the recentUsed, after 5 elements, it cuts the last one, to keep the list always with 5 elements
      this.recentlyUsedClasses.unshift(_.find(this.classes, c => newClass.key === c.id.toString()));
      if (this.recentlyUsedClasses.length > 5) this.recentlyUsedClasses.pop();
      this.notifyRecentClassObserver();

      // changes the plan class for all the students in the perimeter with the selected one
      perimeter.busy = true;
      this.cd.markForCheck();

      this.studentService
        .setClass(newClass.data, perimeter.studentIds, true)
        .pipe(
          finalize(() => {
            perimeter.busy = false;
            this.cd.markForCheck();
          })
        )
        .subscribe(
          () => noop,
          error => {
            console.error(error);
            this.notifyService.showError("apiErrors.schoolclass.saveAssignments");
          }
        );
    }

    if (perimeter) setTimeout(() => (perimeter.selectedClassForSelect = undefined));
  }

  public getRecentClasses(): Observable<Array<Schoolclass>> {
    return this.recentClassesSubject.asObservable();
  }

  private notifyRecentClassObserver(): void {
    this.recentClassesSubject.next(this.recentlyUsedClasses);
  }

  trackPerimeter(index: number, perimeter: Perimeter): string {
    return perimeter.id;
  }

  perimeterMenuOpened(perimeterId: string): void {
    setTimeout(() => {
      fromEvent(document, "click")
        .pipe(take(1))
        .subscribe(() => {
          this.perimeterMenuTriggers.find(t => t.menuData === perimeterId).closeMenu();
        });
    });
  }

  setPolygonOnHover(polygon: Perimeter): void {
    this.polygonService.setPolygonOnHover(polygon);
  }
}

export interface Perimeter extends Polygon {
  displayName: string;
  busy: boolean;
  studentIds: Array<number>;
  studentCount: [number, number, number];
  studentCaption: string;
  selectedClassForSelect?: string; // Dummy for PMI-Select dialog
}
