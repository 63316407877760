import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from "@angular/core";
import {AppstateService} from "../../services/appstate.service";

@Component({
  selector: "app-schoolradius-picker",
  templateUrl: "./schoolradius-picker.component.html",
  styleUrls: ["./schoolradius-picker.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchoolradiusPickerComponent implements OnInit {
  constructor(private appStateService: AppstateService, private cb: ChangeDetectorRef) {}

  isDraw: boolean;

  ngOnInit(): void {
    this.appStateService.getDrawChangeObservable().subscribe(isDrawMode => {
      this.isDraw = isDrawMode;
      this.cb.markForCheck();
    });
  }
}
