import VectorSource from "ol/source/Vector";
import LineString from "ol/geom/LineString";
import {DijkstraAStar} from "./dijkstra-a-star";
import {Vertex} from "../services/geometry/providers/geometry-provider";
import * as _ from "lodash";
import {ObjectBackedMap} from "../tools/object-backed-map";
import { Polygon } from "../services/geometry/providers/geometry-provider";
import { ApiZusatzebeneGeometryProvider } from "../services/geometry/providers/api-zusatzebene-geometry-provider";
import { AppstateService } from "../services/appstate.service";
import { GeometryService } from "../services/geometry/geometry.service";
import { GeometrySnap } from "./geometry-snap";


/* eslint-disable no-use-before-define */
export class Edge {
  public constructor(public node: Node, public length: number, public isZusatz = false) {}
}
/* eslint-enable no-use-before-define */

export class Node {
  readonly key: string;
  readonly edges: Edge[] = [];

  public predecessor: Node;
  public cost: number;
  public costHeuristic: number;
  public connectedComponentIndex = -1;

  public static getKey(x: number, y: number): string {
    return String(x) + "_" + String(y);
  }

  constructor(public readonly x: number, public readonly y: number) {
    this.key = Node.getKey(x, y);
  }

  public addBidirectionalEdge(other: Node, isZusatz: boolean = false): void {
    const dist = this.dist(other);
    this.edges.push(new Edge(other, dist, isZusatz));
    other.edges.push(new Edge(this, dist, isZusatz));
  }

  public removeBidirectionalEdge(other: Node): void {
    _.remove(this.edges, edge => edge.node === other);
    _.remove(other.edges, edge => edge.node === this);
  }

  public dist(other: Node): number {
    return Math.sqrt(Math.pow(this.x - other.x, 2) + Math.pow(this.y - other.y, 2));
  }

  public removeZusatzEdges(): void {
    _.remove(this.edges, edge => edge.isZusatz);
  }
}

export class Routing {
  private static DIST_CUTOFF_FACTOR = 2.5;

  // Using a plain object is 2x to 2.5x faster than using immutable.Map
  // Index signature makes sure we can only store objects of the type Node
  private nodes = new ObjectBackedMap<Node>();

  private dijkstraAStar = new DijkstraAStar();

  private lastZusatzebeneNodes: Node[];
  private schulkreise: Polygon[];

  constructor(routingSource: VectorSource,
              apiZusatzebeneGeometryProvider: ApiZusatzebeneGeometryProvider,
              private appstateService: AppstateService,
              geometryService: GeometryService,
              geometrySnap: GeometrySnap
              ) {
    // construct graph for pathfinding
    this.createGraph(routingSource);

    // initially load zusatzebenen
    apiZusatzebeneGeometryProvider.getPolygons(geometrySnap).subscribe(schulkreise => {
      this.replaceZusatzebene(schulkreise);
    });

    geometryService.getPolygons(false).subscribe(polygons => {
      if (this.appstateService.isAdminState()) {
        this.schulkreise = polygons.filter(p => !p.underConstruction);
      }
    });

    appstateService.getAdminChangeObservable().subscribe(isAdmin => {
      if (isAdmin) {
        this.removeZusatzebene();
      } else {
        this.replaceZusatzebene(this.schulkreise);
      }
    });
  }

  public findPath(source: Vertex, dest: Vertex): [number, number][] {
    if (source.isOnRoutableGeometry && dest.isOnRoutableGeometry && source.routingSegment !== undefined && dest.routingSegment !== undefined) {
      // if source and dest are not in the same connected component, there is no path

      // create temporary new nodes
      const sourceNode = new Node(source.x, source.y);
      const sourceSeg = source.routingSegment;
      const sourceA = this.nodes.get(Node.getKey(source.routingSegment[0][0], source.routingSegment[0][1]));
      const sourceB = this.nodes.get(Node.getKey(source.routingSegment[1][0], source.routingSegment[1][1]));

      const destNode = new Node(dest.x, dest.y);
      const destA = this.nodes.get(Node.getKey(dest.routingSegment[0][0], dest.routingSegment[0][1]));
      const destB = this.nodes.get(Node.getKey(dest.routingSegment[1][0], dest.routingSegment[1][1]));

      if (sourceA === undefined || sourceB === undefined || destA === undefined || destB === undefined) {
        console.log("undefined occured!");
      }

      // make sure that source and dest are in the same connected component.
      // Otherwise, it is not possible to find a routed path
      if (sourceA.connectedComponentIndex !== destA.connectedComponentIndex) {
        return [];
      }

      // connect temporary node with graph
      sourceNode.addBidirectionalEdge(sourceA);
      sourceNode.addBidirectionalEdge(sourceB);
      destNode.addBidirectionalEdge(destA);
      destNode.addBidirectionalEdge(destB);

      // add direct path if source and dest are on same segment
      if (source.routingSegment === dest.routingSegment) {
        sourceNode.addBidirectionalEdge(destNode);
      }

      // find path from source to dest
      const cutoffDistance = sourceNode.dist(destNode) * Routing.DIST_CUTOFF_FACTOR;
      const path = this.dijkstraAStar.findPath(sourceNode, destNode, cutoffDistance);

      // remove edges
      sourceA.removeBidirectionalEdge(sourceNode);
      sourceB.removeBidirectionalEdge(sourceNode);
      destA.removeBidirectionalEdge(destNode);
      destB.removeBidirectionalEdge(destNode);

      // transform to coordinates
      const coordinates = path.map(node => [node.x, node.y]) as [number, number][];
      return coordinates;
    } else {
      throw Error("Both the source and the destination vetors must be on routable geometry and must have the isOnRoutableGeometry flag set");
    }
  }

  private createGraph(routingSource: VectorSource): void {
    // wait for features to load source to be ready
    routingSource.once("change", () => {
      if (routingSource.getState() === "ready") {
        const start = window.performance.now();
        this.addSourceToGraph(routingSource);
        const end = window.performance.now();
        console.log("Time to construct routing graph for " + routingSource.getUrl() + " in ms: " + (end - start));
        this.markConnectedComponents();
      } else {
        throw Error("Vector source not ready - Should not happen!");
      }
    });
  }

  /**
   * Add all shapes of a source to the routing graph.
   * Important must be ready (.getState() === "ready")
   */
  private addSourceToGraph(routingSource: VectorSource): void {
    if (routingSource.getState() !== "ready") {
      throw Error("Routing sourcemust be ready!");
    }

    // loop over all features of the source
    routingSource.getFeatures().forEach(feature => {
      const geometry = feature.getGeometry();
      if (geometry instanceof LineString) {
        const coordinates = geometry.getCoordinates();
        for (let i = 0; i < coordinates.length - 1; i++) {
          const node1 = this.findOrCreateNode(coordinates[i][0], coordinates[i][1]);
          const node2 = this.findOrCreateNode(coordinates[i + 1][0], coordinates[i + 1][1]);
          node1.addBidirectionalEdge(node2, false);
        }
      }
    });
  }

  private replaceZusatzebene(schulkreise: Polygon[]): void {
    this.removeZusatzebene();
    this.addZusatzebeneToGraph(schulkreise);
    this.markConnectedComponents();
  }

  private addZusatzebeneToGraph(polygons: Polygon[]): void {
    this.lastZusatzebeneNodes = [];
    if (polygons) {
      polygons.forEach(polygon => {
        const coordinates = polygon.getOpenlayersRoutedCoordinates();
        for (let i = 0; i < coordinates.length - 1; i++) {
          const node1 = this.findOrCreateNode(coordinates[i][0], coordinates[i][1]);
          const node2 = this.findOrCreateNode(coordinates[i + 1][0], coordinates[i + 1][1]);
          node1.addBidirectionalEdge(node2, true);
          this.lastZusatzebeneNodes.push(node1);
        }
      });
    }
  }

  private removeZusatzebene(): void {
    if (this.lastZusatzebeneNodes) {
      this.lastZusatzebeneNodes.forEach(node => {
        node.removeZusatzEdges();
        if (node.edges.length === 0) this.nodes.remove(node.key);
      });
    }
  }

  private findOrCreateNode(x: number, y: number): Node {
    const key = Node.getKey(x, y);
    if (this.nodes.contains(key)) {
      return this.nodes.get(key);
    } else {
      const node = new Node(x, y);
      this.nodes.put(key, node);
      return node;
    }
  }

  private findNode(x: number, y: number): Node {
    const key = Node.getKey(x, y);
    if (this.nodes.contains(key)) {
      return this.nodes.get(key);
    }
    return undefined;
  }

  private markConnectedComponents(): void {
    const startConnected = window.performance.now();
    let connectedComponentIndex = 0;
    for (const node of this.nodes.toArray()) {
      if (node.connectedComponentIndex < 0) {
        this.dfsConnectedComponent(node, connectedComponentIndex);
        connectedComponentIndex++;
      }
    }
    const endConnected = window.performance.now();
    console.log("Time to calculate connected components: " + (endConnected - startConnected) + " ms");
  }

  // marks all nodes of the same connected component with the connectedComponentIndex
  private dfsConnectedComponent(origin: Node, connectedComponentIndex: number): void {
    const stack: Node[] = [];
    stack.push(origin);

    while (stack.length > 0) {
      const node = stack.pop();
      if (node.connectedComponentIndex < 0) {
        node.connectedComponentIndex = connectedComponentIndex;
        node.edges.forEach(edge => {
          if (edge.node.connectedComponentIndex < 0) {
            stack.push(edge.node);
          }
        });
      }
    }
  }
}
