export class LodHelper {

  public static readonly MIN_LOD = 0;
  public static readonly MAX_LOD = 9;

  static getLod(resolution: number): number {
    const lod = Math.round(Math.log2(resolution * 8));
    return Math.min(Math.max(lod, LodHelper.MIN_LOD), LodHelper.MAX_LOD);
  }
}
