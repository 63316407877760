import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Polygon, Vertex} from "./geometry-provider";
import {
  AusnahmenGeneratedClient,
  SchulkreisGeneratedClient,
  ZusatzebeneGeneratedClient,
  Schoolareas as APISchoolareas,
  Zusatzebene as APIZusatzebene,
  ZusatzebeneV as APIZusatzebeneV,
  ZusatzebenePV as APIZusatzebenePV,
  KarteGeneratedClient,
} from "src/app/API/generated/clients";

import * as Color from "color";
import * as _ from "lodash";
import {map} from "rxjs/operators";

import {Planningvariant} from "../../planningvariant/providers/planningvariant-provider";
import {GeometrySnap} from "../../../routing/geometry-snap";
import {AuthService} from "../../auth/auth.service";
import { ApiBaseGeometryProvider } from "./api-base-geometry-provider";

@Injectable({
  providedIn: "root"
})
export class ApiZusatzebeneGeometryProvider extends ApiBaseGeometryProvider {

  constructor(
    private schoolareaApiClient: SchulkreisGeneratedClient,
    private layerApiClient: ZusatzebeneGeneratedClient,
    gisExceptionApiClient: AusnahmenGeneratedClient,
    mapApiClient: KarteGeneratedClient,
    authService: AuthService,
  ) {
    super(gisExceptionApiClient, mapApiClient, authService);
  }

  getPolygons(geometrySnap: GeometrySnap, planningvariantId?: string): Observable<Polygon[]> {
    return this.schoolareaApiClient.schulkreis_Get(this.authService.realmId).pipe(
      map(schoolareas => {
        const vertices = schoolareas.zusatzebeneVertices.map<Vertex>(v => {
          const vertex = new Vertex(v.x, v.y, v.aufRoutingGeometrie, undefined, v.manuell, v.id);
          if (vertex.isOnRoutableGeometry) {
            vertex.routingSegment = geometrySnap.snapTo(vertex.x, vertex.y, ApiBaseGeometryProvider.SNAP_TOLERANCE).segment;
          } else {
            vertex.isOnRoutableGeometry = false;
          }
          return vertex;
        });

        return schoolareas.zusatzebenen.map<Polygon>(
          p =>
            new Polygon(
              p.name,
              Color(this.colorPalette.getColorByIndex(0)),
              p.zusatzebenePVs.map(pv => _.find(vertices, v => v.id === pv.zusatzebeneVId)),
              false,
              p.id
            )
        );
      })
    );
  }

  renamePolygon(id: string, newName: string): Observable<void> {
    return this.layerApiClient.zusatzebene_Rename(id, newName, this.authService.realmId);
  }

  deletePolygon(id: string): Observable<void> {
    return this.layerApiClient.zusatzebene_Delete(id, this.authService.realmId);
  }

  savePolygons(polygons: Polygon[], planningvariant?: Planningvariant): Observable<void> {
    const schoolareas: APISchoolareas = {
      zusatzebenen: polygons.map<APIZusatzebene>(p => {
        return {
          id: p.id,
          name: p.name,
          zusatzebenePVs: p.getAllVertices().map<APIZusatzebenePV>(v => {
            return {
              zusatzebeneId: p.id,
              zusatzebeneVId: v.id
            };
          })
        };
      }),
      zusatzebeneVertices: _.uniqBy(_.flatMap(polygons, p => p.getAllVertices()), v => v.id).map<APIZusatzebeneV>(v => {
        return {
          id: v.id,
          x: v.x,
          y: v.y,
          aufRoutingGeometrie: v.isOnRoutableGeometry,
          manuell: v.isHumanCreated
        };
      })
    };

    return this.schoolareaApiClient.schulkreis_Update(schoolareas, this.authService.realmId);
  }
}
