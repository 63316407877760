import {Component, OnInit, ElementRef, ViewChild, Input, NgZone, ChangeDetectionStrategy, ChangeDetectorRef} from "@angular/core";

import Map from "ol/Map";
import View from "ol/View";
import VectorLayer from "ol/layer/Vector";
import Feature from "ol/Feature";
import OlPolygon from "ol/geom/Polygon";

// tmp imports for drawing layer styling
import VectorSource from "ol/source/Vector";
import Style from "ol/style/Style";
import FillStyle from "ol/style/Fill";
import StrokeStyle from "ol/style/Stroke";
import DoubleClickZoomInteraction from "ol/interaction/DoubleClickZoom";
import DragPanInteraction from "ol/interaction/DragPan";
import ScaleLineControl from "ol/control/ScaleLine";
import {GeometryService} from "../services/geometry/geometry.service";

// import sync from "ol-hashed";
import {ShapefileLoader} from "./base-map/shapefile-loader";
import {PolygonRenderer} from "./polygon-renderer";
import {PolygonCreationTool} from "./tools/polygon-creation-tool";
import {MapBrowserEvent, VectorTile} from "openlayers";
import {PolygonService} from "../services/geometry/polygon.service";

import * as _ from "lodash";
import {AppstateService} from "../services/appstate.service";
import {MousePositionService} from "../services/mouse-position.service";
import {NotifyService} from "../services/notify.service";
import {Routing} from "../routing/routing";
import {GeometrySnap} from "../routing/geometry-snap";
import {PolygonEditTool} from "./tools/polygon-edit-tool";
import {VertexTooltipService} from "../services/vertex-tooltip.service";
import {DrawingLayer, Painter} from "./drawing-layer";
import {BuildingRenderer} from "./location/building-renderer";
import {BuildingDataService} from "../services/building/building-data.service";
import {AppearanceService} from "../services/appearance.service";
import {StudentRenderer} from "./location/student-renderer";
import {StudentDataService} from "../services/student/student-data.service";
import {StudentInteraction} from "./location/student-interaction";
import {StudentMapService} from "../services/student/student-map.service";
import {BuildingMapService} from "../services/building/building-map.service";
import { SchulkreisRenderer } from "./schulkreis-renderer";
import { ApiZusatzebeneGeometryProvider } from "../services/geometry/providers/api-zusatzebene-geometry-provider";
import { Polygon } from "../services/geometry/providers/geometry-provider";

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent implements OnInit {
  map: Map;
  view: View;
  loading = true;

  @ViewChild("map")
  mapElement: ElementRef;
  @Input()
  bottomGap: boolean;

  constructor(
    private geometryService: GeometryService,
    private polygonService: PolygonService,
    private appstateService: AppstateService,
    private mousePositionService: MousePositionService,
    private notifyService: NotifyService,
    private vertexTooltipService: VertexTooltipService,
    private buildingDataService: BuildingDataService,
    private studentDataService: StudentDataService,
    private appearanceService: AppearanceService,
    private studentMapServie: StudentMapService,
    private buildingMapService: BuildingMapService,
    private zone: NgZone,
    private cd: ChangeDetectorRef,
    private shapefileLoader: ShapefileLoader,
    private apiZusatzebeneGeometryProvider: ApiZusatzebeneGeometryProvider
  ) {}

  ngOnInit(): void {
    this.appstateService.getIsMapLoadingObservable().subscribe(isLoading => {
      this.zone.run(() => {
        this.loading = isLoading;
        this.cd.markForCheck();
      });
    });

    this.geometryService.getMapBoundaries().subscribe(
      mapBoundaries => {
        this.zone.runOutsideAngular(() => {
          const middleX = (mapBoundaries[0] + mapBoundaries[2]) / 2;
          const middleY = (mapBoundaries[1] + mapBoundaries[3]) / 2;

          // create map and view
          this.view = new View({
            center: [middleX, middleY],
            // zoom: 15,
            maxZoom: 23,
            minZoom: 13
          });
          this.map = new Map({
            target: "map",
            view: this.view,
            controls: [new ScaleLineControl()]
          });
          // I have no idea why we need to adjust the extents!!!
          const deltaX = mapBoundaries[2] - mapBoundaries[0];
          const deltaY = mapBoundaries[3] - mapBoundaries[1];
          this.view.fit([mapBoundaries[0] + deltaX / 4, mapBoundaries[1] + deltaY / 4, mapBoundaries[2] - deltaX / 4, mapBoundaries[3] - deltaY / 4]);

          // load layers
          const layers: VectorLayer[] = [];
          layers.push(this.shapefileLoader.loadMap());

          // add layers to map
          layers.forEach((layer: VectorLayer) => {
            this.map.addLayer(layer);
          });

          // setup drawing tool
          const zoomInteraction = _.find(this.map.getInteractions().getArray(), interaction => interaction instanceof DoubleClickZoomInteraction);
          const offsetFromTop = this.mapElement.nativeElement.getBoundingClientRect().top;
          const polygonCreationTool = new PolygonCreationTool(
            this.geometryService,
            zoomInteraction,
            this.mousePositionService,
            this.notifyService,
            this.appstateService,
            this.map,
            offsetFromTop
          );
          this.map.on("click", (evt: MapBrowserEvent) => polygonCreationTool.onClick(evt));
          this.map.on("pointermove", (evt: MapBrowserEvent) => polygonCreationTool.onPointerMove(evt));
          this.map.on("dblclick", (evt: MapBrowserEvent) => polygonCreationTool.onDblClick(evt));

          // setup geometryservice
          this.geometryService.map = this.map;
          this.geometryService.polygonCreationTool = polygonCreationTool;
          const geometrySnap = new GeometrySnap(layers[0].getSource(), this.apiZusatzebeneGeometryProvider, this.appstateService, this.geometryService);
          this.geometryService.geometrySnap = geometrySnap;
          this.geometryService.routing = new Routing(layers[0].getSource(),
                                                     this.apiZusatzebeneGeometryProvider,
                                                     this.appstateService,
                                                     this.geometryService,
                                                     geometrySnap);

          // setup edit tool
          const dragPanInteraction = _.find(this.map.getInteractions().getArray(), interaction => interaction instanceof DragPanInteraction);
          const polygonEditTool = new PolygonEditTool(this.geometryService, this.vertexTooltipService, this.appstateService, dragPanInteraction, this.map);
          this.map.on("click", (evt: MapBrowserEvent) => polygonEditTool.onClick(evt));
          this.map.on("pointermove", (evt: MapBrowserEvent) => polygonEditTool.onPointerMove(evt));
          this.map.on("pointerdrag", (evt: MapBrowserEvent) => polygonEditTool.onPointerDrag(evt));
          this.map.on("pointerdown", (evt: MapBrowserEvent) => polygonEditTool.onPointerDown(evt));
          this.map.on("pointerup", (evt: MapBrowserEvent) => polygonEditTool.onPointerUp(evt));

          // dragging cursor
          this.map.on("movestart", evt => {
            this.studentMapServie.setClicked(undefined); // close click panel when we start dragging
            const viewport = this.map.getViewport();
            if (viewport instanceof HTMLElement) {
              viewport.style.cursor = "move";
              polygonEditTool.pause();
            }
          });
          this.map.on("moveend", evt => {
            const viewport = this.map.getViewport();
            if (viewport instanceof HTMLElement) {
              viewport.style.cursor = "default";
              polygonEditTool.resume();
            }
          });

          // setup renderers
          const schulkreisRenderer = new SchulkreisRenderer(this.apiZusatzebeneGeometryProvider,
                                                            geometrySnap,
                                                            this.appstateService,
                                                            this.map,
                                                            this.geometryService);
          const polygonRenderer = new PolygonRenderer(this.polygonService, this.appstateService);
          const buildingRenderer = new BuildingRenderer(this.map, this.buildingDataService, this.appstateService, this.appearanceService);
          const studentRenderer = new StudentRenderer(this.map, this.studentDataService, this.appstateService, this.appearanceService, this.studentMapServie);

          // setup polygon drawing layer
          const polygonPainters: Painter[] = [schulkreisRenderer, polygonRenderer, polygonCreationTool, polygonEditTool];
          const polygonDrawingLayer = new DrawingLayer(this.map, this.appstateService, polygonPainters, "polygon", "vector");
          this.map.addLayer(polygonDrawingLayer.getLayer());

          // setup location drawing layer
          const locatonPainters: Painter[] = [buildingRenderer, studentRenderer];
          const locationDrawingLayer = new DrawingLayer(this.map, this.appstateService, locatonPainters, "location", "image");
          this.map.addLayer(locationDrawingLayer.getLayer());

          // setup hover trigger
          const studentInteraction = new StudentInteraction(
            this.map,
            this.studentDataService,
            this.buildingDataService,
            this.studentMapServie,
            this.buildingMapService,
            this.appstateService
          );
          this.map.on("pointermove", (evt: MapBrowserEvent) => studentInteraction.onPointerMove(evt));
          this.map.on("click", (evt: MapBrowserEvent) => studentInteraction.onClick(evt));

          // save map location between browser reloads
          // sync(this.map);
        });
      },
      err => {
        this.notifyService.showError("apiErrors.map.loadBoundaries");
      }
    );
  }

  zoom(modifier: number): void {
    const view = this.map.getView();
    const newResolution = view.constrainResolution(view.getResolution(), modifier);
    if (view.getAnimating()) {
      view.cancelAnimations();
    }
    view.animate({
      resolution: newResolution,
      duration: 250,
      easing: t => 1 - Math.pow(1 - t, 3)
    });
  }
}
