import {Injectable} from "@angular/core";
import {Observable, BehaviorSubject, of} from "rxjs";
import {Building} from "./providers/building-provider";

@Injectable({
  providedIn: "root"
})
export class BuildingMapService {
  hoverSubject: BehaviorSubject<Building> = new BehaviorSubject(undefined);
  clickSubject: BehaviorSubject<Building> = new BehaviorSubject(undefined);

  constructor() {}

  getHoverObservable(): Observable<Building> {
    return this.hoverSubject.asObservable();
  }

  setHovered(building: Building): void {
    this.hoverSubject.next(building);
  }

  getClickObservable(): Observable<Building> {
    return this.clickSubject.asObservable();
  }

  setClicked(building: Building): void {
    this.clickSubject.next(building);
  }
}
