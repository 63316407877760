import {BrowserModule} from "@angular/platform-browser";
import {NgModule} from "@angular/core";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from "@angular/common/http";

import {OAuthModule} from "angular-oauth2-oidc";
import {TokenInterceptor} from "./services/auth/tokenInterceptor";

import {ApiBaseUrlToken} from "./API/generated/clients";
import {APIBaseURL} from "./API/baseURLs";
import {ApiMapBaseUrlToken} from "./map/base-map/shapefile-loader";
import {APIMapBaseURL} from "./API/baseURLs";

import {TranslateModule, TranslateLoader} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";

import {MomentModule} from "ngx-moment";

import {AppComponent} from "./app.component";
import {PortalbarComponent} from "./portalbar/portalbar.component";
import {MapComponent} from "./map/map.component";
import {NavbarComponent} from "./navbar/navbar.component";
import {MenuComponent} from "./panels/menu/menu.component";

import {MaterialCustomConfigModule} from "./material-config/material-config.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {PmiSelectModule} from "ag.pmi.dev.client.ng6.select";
import {PlanningvariantPickerComponent} from "./navbar/planningvariant-picker/planningvariant-picker.component";
import {BottomPanelComponent} from "./panels/bottom-panel/bottom-panel.component";
import {PerimeterListComponent} from "./panels/bottom-panel/perimeter-list/perimeter-list.component";
import {InfoPanelComponent} from "./panels/info-panel/info-panel.component";
import {BuildingPanelComponent} from "./panels/info-panel/building-panel/building-panel.component";
import {StudentPanelComponent} from "./panels/info-panel/student-panel/student-panel.component";
import {EditDialogComponent} from "./defaultDialogs/edit-dialog/edit-dialog.component";
import {ConfirmDialogComponent} from "./defaultDialogs/confirm-dialog/confirm-dialog.component";
import {OnclickPanelComponent} from "./panels/info-panel/onclick-panel/onclick-panel.component";
import {OnclickStudentPanelComponent} from "./panels/info-panel/onclick-panel/onclick-student-panel/onclick-student-panel.component";
import {OnclickBuildingPanelComponent} from "./panels/info-panel/onclick-panel/onclick-building-panel/onclick-building-panel.component";
import {VertexTooltipComponent} from "./panels/vertex-tooltip/vertex-tooltip.component";
import {ForecastComponent} from "./panels/bottom-panel/forecast/forecast.component";
import {StudentsListComponent} from "./panels/bottom-panel/students-list/students-list.component";
import {MatBadgeModule, ErrorStateMatcher, ShowOnDirtyErrorStateMatcher} from "@angular/material";
import {VisibilityMenuComponent} from "./panels/menu/visibility-menu/visibility-menu.component";
import {FiltersMenuComponent} from "./panels/menu/filters-menu/filters-menu.component";
import {SchoolradiusPickerComponent} from "./navbar/schoolradius-picker/schoolradius-picker.component";
import {SchoolradiusMenuComponent} from "./panels/schoolradius-menu/schoolradius-menu.component";
import {NotificationComponent} from "./notification/notification.component";
import {ChangeCoordinatesDialogComponent} from "./panels/menu/change-coordinates-dialog/change-coordinates-dialog.component";
import { ComboboxDialogComponent } from "./defaultDialogs/combobox-dialog/combobox-dialog.component";

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    PortalbarComponent,
    NavbarComponent,
    MapComponent,
    MenuComponent,
    PlanningvariantPickerComponent,
    BottomPanelComponent,
    PerimeterListComponent,
    InfoPanelComponent,
    BuildingPanelComponent,
    StudentPanelComponent,
    EditDialogComponent,
    ConfirmDialogComponent,
    ComboboxDialogComponent,
    OnclickPanelComponent,
    OnclickStudentPanelComponent,
    OnclickBuildingPanelComponent,
    VertexTooltipComponent,
    ForecastComponent,
    StudentsListComponent,
    VisibilityMenuComponent,
    FiltersMenuComponent,
    SchoolradiusPickerComponent,
    SchoolradiusMenuComponent,
    NotificationComponent,
    ChangeCoordinatesDialogComponent
  ],
entryComponents: [EditDialogComponent, ComboboxDialogComponent, ConfirmDialogComponent, NotificationComponent, ChangeCoordinatesDialogComponent/*, DatabaseDialogComponent*/],
  imports: [
    BrowserModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    MatBadgeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MaterialCustomConfigModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    PmiSelectModule
  ],
  providers: [
    {provide: ApiBaseUrlToken, useValue: APIBaseURL},
    {provide: ApiMapBaseUrlToken, useValue: APIMapBaseURL},
    {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
