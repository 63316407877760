import { Student } from "../../services/student/providers/student-provider";
import { BuildingDataService } from "../../services/building/building-data.service";
import { Building } from "../../services/building/providers/building-provider";
import MapBrowserEvent from "ol/MapBrowserEvent";
import Map from "ol/Map";
import Feature from "ol/Feature";
import { StudentMapService } from "../../services/student/student-map.service";
import * as _ from "lodash";
import { BuildingMapService } from "../../services/building/building-map.service";
import { StudentDataService } from "../../services/student/student-data.service";
import { AppstateService } from "../../services/appstate.service";

export class StudentInteraction {

  private static readonly HOVER_TOLERANCE_PIXELS = 5;

  private students: Student[];
  private buildings: Building[];

  constructor(private map: Map,
              studentDataService: StudentDataService,
              buildingDataService: BuildingDataService,
              private studentMapService: StudentMapService,
              private buildingMapService: BuildingMapService,
              private appStateService: AppstateService) {
    studentDataService.getStudents().subscribe(students => this.students = students);
    buildingDataService.getBuildings().subscribe(buildings => this.buildings = buildings);
  }

  public onPointerMove(evt: MapBrowserEvent): void {
    const applied = this.applyFuncToClosestFeature(evt, b => this.buildingMapService.setHovered(b), s => this.studentMapService.setMapHovered(s));
    if (!this.appStateService.isDrawState()) {
      this.setCursor(applied);
    }
  }

  public onClick(evt: MapBrowserEvent): void {
    if (!this.appStateService.isDrawState()) {
      const applied = this.applyFuncToClosestFeature(evt, b => this.buildingMapService.setClicked(b), s => this.studentMapService.setClicked(s));
      if (!applied) {
        this.studentMapService.setClicked(undefined);
      }
    }
  }

  private applyFuncToClosestFeature(evt: MapBrowserEvent, f1: (Building) => void, f2: (Student) => void): boolean {
    const minFeature = this.getBestFeatureAtEventCoordinates(evt);

    // notify service
    if (minFeature) {
      const id = minFeature.get("id");
      if (!this.tryFindBuildingAndApplyFunc(id, f1)) {
        if (!this.tryFindStudentAndApplyFunc(id, f2)) {
          f2(undefined);
        }
      }
      return true;
    }
    return false;
  }

  private getBestFeatureAtEventCoordinates(evt: MapBrowserEvent): Feature {
    const features = this.map.getFeaturesAtPixel(evt.pixel, {layerFilter: layer => layer.get("name") === "location"});
    // return if no features at current pixel
    if (features === null || features.length === 0) {
      this.studentMapService.setMapHovered(undefined);
      return undefined;
    }

    const filteredFeatures = <Feature[]>features.filter(f => f instanceof Feature);
    const minFeature = _.maxBy(filteredFeatures, feature => {
      const zIndex = parseInt(feature.get("zIndex"), 10);
      return zIndex;
    });
    return minFeature;
  }

  private tryFindBuildingAndApplyFunc(id: number, func: (Building) => void): boolean {
    if (this.buildings !== undefined) {
      const building = _.find(this.buildings, b => b.id === id);
      if (building !== undefined) {
        func(building);
        return true;
      }
    }
    return false;
  }

  private tryFindStudentAndApplyFunc(id: number, func: (Student) => void): boolean {
    if (this.students !== undefined) {
      const student = _.find(this.students, s => s.id === id);
      if (student !== undefined) {
        func(student);
        return true;
      }
    }
    return false;
  }

  private setCursor(isInteractive: boolean): void {
    const viewport = this.map.getViewport();
    if (viewport instanceof HTMLElement) {
      if (isInteractive) {
        viewport.style.cursor = "pointer";
      } else if (viewport.style.cursor === "pointer") {
        viewport.style.cursor = "default";
      }
    }
  }
}
