import * as Color from "color";
import * as _ from "lodash";

export abstract class ColorPalette {

  nextColor(lastColor?: Color): Color {
    const colors = this.getColors();
    if (lastColor !== undefined) {
      const lastIndex = _.findIndex(colors, c => c.hex() === lastColor.hex());

      // if color was not found return the first color
      if (lastIndex < 0) return colors[0];

      const index = this.toRange(lastIndex + 1);
      return colors[index];

    }
    return colors[0];
  }

  /**
   * For use tests / dummy factory
   * @param index
   */
  getColorByIndex(index: number): Color {
    const colors = this.getColors();
    index = this.toRange(index);
    return colors[index];
  }

  /**
   * Make sure that the index is in the range of colors by applying the modulo operator
   * @param index
   */
  protected toRange(index: number): number {
    const colors = this.getColors();
    index %= colors.length;
    if (index < 0) index += colors.length;
    return index;
  }

  protected abstract getColors(): Color[];

}

/**
 * Colors from http://colorbrewer2.org/#type=qualitative&scheme=Set1&n=9
 */
export class ColorBrewerColorPalette extends ColorPalette {

  private colors = [
    Color("#e41a1c"),
    Color("#377eb8"),
    Color("#4daf4a"),
    Color("#984ea3"),
    Color("#ff7f00"),
    Color("#ffff33"),
    Color("#a65628"),
    Color("#f781bf"),
    Color("#999999")
  ];

  protected getColors(): Color[] {
    return this.colors;
  }
}

/**
 * Colors from old GIS
 */
export class Windows16BitColorPalette extends ColorPalette {

  private readonly colors = [
    Color.rgb(0, 0, 255),
    Color.rgb(0, 127, 0),
    Color.rgb(255, 0, 0),
    Color.rgb(255, 255, 0),
    Color.rgb(0, 255, 0),
    Color.rgb(0, 0, 0),
    Color.rgb(0, 127, 127),
    Color.rgb(255, 0, 255),
    Color.rgb(0, 0, 127),
    Color.rgb(255, 127, 0),
    Color.rgb(127, 0, 255),
    Color.rgb(0, 255, 255),
    Color.rgb(127, 127, 0),
    Color.rgb(127, 127, 127),
    Color.rgb(127, 0, 255)
  ];

  protected getColors(): Color[] {
    return this.colors;
  }
}

export abstract class ColorPaletteFactory {

  private static palette: ColorPalette;

  public static getDefault(): ColorPalette {
    if (ColorPaletteFactory.palette === undefined) {
      ColorPaletteFactory.palette = new ColorBrewerColorPalette();
    }
    return ColorPaletteFactory.palette;
  }
}
