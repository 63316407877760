import {Injectable} from "@angular/core";
import {MatSnackBar} from "@angular/material";
import {TranslateService} from "@ngx-translate/core";
import {NotificationComponent, Notification, NotificationType} from "../notification/notification.component";

@Injectable({
  providedIn: "root"
})
export class NotifyService {
  constructor(private snackBarService: MatSnackBar, private translate: TranslateService) {}

  showInfo(translationKey: string, interpolateParams?: Object, duration?: number): void {
    this.showNotification(translationKey, NotificationType.INFO, interpolateParams, duration);
  }

  showSuccess(translationKey: string, interpolateParams?: Object, duration?: number): void {
    this.showNotification(translationKey, NotificationType.SUCCESS, interpolateParams, duration);
  }

  showWarning(translationKey: string, interpolateParams?: Object, duration?: number): void {
    this.showNotification(translationKey, NotificationType.WARNING, interpolateParams, duration);
  }

  showError(translationKey: string, interpolateParams?: Object, duration?: number): void {
    this.showNotification(translationKey, NotificationType.ERROR, interpolateParams, duration);
  }

  private showNotification(translationKey: string, type: NotificationType, interpolateParams?: Object, duration?: number): void {
    const notification: Notification = {
      message: this.translate.instant(translationKey, interpolateParams),
      type: type
    };

    this.snackBarService.openFromComponent(NotificationComponent, {data: notification, duration: duration || 5000});
  }
}
