import MapBrowserEvent from "ol/MapBrowserEvent";
import { GeometryService } from "../../services/geometry/geometry.service";
import { VertexTooltipService } from "../../services/vertex-tooltip.service";
import { AppstateService, AppState } from "../../services/appstate.service";
import DragPanInteraction from "ol/interaction/DragPan";
import Map from "ol/Map";
import Feature from "ol/Feature";
import Style from "ol/style/Style";
import FillStyle from "ol/style/Fill";
import StrokeStyle from "ol/style/Stroke";
import RegularShape from "ol/style/RegularShape";
import Point from "ol/geom/Point";

import {Vertex} from "./../../services/geometry/providers/geometry-provider";
import { VertexMetadata } from "../../services/geometry/vertex-metadata";
import { Painter, DrawingLayer } from "../drawing-layer";
export class PolygonEditTool implements Painter {

  private isActive = false;
  private isPaused = false;
  private draggingVertex: Vertex;
  private draggingDownLoaction: [number, number];
  private drawingLayer: DrawingLayer;
  private newVertexOnLine: Vertex;

  /**
   * hack: disable click when a new vertex was created on the down event.
   * This prevents the map from showning a context menu on the newly created vertex
   */
  private preventClick = false;

  private readonly VERTEX_OPACITY = 0.6;
  private readonly pointStyle = new Style({
    image: new RegularShape({
      fill: new FillStyle({color: [255, 255, 255, this.VERTEX_OPACITY]}),
      stroke: new StrokeStyle({color: [0, 0, 0, this.VERTEX_OPACITY], width: 1.5}),
      points: 4,
      radius: 6,
      angle: Math.PI / 4
    })
  });

  constructor(
    private geometryService: GeometryService,
    private vertexTooltipService: VertexTooltipService,
    private appStateService: AppstateService,
    private dragPanInteraction: DragPanInteraction,
    private map: Map ) {
      this.appStateService.getStateObservable().subscribe(state => {
        this.isActive = AppstateService.isEditStateStatic(state);
      });
    }

  public onClick(evt: MapBrowserEvent): void {
    // check if click was on vertex
    if (this.isEnabled() && !this.preventClick) {
      const vertexMeta = this.geometryService.getExistingVertex(evt.coordinate[0], evt.coordinate[1]);
      if (vertexMeta) {
        this.vertexTooltipService.showTooltip(vertexMeta.vertex);
      }
    }
    this.preventClick = false;
  }

  public onPointerDown(evt: MapBrowserEvent): void {
    if (this.isEnabled()) {
      const x = evt.coordinate[0];
      const y = evt.coordinate[1];

      // get existing vertex
      let vertexMeta = this.geometryService.getExistingVertex(x, y);

      // check if we are on a line between vertices
      if (!vertexMeta) {
        vertexMeta = this.geometryService.snapToPolygonLine(x, y);
        if (vertexMeta && vertexMeta.isOnLine()) {
          this.preventClick = true;
          this.geometryService.addVertexToExistingLine(vertexMeta);
        }
      }

      // begin dragging
      if (vertexMeta) {
        this.draggingVertex = vertexMeta.vertex;
        this.draggingDownLoaction = [this.draggingVertex.x, this.draggingVertex.y];
        this.setCursor(true);
      }
    }
  }

  public onPointerUp(evt: MapBrowserEvent): void {
    if (this.draggingVertex) {
      const x = evt.coordinate[0];
      const y = evt.coordinate[1];
      const vertexMeta = this.geometryService.getVertex(x, y, this.draggingVertex);
      if (vertexMeta) {
        if (vertexMeta.isOnLine()) {
          this.geometryService.addVertexToExistingLine(new VertexMetadata(this.draggingVertex, false, vertexMeta.lineVertex1, vertexMeta.lineVertex2));
        } else if (!vertexMeta.isNewVertex) {
          this.geometryService.mergeVertices(this.draggingVertex, vertexMeta.vertex, this.draggingDownLoaction);
        }
      }
      if (this.draggingVertex.x !== this.draggingDownLoaction[0] || this.draggingVertex.y !== this.draggingDownLoaction[1]) {
        this.geometryService.updateVertexPosition(this.draggingVertex, false);
      }

    }
    this.draggingVertex = undefined;
    this.setCursor(false);
  }

  public onPointerDrag(evt: MapBrowserEvent): void {
    if (this.isEnabled() && this.draggingVertex) {
      this.preventClick = false;
      const x = evt.coordinate[0];
      const y = evt.coordinate[1];

      // snap on existing vertex
      const vertexMeta = this.geometryService.getVertex(x, y, this.draggingVertex);
      if (vertexMeta) {
        const vertex = vertexMeta.vertex;
        this.draggingVertex.x = vertex.x;
        this.draggingVertex.y = vertex.y;
        this.draggingVertex.isOnRoutableGeometry = vertex.isOnRoutableGeometry;
        this.draggingVertex.routingSegment = vertex.routingSegment;
      } else {
        this.draggingVertex.x = x;
        this.draggingVertex.y = y;
        this.draggingVertex.isOnRoutableGeometry = false;
        this.draggingVertex.routingSegment = undefined;
      }
      this.geometryService.updateVertexPosition(this.draggingVertex, true);
    }
  }

  public onPointerMove(evt: MapBrowserEvent): void {

    let hasNewVertexOnLine = false;
    const x = evt.coordinate[0];
    const y = evt.coordinate[1];

    if (this.isEnabled() && !this.draggingVertex) {
      let canInteract = false;

      // check if we are on a vertex
      const vertexMetaExisting = this.geometryService.getExistingVertex(x, y);
      if (vertexMetaExisting) {
        canInteract = true;

      } else {

        // check if we are on a line between two vertices
        const vertexMetaLine = this.geometryService.snapToPolygonLine(x, y);
        if (vertexMetaLine && vertexMetaLine.isOnLine()) {
          canInteract = true;
          this.newVertexOnLine = vertexMetaLine.vertex;
          hasNewVertexOnLine = true;
          this.drawingLayer.triggerRepaint();
        }
      }

      this.setCursor(canInteract);

      // activate / deactivate dragging of the map
      this.dragPanInteraction.setActive(!canInteract);
    }

    if (!hasNewVertexOnLine && this.newVertexOnLine) {
      this.newVertexOnLine = undefined;
      this.drawingLayer.triggerRepaint();
    }

  }

  public pause(): void {
    this.isPaused = true;
  }

  public resume(): void {
    this.isPaused = false;
  }

  getFeatures(): Feature[] {
    if (this.newVertexOnLine) {
      const coordinates = [this.newVertexOnLine.x, this.newVertexOnLine.y] as [number, number];
      return [new Feature(new Point(coordinates))];
    }
    return [];
  }

  getStyle(feature: Feature): Style | Style[] {
    return this.pointStyle;
  }

  setDrawingLayer(drawingLayer: DrawingLayer): void {
    this.drawingLayer = drawingLayer;
  }

  private isEnabled(): boolean {
    return this.isActive && !this.isPaused;
  }

  private setCursor(isInteractive: boolean): void {
    const viewport = this.map.getViewport();
    if (viewport instanceof HTMLElement) {
      if (isInteractive) {
        viewport.style.cursor = "pointer";
      } else if (viewport.style.cursor === "pointer") {
        viewport.style.cursor = "default";
      }
    }
  }
}
