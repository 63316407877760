import {Injectable} from "@angular/core";

import {Observable} from "rxjs";
import {map} from "rxjs/operators";

import {FilterProvider, Group} from "./filter-provider";
import {FilterGeneratedClient, Filter as APIFilter, DynamicFilterEntry} from "src/app/API/generated/clients";
import {AuthService} from "../../auth/auth.service";

@Injectable({
  providedIn: "root"
})
export class ApiFilterProvider implements FilterProvider {
  constructor(private apiClient: FilterGeneratedClient, private authService: AuthService) {}

  getGroups(): Observable<Array<Group>> {
    return this.apiClient.filter_Get(this.authService.realmId).pipe(map(filter => filter.map(f => this.mapApiMessage(f))));
  }

  getForeignLanguageFilterValues(): Observable<DynamicFilterEntry[]> {
    return this.apiClient.filter_GetFilterValuesFremdsprachig(this.authService.realmId).pipe(map(filter => filter));
  }

  getLevelFilterValues(): Observable<DynamicFilterEntry[]> {
    return this.apiClient.filter_GetFilterValuesStufe(this.authService.realmId).pipe(map(filter => filter));
  }

  getClassFilterValues(): Observable<DynamicFilterEntry[]> {
    return this.apiClient.filter_GetFilterValuesKlasse(this.authService.realmId).pipe(map(filter => filter));
  }

  getPlanClassFilterValues(): Observable<DynamicFilterEntry[]> {
    return this.apiClient.filter_GetFilterValuesPlanKlasse(this.authService.realmId).pipe(map(filter => filter));
  }

  getGenderFilterValues(): Observable<DynamicFilterEntry[]> {
    return this.apiClient.filter_GetFilterValuesGeschlecht(this.authService.realmId).pipe(map(filter => filter));
  }

  getSchoolBuildingTypeFilterValues(): Observable<DynamicFilterEntry[]> {
    return this.apiClient.filter_GetFilterValueSchulhausTyp(this.authService.realmId).pipe(map(filter => filter));
  }

  private mapApiMessage(f: APIFilter): Group {
    return {
      id: f.id,
      name: f.name
    };
  }
}
