import VectorSource from "ol/source/Vector";
import * as RBush from "rbush";
import LineString from "ol/geom/LineString";
import Map from "ol/Map";
import {GeometryService} from "../services/geometry/geometry.service";
import { GeometryTools } from "../tools/geometry-tools";
import { Polygon } from "../services/geometry/providers/geometry-provider";
import { ApiZusatzebeneGeometryProvider } from "../services/geometry/providers/api-zusatzebene-geometry-provider";
import { AppstateService } from "../services/appstate.service";
import { geom } from "openlayers";

export class GeometrySnap {
  private rBush = RBush();
  private zusatzebeneRBush = RBush();
  private schulkreise: Polygon[];

  constructor(
    source: VectorSource,
    apiZusatzebeneGeometryProvider: ApiZusatzebeneGeometryProvider,
    private appstateService: AppstateService,
    geometryService: GeometryService
    ) {
    source.once("change", () => {
      if (source.getState() === "ready") {
        const start = window.performance.now();
        this.addToRTree(source);
        const end = window.performance.now();
        console.log("Time to R Tree for " + source.getUrl() + " in ms: " + (end - start));
      } else {
        throw Error("Vector source not ready - Should not happen!");
      }
    });

    // initially load zusatzebenen
    apiZusatzebeneGeometryProvider.getPolygons(this).subscribe(schulkreise => {
      this.setZusatzebenen(schulkreise);
    });

    this.appstateService.getAdminChangeObservable().subscribe(isAdmin => {
      if (isAdmin) {
        this.zusatzebeneRBush = RBush();
      } else {
        if (this.schulkreise) this.setZusatzebenen(this.schulkreise);
      }
    });

    geometryService.getPolygons(false).subscribe(polygons => {
      if (this.appstateService.isAdminState()) {
        this.schulkreise = polygons;
      }
    });
  }

  public addSource(source: VectorSource): void {
    this.addToRTree(source);
  }

  public snapTo(x: number, y: number, toleranceMeters: number): SnapResult {
    const coordinate = [x, y] as [number, number];
    const lowerLeft = [coordinate[0] - toleranceMeters, coordinate[1] + toleranceMeters] as [number, number];
    const upperRight = [coordinate[0] + toleranceMeters, coordinate[1] - toleranceMeters] as [number, number];
    const box = this.getBbox([lowerLeft, upperRight]);

    const mapSegments = this.rBush.search(box) as SegmentData[];
    const zusatzSegments = this.zusatzebeneRBush.search(box) as SegmentData[];
    const segments = mapSegments.concat(zusatzSegments);
    if (segments.length > 0) {
      // find closest segment
      const distancesSq = segments.map(segment => GeometryTools.squaredDistanceToSegment(coordinate, segment.segment));
      let lowestDistIndex = 0;
      for (let i = 1; i < distancesSq.length; i++) {
        if (distancesSq[i] < distancesSq[lowestDistIndex]) lowestDistIndex = i;
      }
      if (distancesSq[lowestDistIndex] <= toleranceMeters * toleranceMeters) {
        const closestSegment = segments[lowestDistIndex].segment;
        const coordinateOnSegment = GeometryTools.closestOnSegment(coordinate, closestSegment);
        //console.log(new SnapResult(coordinateOnSegment[0], coordinateOnSegment[1], true, closestSegment));
        return new SnapResult(coordinateOnSegment[0], coordinateOnSegment[1], true, closestSegment);
      }
    }

    // Did not find anything to snap to
    return new SnapResult(coordinate[0], coordinate[1], false);
  }

  private addToRTree(readySource: VectorSource): void {
    if (readySource.getState() !== "ready") {
      throw Error("Routing sourcemust be ready!");
    }

    const bboxList: SegmentData[] = [];
    readySource.getFeatures().forEach(feature => {
      const geometry = feature.getGeometry();
      var properties = feature.getProperties()
      var type = properties.type;
      if(!(type == "footway" || type == "steps" || type == "path" || type == "track")) {
        if (geometry instanceof LineString) {
          //console.log(geometry.);
          const coordinates = geometry.getCoordinates();
          for (let i = 0; i < coordinates.length - 1; i++) {
            const segment = coordinates.slice(i, i + 2);
            const bbox = this.getBbox(segment);
            bboxList.push(bbox);
          }
        }
      }
    });
    this.rBush.load(bboxList);
  }

  private setZusatzebenen(polygons: Polygon[]): void {
    const bboxList: SegmentData[] = [];
    polygons.forEach(polygon => {
      const coordinates = polygon.getOpenlayersRoutedCoordinates();
      for (let i = 0; i < coordinates.length - 1; i++) {
        const segment = coordinates.slice(i, i + 2);
        const bbox = this.getBbox(segment);
        bboxList.push(bbox);
      }
    });
    this.zusatzebeneRBush = RBush();
    this.zusatzebeneRBush.load(bboxList);
  }

  private getBbox(segment: [number, number][]): SegmentData {
    const bbox = new SegmentData(segment);
    segment.forEach(coordinate => {
      bbox.maxX = Math.max(bbox.maxX, coordinate[0]);
      bbox.minX = Math.min(bbox.minX, coordinate[0]);
      bbox.maxY = Math.max(bbox.maxY, coordinate[1]);
      bbox.minY = Math.min(bbox.minY, coordinate[1]);
    });
    return bbox;
  }
}

export class SnapResult {
  constructor(public x: number, public y: number, public snapped: boolean, public segment?: [number, number][]) {}
}

class SegmentData {
  maxX = Number.MIN_SAFE_INTEGER;
  minX = Number.MAX_SAFE_INTEGER;
  maxY = Number.MIN_SAFE_INTEGER;
  minY = Number.MAX_SAFE_INTEGER;
  constructor(public segment: [number, number][]) {}
}
