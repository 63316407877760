import {Injectable} from "@angular/core";

import {Observable} from "rxjs";
import {map} from "rxjs/operators";

import {FileResponse, PrognoseGeneratedClient} from "src/app/API/generated/clients";

import * as _ from "lodash";
import * as moment from "moment";
import {AuthService} from "../../auth/auth.service";
import { ForecastProvider } from "./forecast-provider";

@Injectable({
  providedIn: "root"
})
export class ApiForecastProvider implements ForecastProvider {
  constructor(private apiClient: PrognoseGeneratedClient, private authService: AuthService) {}

  getYears(): Observable<number[]> {
    return this.apiClient.prognose_GetYears(this.authService.realmId);
  }
}
