import Style from "ol/style/Style";
import Feature from "ol/Feature";
import CircleStyle from "ol/style/Circle";
import FillStyle from "ol/style/Fill";
import StrokeStyle from "ol/style/Stroke";
import RegularShape from "ol/style/RegularShape";

import { StudentWithCoordinates } from "./student-appearence-styler";
import * as Color from "color";
import { ObjectBackedMap } from "../../../tools/object-backed-map";
import { RenderTools } from "../render-tools";
import { Student, Gender } from "../../../services/student/providers/student-provider";
import { ShapeStudentStyler, StudentLocation } from "./shape-student-styler";

export class GenderShapeStudentStyler extends ShapeStudentStyler {

  public static readonly LINE_STYLE = new Style({
    stroke: new StrokeStyle({color: "#606060", width: 2}),
  });

  private stylesWithClass = new ObjectBackedMap<Style>();
  private stylesWithoutClass = new ObjectBackedMap<Style>();

  getStyle(feature: Feature, unitsPerPixel: number): Style {

    // get correctly colored style
    const colorHex: string = feature.get("color");
    const hasClass: boolean = feature.get("hasClass");
    const isHovered: boolean = feature.get("hovered");
    const gender: number = feature.get("gender");
    if (colorHex) {
      const style = this.getOrCreateSyle(colorHex, hasClass, isHovered, unitsPerPixel, gender);

      // update zIndex
      const zIndex: number = parseInt(feature.get("zIndex"), 10);
      style.setZIndex(zIndex);
      return style;

    } else {
      return GenderShapeStudentStyler.LINE_STYLE;
    }
  }

  getProperties(studentWithCoordinates: StudentWithCoordinates, hoveredStudent: Student): Object {
    const student = studentWithCoordinates.student;
    const perimeter = student.perimeter;
    let color = Color.rgb(255, 255, 255);
    if (perimeter) color = perimeter.color;
    return {id: student.id,
            zIndex: studentWithCoordinates.zIndex.toString(),
            color: color.hex(),
            hasClass: (student.schoolClass !== undefined),
            hovered: (hoveredStudent && (student.id === hoveredStudent.id)),
            gender: student.gender};
  }

  protected addStudentToLocation(student: Student, location: StudentLocation): void {
    switch (student.gender) {
      case Gender .MALE:
        location.category1Students.push(student);
        break;
      case Gender.FEMALE:
        location.category2Students.push(student);
        break;
      case Gender.UNKNOWN:
        location.category3Students.push(student);
        break;
    }
  }

  private getOrCreateSyle(colorHex: string, hasClass: boolean, isHovered: boolean, unitsPerPixel: number, gender: number): Style {
    const styleMap = hasClass ? this.stylesWithClass : this.stylesWithoutClass;
    let radius = RenderTools.getStudentRadius(unitsPerPixel);
    if (isHovered) radius = radius * RenderTools.SCALE_SELECTED_STUDENT_BY_FACTOR;
    const key = colorHex + "_" + radius + "_" + gender;

    if (!styleMap.contains(key)) {
      const style = this.createStyle(colorHex, hasClass, radius, gender);
      styleMap.put(key, style);
    }
    return styleMap.get(key);
  }

  private createStyle(colorHex: string, hasClass: boolean, radius: number, gender: number): Style {

    // Define fill and stroke
    let fill: FillStyle;
    let stroke: StrokeStyle;
    if (hasClass) {
      const fillColor = new Color(colorHex);
      fill = new FillStyle({color: fillColor.array() as [number, number, number, number]});
      stroke = new StrokeStyle({color: Color.rgb(0, 0, 0).array() as [number, number, number, number], width: 1.5});
    } else {
      const baseColor = new Color(colorHex);
      fill = new FillStyle({color: baseColor.lighten(0.6).hex()});
      stroke = new StrokeStyle({color: baseColor.darken(0.5).hex(), width: 1.5});
    }

    switch (gender) {
      case 1:
        return new Style({
          image: new CircleStyle({fill: fill, stroke: stroke, radius: radius})
        });
      case 2:
        return new Style({
          image: new RegularShape({fill: fill, stroke: stroke, points: 4, radius: radius * 1.1})
        });
      default:
      return new Style({
        image: new RegularShape({fill: fill, stroke: stroke, points: 5, radius: radius * 1.2, radius2: radius * 0.6})
      });
    }
  }
}
