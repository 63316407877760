import { Painter, DrawingLayer } from "../drawing-layer";
import { ObjectBackedMap } from "../../tools/object-backed-map";
import { AppstateService } from "../../services/appstate.service";

import Map from "ol/Map";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import Style from "ol/style/Style";
import { AppearanceService, StudentAppearence } from "../../services/appearance.service";
import { StudentDataService } from "../../services/student/student-data.service";
import { Student } from "../../services/student/providers/student-provider";
import { StudentWithCoordinates, IStudentAppearanceStyler } from "./studentAppearanceStylers/student-appearence-styler";
import { PerimeterColorStudentStyler } from "./studentAppearanceStylers/perimeter-color-student-styler";
import { StudentMapService } from "../../services/student/student-map.service";
import { GenderColorStudentStyler } from "./studentAppearanceStylers/gender-color-student-styler";
import { ForeignLanguageColorStudentStyler } from "./studentAppearanceStylers/foreign-language-color-student-styler";
import { GenderShapeStudentStyler } from "./studentAppearanceStylers/gender-shape-student-styler";
import { ForeignLanguageShapeStudentStyler } from "./studentAppearanceStylers/foreign-language-shape-student-styler";

export class StudentRenderer implements Painter {

  private drawingLayer: DrawingLayer;
  private students: Student[];
  private studentAppearanceStyler: IStudentAppearanceStyler;
  private hoveredStudent: Student;

  constructor(private map: Map,
                      studentDataService: StudentDataService,
                      private appStateService: AppstateService,
                      appearanceService: AppearanceService,
                      studentMapService: StudentMapService) {
    studentDataService.getStudents().subscribe(students => {
      this.students = students;
      this.repaint();
    });

    appearanceService.getStudentAppearenceObservable().subscribe(studentAppearance => {
      this.studentAppearanceStyler = this.getStudentStyler(studentAppearance);
      this.repaint();
    });

    studentMapService.getMenuHoverObservable().subscribe(student => {
      this.hoveredStudent = student;
      this.repaint();
    });
  }

  setDrawingLayer(drawingLayer: DrawingLayer): void {
    this.drawingLayer = drawingLayer;
  }

  getFeatures(): Feature[] {

    // make sure we are ready
    if (this.students === undefined || this.studentAppearanceStyler === undefined) return [];

    // only students buildigs if we are not in admin mode
    if (this.appStateService.isAdminState())  return [];

    const features = this.studentAppearanceStyler.getFeatures(this.students, this.unitsPerPixel(), this.hoveredStudent);
    return features;
  }

  getStyle(feature: Feature): Style | Style[] {
    return this.studentAppearanceStyler.getStyle(feature, this.unitsPerPixel());
  }

  private repaint(): void {
    if (this.drawingLayer) this.drawingLayer.triggerRepaint();
  }

  private unitsPerPixel(): number {
    return this.map.getView().getResolution();
  }

  private getStudentStyler(appearence: StudentAppearence): IStudentAppearanceStyler {
    switch (appearence) {
      case StudentAppearence.PerimeterColor:
        return new PerimeterColorStudentStyler();
      case StudentAppearence.GenderColor:
        return new GenderColorStudentStyler();
      case StudentAppearence.ForeignColor:
        return new ForeignLanguageColorStudentStyler();
      case StudentAppearence.GenderShape:
        return new GenderShapeStudentStyler();
      case StudentAppearence.ForeignShape:
        return new ForeignLanguageShapeStudentStyler();
      default:
        return new PerimeterColorStudentStyler();
    }
  }
}
