import {BinaryMinHeap} from "./binary-min-heap";
import {Node, Edge} from "./routing";
import {ObjectBackedMap} from "../tools/object-backed-map";

export class DijkstraAStar {
  /**
   * Finds the shortest path between source and dest up to a maximum
   * lenght of cutoffDistance.
   * @param source
   * @param dest
   * @param cutoffDistance
   */
  findPath(source: Node, dest: Node, cutoffDistance: number): Node[] {
    const open = new BinaryMinHeap<Node>(node => node.key, node => node.costHeuristic);
    const openList = new ObjectBackedMap<boolean>();
    const closedList = new ObjectBackedMap<boolean>();
    source.cost = 0;
    source.costHeuristic = 0;
    open.add(source);
    let current: Node;

    while (open.length > 0) {
      current = open.extractMin();
      openList.remove(current.key);
      closedList.put(current.key, true);

      // check if the path is guaranteed to be longer than the cutoff
      if (current.costHeuristic > cutoffDistance) {
        return [];
      }

      // check if we found the destination
      if (current === dest) {
        return this.getPathArray(source, dest);
      }

      // loop over edges of current node
      for (const edge of current.edges) {
        const successor = edge.node;

        // skip if we already handled the successor
        if (closedList.contains(successor.key)) {
          continue;
        }

        // skip if distance of new path is longer than previously discovered
        const cost = current.cost + edge.length;
        const costHeuristic = cost + successor.dist(dest);
        const containsSuccessor = openList.contains(successor.key);
        if (containsSuccessor && costHeuristic >= successor.costHeuristic) {
          continue;
        }

        // we found a better path --> update accordingly
        successor.predecessor = current;
        successor.cost = cost;
        successor.costHeuristic = costHeuristic;
        if (containsSuccessor) {
          open.decreaseKey(successor);
        } else {
          open.add(successor);
          openList.put(successor.key, true);
        }
      }
    }

    // No path found!
    return [];
  }

  private getPathArray(source: Node, dest: Node): Node[] {
    const path: Node[] = [];
    let current = dest;
    while (current !== source) {
      path.push(current);
      current = current.predecessor;
    }
    path.push(source);

    // invert path
    return path.reverse();
  }
}
