import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

import {BuildingProvider, Building, BuildingTypeName} from "./building-provider";
import {GebäudeGeneratedClient, Building as APIBuilding, GebäudeTypeNames as BuildingsTypeNames} from "src/app/API/generated/clients";

import {AuthService} from "../../auth/auth.service";

@Injectable({
  providedIn: "root"
})
export class ApiBuildingProvider implements BuildingProvider {
  constructor(private apiClient: GebäudeGeneratedClient, private authService: AuthService) {}

  getBuildings(): Observable<Array<Building>> {
    return this.apiClient.gebäude_Get(this.authService.realmId).pipe(map(buildings => buildings.map(b => this.mapApiMessage(b))));
  }

  getBuildingsTypeNames(): Observable<Array<BuildingsTypeNames>> {
    return this.apiClient.gebäude_GetTypeNames(this.authService.realmId).pipe(map(typesName => typesName.map(b => this.mapApiBuildingTypeName(b))));
  }

  private mapApiMessage(b: APIBuilding): Building {
    return {
      id: b.id,
      name: b.name,
      street: b.strasse,
      zipcode: b.plz,
      town: b.ort,
      type: b.typ,
      x: b.koordinateX,
      y: b.koordinateY
    };
  }

  private mapApiBuildingTypeName(b: BuildingsTypeNames): BuildingsTypeNames {
    return {
      key: b.key,
      value: b.value
    };
  }
}
