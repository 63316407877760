import {Component, OnInit, Input, ChangeDetectionStrategy, SimpleChanges, OnChanges} from "@angular/core";
import {Building, BuildingProvider} from "../../../services/building/providers/building-provider";
import * as _ from "lodash";

@Component({
  selector: "app-building-panel",
  templateUrl: "./building-panel.component.html",
  styleUrls: ["./building-panel.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuildingPanelComponent implements OnInit, OnChanges {
  @Input() building: Building;
  buildingColor: string;

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.building.currentValue) {
      this.buildingColor = _.find(BuildingProvider.BUILDING_COLORS, c => c[0] === this.building.type)[1].hex();
    }
  }
}
