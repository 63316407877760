import {Component, OnInit, Output} from "@angular/core";
import * as _ from "lodash";
import { FilterService } from "src/app/services/filter/filter.service";
import { Observable } from "rxjs";
import { ActiveFiltersService, RawFilters } from "src/app/services/active-filters.service";

@Component({
  selector: "app-filters-menu",
  templateUrl: "./filters-menu.component.html",
  styleUrls: ["./filters-menu.component.scss"]
})
export class FiltersMenuComponent implements OnInit {

  @Output()
  filterList: Array<SelectFilter> = [];


  private selectedForeignLanguage: string;
  private selectedClass: string;
  private selectedPlanClass: string;
  private selectedLevel: string;
  private selectedGender: string;
  private selectedSchoolBuildingType: string;

  constructor(private filterService: FilterService, private activeFilterService: ActiveFiltersService) {}

  ngOnInit(): void {
    this.activeFilterService.getActiveFilters().subscribe(f => {
      this.selectedForeignLanguage = f.foreignLanguage ? String(f.foreignLanguage) : null;
      this.selectedClass = f.klasse ? String(f.klasse) : null;
      this.selectedPlanClass = f.planKlasse ? String(f.planKlasse) : null;
      this.selectedLevel = f.stufe;
      this.selectedGender = f.gender;
      this.selectedSchoolBuildingType = f.schoolBuildingType;
    });
    this.filterList = [
      {
        filterLabel: "Fremdsprachig",
        options: this.filterService.getForeignLanguageFilterValues(),
        selectedOption: this.selectedForeignLanguage
      },
      {
        filterLabel: "Klasse",
        options: this.filterService.getForeignLanguageFilterValues(),
        selectedOption: this.selectedClass
      },
      {
        filterLabel: "Planklasse",
        options: this.filterService.getPlanClassFilterValues(),
        selectedOption: this.selectedPlanClass
      },
      {
        filterLabel: "Stufe",
        options: this.filterService.getLevelFilterValues(),
        selectedOption: this.selectedLevel
      },
      {
        filterLabel: "Geschlecht",
        options: this.filterService.getGenderFilterValues(),
        selectedOption: this.selectedGender
      },
      {
        filterLabel: "Schulhaustyp",
        options: this.filterService.getSchoolBuildingTypeFilterValues(),
        selectedOption: this.selectedSchoolBuildingType
      }
    ];
  }

  setFiltersOnChange(): any {
    const rawValues: RawFilters = {};
    this.filterList.forEach(s => {
      rawValues[s.filterLabel] = s.selectedOption;
    });
    this.activeFilterService.setActiveFilters(rawValues);
  }
}
export interface SelectFilter {
  filterLabel: string;
  options: Observable<any>;
  selectedOption: string;
}
