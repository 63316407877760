import {Injectable} from "@angular/core";
import {BehaviorSubject, fromEvent} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class MousePositionService {
  public currentCoordinates: [number, number];

  constructor() {
    fromEvent(document, "mousemove").subscribe((event: MouseEvent) => {
      this.currentCoordinates = [event.clientX, event.clientY];
    });
  }
}
