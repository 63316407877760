import {Injectable} from "@angular/core";
import {Observable, BehaviorSubject, combineLatest} from "rxjs";

import {Schoolclass} from "./providers/schoolclass-provider";
import {ApiSchoolclassProvider} from "./providers/api-schoolclass-provider";

import * as _ from "lodash";
import {BuildingDataService} from "../building/building-data.service";

@Injectable({
  providedIn: "root"
})
export class SchoolclassService {
  constructor(private schoolclassProvider: ApiSchoolclassProvider, private buildingService: BuildingDataService) {
    // load classes when the selected period changes
    this.buildingService.getBuildings().subscribe(buildings => {
        this.schoolclassProvider.getClasses().subscribe(classes => {
          // add Building to class
          classes.forEach(sc => {
            sc.building = _.find(buildings, b => b.id === sc.buildingId);
          });
          this.schoolclasses = classes;
          this.notifyObservers();
        });
        // call for Plan klasses
        this.schoolclassProvider.getClasses(true).subscribe(classes => {
          // add Building to class
          classes.forEach(sc => {
            sc.building = _.find(buildings, b => b.id === sc.buildingId);
          });
          this.schoolPlanClasses = classes;
          this.notifyPlanClassObservers();
        });
    });
  }

  private schoolclasses: Array<Schoolclass> = [];
  private schoolPlanClasses: Array<Schoolclass> = [];
  private schoolclassObservable = new BehaviorSubject<Array<Schoolclass>>(this.schoolclasses);
  private schoolPlanClassObservable = new BehaviorSubject<Array<Schoolclass>>(this.schoolPlanClasses);

  private notifyObservers(): void {
    this.schoolclassObservable.next(this.schoolclasses);
  }
  private notifyPlanClassObservers(): void {
    this.schoolPlanClassObservable.next(this.schoolPlanClasses);
  }

  public getSchoolclasses(): Observable<Schoolclass[]> {
    return this.schoolclassObservable.asObservable();
  }
  public getPlanSchoolclasses(): Observable<Schoolclass[]> {
    return this.schoolPlanClassObservable.asObservable();
  }
}
