import {Injectable} from "@angular/core";
import {Observable, BehaviorSubject, of} from "rxjs";
import {delay, map, concatAll, concatMap, repeat} from "rxjs/operators";
import { Student } from "./providers/student-provider";

@Injectable({
  providedIn: "root"
})
export class StudentMapService {
  private hoverSubject = new BehaviorSubject<Student>(undefined);
  private clickSubject = new BehaviorSubject<Student>(undefined);
  private studentListMouseHover = new BehaviorSubject<Student>(undefined);

  constructor() {}

  getMapHoverObservable(): Observable<Student> {
    return this.hoverSubject.asObservable();
  }

  setMapHovered(student: Student): void {
    this.hoverSubject.next(student);
  }

  getClickObservable(): Observable<Student> {
    return this.clickSubject.asObservable();
  }

  setClicked(student: Student): void {
    this.clickSubject.next(student);
  }

  getMenuHoverObservable(): Observable<Student> {
    return this.studentListMouseHover.asObservable();
  }

  setMenuHovered(student: Student): void {
    if (student === null) {
      this.studentListMouseHover.next(undefined);
    } else {
      this.studentListMouseHover.next(student);
    }
  }
}
