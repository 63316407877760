import {Component, OnInit, Inject, ViewChild, ElementRef, ChangeDetectionStrategy} from "@angular/core";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";
import {FormGroup, FormControl, Validators, ValidatorFn} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "app-edit-dialog",
  templateUrl: "./edit-dialog.component.html",
  styleUrls: ["./edit-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<EditDialogComponent>, private translate: TranslateService, @Inject(MAT_DIALOG_DATA) public data: EditDialogData) {}
  @ViewChild("mainInput")
  mainInput: ElementRef;

  mainForm = new FormGroup({
    name: new FormControl("", [Validators.required, Validators.maxLength(30), EditDialogComponent.validateUnique(this.data.invalidValues)])
  });

  static validateUnique(invalidValues: Array<string>): ValidatorFn {
    return (control: FormControl) => {
      const invalid = control.value && (invalidValues || []).some(v => control.value.toLowerCase() === v.toLowerCase());
      return invalid ? {unique: {value: control.value}} : null;
    };
  }

  get requiredError(): boolean {
    return this.mainForm.get("name").hasError("required");
  }

  get uniqueError(): boolean {
    return this.mainForm.get("name").hasError("unique");
  }

  get saveButtonLabel(): string {
    return this.data.saveButtonLabel || this.translate.instant("defaultDialogs.edit.buttons.save");
  }

  ngOnInit(): void {
    this.mainForm.controls.name.setValue(this.data.value);
    setTimeout(() => this.mainInput.nativeElement.select());
  }

  submit(): void {
    if (this.mainForm.valid) {
      this.dialogRef.close(this.mainForm.controls.name.value);
    }
  }
}

export interface EditDialogData {
  dialogTitle: string;
  label: string;
  value: string;
  saveButtonLabel?: string;
  invalidValues?: Array<string>;
}
