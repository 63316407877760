import {Component, OnInit, Inject} from "@angular/core";
import {FormGroup, FormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
  selector: "app-change-coordinates-dialog",
  templateUrl: "./change-coordinates-dialog.component.html",
  styleUrls: ["./change-coordinates-dialog.component.scss"]
})
export class ChangeCoordinatesDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ChangeCoordinatesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private coordinates: [number, number, number, number]
  ) {
    this.mainForm = new FormGroup({
      south: new FormControl(this.coordinates[0], [Validators.required, Validators.min(2000000)]),
      west: new FormControl(this.coordinates[1], [Validators.required, Validators.min(1000000)]),
      north: new FormControl(this.coordinates[2], [Validators.required, Validators.min(2000000)]),
      east: new FormControl(this.coordinates[3], [Validators.required, Validators.min(1000000)])
    });
  }

  mainForm: FormGroup;

  ngOnInit(): void {}

  submit(): void {
    if (this.mainForm.valid) {
      this.dialogRef.close(this.mainForm.value);
    }
  }
}
