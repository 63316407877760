import {Injectable} from "@angular/core";

import {Observable} from "rxjs";
import {flatMap} from "rxjs/operators";

import {Coordinates} from "./providers/mapadmin-provider";
import {ApiMapadminProvider} from "./providers/api-mapadmin-provider";
import {GeometryService} from "../geometry/geometry.service";

@Injectable({
  providedIn: "root"
})
export class MapadminService {
  constructor(private mapProvider: ApiMapadminProvider, private geometryService: GeometryService) {}

  public setCoordinates(newCoordinates: Coordinates): Observable<Object> {
    return this.geometryService
      // put it into db
      .setMapBoundaries([newCoordinates.south, newCoordinates.west, newCoordinates.north, newCoordinates.east])
      // then tell mapCut to do its job
      .pipe(flatMap(() => this.mapProvider.setCoordinates(newCoordinates)));
  }
}
