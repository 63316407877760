import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from "@angular/core";
import {trigger, state, style, transition, animate, keyframes} from "@angular/animations";
import {Building} from "../../services/building/providers/building-provider";
import {StudentMapService} from "../../services/student/student-map.service";
import {BuildingMapService} from "../../services/building/building-map.service";
import * as _ from "lodash";
import { Student } from "../../services/student/providers/student-provider";

@Component({
  selector: "app-info-panel",
  templateUrl: "./info-panel.component.html",
  styleUrls: ["./info-panel.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("rotateIcon", [
      state("true", style({transform: "rotate(0deg)"})),
      state("false", style({transform: "rotate(180deg)"})),
      transition("* => *", [animate("100ms 250ms cubic-bezier(0.0, 0.0, 0.2, 1)")])
    ]),
    trigger("fader", [
      transition(":enter", [style({opacity: 0}), animate("400ms cubic-bezier(0.0, 0.0, 0.2, 1)", style({opacity: 1}))]),
      transition(":leave", [style({opacity: 1}), animate("200ms cubic-bezier(0.4, 0.0, 1, 1)", style({opacity: 0}))])
    ]),
    trigger("faderEmptyText", [
      transition(":enter", [style({opacity: 0}), animate("400ms 200ms cubic-bezier(0.0, 0.0, 0.2, 1)", style({opacity: 1}))]),
      transition(":leave", [style({opacity: 0})])
    ])
  ]
})
export class InfoPanelComponent implements OnInit {
  constructor(
    private studentMapService: StudentMapService,
    private buildingMapService: BuildingMapService,
    private cd: ChangeDetectorRef
  ) {}

  isOpen: boolean;
  selectedStudent: Student;
  selectedBuilding: Building;

  ngOnInit(): void {
    this.isOpen = true;

    this.buildingMapService.getHoverObservable().subscribe(building => {
      this.selectedStudent = undefined;
      this.selectedBuilding = building;
      this.cd.markForCheck();
    });

    this.studentMapService.getMapHoverObservable().subscribe(student => {
      this.selectedStudent = student;
      this.selectedBuilding = undefined;
      this.cd.markForCheck();
    });
  }
}
