import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from "@angular/core";
import {trigger, state, style, transition, animate, keyframes} from "@angular/animations";
import {TranslateService} from "@ngx-translate/core";
import {PlanningvariantService} from "./services/planningvariant/planningvariant.service";
import {AppstateService} from "./services/appstate.service";
import {AuthService} from "./services/auth/auth.service";
import {NotifyService} from "./services/notify.service";
import { ActiveFiltersService } from "./services/active-filters.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("isFullWidth", [
      state("true", style({width: "100%"})),
      state("false", style({width: "*"})),
      transition("0 => 1", [animate("500ms cubic-bezier(0.4, 0.0, 0.2, 1)")]),
      transition("1 => 0", [animate("400ms cubic-bezier(0.4, 0.0, 0.2, 1)")])
    ]),
    trigger("flyInOut", [
      state("in", style({transform: "translateX(0)"})),
      transition(":enter", [
        animate(
          300,
          keyframes([
            style({transform: "translateX(-100%)", offset: 0}),
            style({transform: "translateX(15px)", offset: 0.3}),
            style({transform: "translateX(0)", offset: 1})
          ])
        )
      ]),
      transition(":leave", [
        animate(
          300,
          keyframes([
            style({transform: "translateX(0)", offset: 0}),
            style({transform: "translateX(15px)", offset: 0.3}),
            style({transform: "translateX(-100%)", offset: 1})
          ])
        )
      ])
    ])
  ]
})
export class AppComponent implements OnInit {
  initiated = false;
  authenticated = false;
  fatalError = false;

  bottomPanelFullWidth: boolean;
  hideBottomPanel = true;
  isAdmin = false;
  isDraw = false;
  isLoadingData = false;

  constructor(
    translate: TranslateService,
    private planningvariantService: PlanningvariantService,
    private appStateService: AppstateService,
    private authService: AuthService,
    private notifySerivce: NotifyService,
    private cd: ChangeDetectorRef,
    private activeFiltersService: ActiveFiltersService
  ) {
    translate.setDefaultLang("de");
    translate.use(translate.getBrowserLang());
  }

  ngOnInit(): void {
    this.authService.initAndTryLogin().subscribe(
      authenticated => {
        this.initiated = true;
        this.authenticated = authenticated;
        this.cd.markForCheck();
      },
      err => {
        this.fatalError = true;
        this.notifySerivce.showError("apiErrors.config.loadConfig");
        this.cd.markForCheck();
      }
    );

    this.planningvariantService.getSelectedPlanningvariant().subscribe(p => {
      if (!p) {
        this.hideBottomPanel = true;
        this.bottomPanelFullWidth = false;
      } else {
        this.hideBottomPanel = false;
      }
      this.cd.markForCheck();
    });

    this.appStateService.getDrawChangeObservable().subscribe(isDrawMode => {
      this.isDraw = isDrawMode;
      this.cd.markForCheck();
    });

    this.appStateService.getAdminChangeObservable().subscribe(isAdminMode => {
      this.isAdmin = isAdminMode;
      this.cd.markForCheck();
    });

    this.activeFiltersService.isLoadingDataFilters().subscribe(s => {
      this.isLoadingData = s;
    });
  }

  login(): void {
    this.authService.login();
  }

  setBottomPanelFullWidth(newVal: boolean): void {
    this.bottomPanelFullWidth = newVal;
  }
}
