import {Observable} from "rxjs";
import * as Color from "color";
import {v4 as uuid} from "uuid";
import {IPolygonService} from "../polygon.service";
import {ObjectBackedMap} from "../../../tools/object-backed-map";
import * as _ from "lodash";
import OlPolygon from "ol/geom/Polygon";
import {Planningvariant} from "../../planningvariant/providers/planningvariant-provider";
import {GeometrySnap} from "../../../routing/geometry-snap";
import { Coordinates } from "src/app/API/generated/clients";

export interface GeometryProvider {
  /*
   * Get the cooridatens of the edges of the map (CH1903+/LV95) - South, West, North, East
   */
  getMapBoundaries(): Observable<[number, number, number, number]>;

  /*
   * Set the cooridatens of the edges of the map (CH1903+/LV95) - South, West, North, East
   */
  setMapBoundaries(coordinates: [number, number, number, number]): Observable<Coordinates>;

  /*
   * Get all polygons.
   * A planningvariantId is only supplied if applicable (not in schoolareas mode).
   * @param planningvariantId
   */
  getPolygons(geometrySnap: GeometrySnap, planningvariantId?: string): Observable<Polygon[]>;

  /*
   * Rename a polygon that already exists in the database
   * @param id
   * @param newName
   */
  renamePolygon(id: string, newName: string): Observable<void>;

  /*
   * Delete the polygon with the supplied id
   * @param id
   */
  deletePolygon(id: string): Observable<void>;

  createPerimeterException(perimeterId: string, studentId: number): Observable<string>;
  updatePerimeterException(gisExceptionId: string, perimeterId: string): Observable<void>;
  deletePerimeterException(id: string): Observable<void>;

  /*
   * Replace all existing polygons with the supplied ones.
   * A planningvariantId is only supplied if applicable (not in schoolareas mode).
   * @param planningvariantId
   * @param polygons
   */
  savePolygons(polygons: Polygon[], planningvariant?: Planningvariant): Observable<void>;
}

export class Vertex {
  id: string = uuid();
  polygons = new ObjectBackedMap<Polygon>();
  isOnRoutableGeometry = false;
  isHumanCreated = true;

  public get key(): string {
    return this.id;
  }

  public getLocationKey(): string {
    return this.x + "_" + this.y;
  }

  constructor(
    public x: number,
    public y: number,
    isOnRoutableGeometry?: boolean,
    public routingSegment?: [number, number][],
    isHumanCreated?: boolean,
    id?: string
  ) {
    if (id !== undefined) this.id = id;
    if (isOnRoutableGeometry) this.isOnRoutableGeometry = isOnRoutableGeometry;
    if (isHumanCreated !== undefined) this.isHumanCreated = isHumanCreated;
  }

  equals(other: any): boolean {
    if (other instanceof Vertex) {
      return this.x === other.x && this.y === other.y;
    }
    return false;
  }
}

export class Polygon {
  public id: string = uuid();

  // tuple of studentId, gisExceptionId
  public gisExceptions: GisException[] = [];

  private humanCreatedVertices: Vertex[] = [];
  public underConstruction = false;
  private _routedVertexStrings: Vertex[][] = []; // each element stores a human created vertex followed by a string of routed vertices
  private get routedVertexStrings(): Vertex[][] {
    this.revision++;
    return this._routedVertexStrings;
  }
  private set routedVertexStrings(routedVertexStrings: Vertex[][]) {
    this._routedVertexStrings = routedVertexStrings;
    this.revision++;
  }
  private polygonService: IPolygonService;
  private revision = 0;
  private containsGeometryUpdatedRevision = -1;
  private routedGeometry: OlPolygon;

  constructor(public name: string, public color: Color, vertices?: Vertex[], underConstruction?: boolean, id?: string, gisExceptions?: GisException[]) {
    if (id) this.id = id;
    if (gisExceptions) this.gisExceptions = gisExceptions;
    if (vertices) {
      let currentIndex = -1;
      for (let i = 0; i < vertices.length; i++) {
        if (vertices[i].isHumanCreated) {
          currentIndex++;
          this.humanCreatedVertices.push(vertices[i]);
          this.routedVertexStrings.push([vertices[i]]);
          vertices[i].polygons.put(id, this);
        } else if (currentIndex >= 0) {
          this.routedVertexStrings[currentIndex].push(vertices[i]);
        }
      }
    }
    if (underConstruction !== undefined) this.underConstruction = underConstruction;
  }

  setPolygonService(polygonService: IPolygonService): void {
    this.polygonService = polygonService;
  }

  getHumanCreatedVertices(): Vertex[] {
    return this.humanCreatedVertices;
  }

  appendVertex(vertex: Vertex): void {
    this.humanCreatedVertices.push(vertex);
    this.routedVertexStrings.push([vertex]);
    this.onVertexUpdated(vertex);
  }

  addVertex(index: number, vertex: Vertex): void {
    this.humanCreatedVertices.splice(index, 0, vertex);
    this.routedVertexStrings.splice(index, 0, [vertex]);
    this.onVertexUpdated(vertex);
  }

  deleteVertex(vertex: Vertex): void {
    if (this.humanCreatedVertices.length > 0 && this.humanCreatedVertices[0].equals(vertex)) {
      this.removeAllVertexOccurances(vertex);
      this.appendVertex(this.humanCreatedVertices[0]);
    } else {
      this.removeAllVertexOccurances(vertex);
    }
  }

  /**
   * Returns the human created vertex coordinates in the format suitable for openlayers
   */
  public getOpenlayersCoordinates(additionalVertex?: Vertex, filter?: (v: Vertex) => boolean): [number, number][] {
    let vertices = this.humanCreatedVertices;
    if (filter) vertices = vertices.filter(filter);
    const coordinates = this.asCoordinateArray(vertices);
    if (additionalVertex && (!filter || filter(additionalVertex))) coordinates.push([additionalVertex.x, additionalVertex.y]);
    return coordinates;
  }

  getAllVertices(): Vertex[] {
    return _.flatten(this.routedVertexStrings);
  }

  getOpenlayersRoutedCoordinates(additionalVertex?: Vertex): [number, number][] {
    let vertices = this.getAllVertices();
    if (additionalVertex) {
      if (vertices.length > 0) {
        const last = vertices[vertices.length - 1];
        if (last.isOnRoutableGeometry && additionalVertex.isOnRoutableGeometry) {
          const path = this.polygonService.getRoutedPath(last, additionalVertex);

          // remove first and last vertex
          path.shift();
          path.pop();

          vertices = _.concat(vertices, path);
        }
      }
      vertices.push(additionalVertex);
    }

    const coordinates = this.asCoordinateArray(vertices);
    return coordinates;
  }

  getRoutedPath(from: Vertex, to: Vertex): [number, number][] {
    if (!from.isOnRoutableGeometry || !to.isOnRoutableGeometry) throw Error("Both vertices must be on routable geometry.");
    for (let i = 1; i < this.humanCreatedVertices.length; i++) {
      if (this.humanCreatedVertices[i - 1].equals(from) && this.humanCreatedVertices[i].equals(to)) {
        const copy = _.concat(this.routedVertexStrings[i - 1], to);
        // const copy = this.routedVertexStrings[i - 1].slice(1, this.routedVertexStrings[i - 1].length - 1);
        return this.asCoordinateArray(copy);
      }
    }
    return undefined;
  }

  equals(other: any): boolean {
    if (other instanceof Polygon) {
      return this.id === other.id;
    }
    return false;
  }

  getDirectVertexSuccessionLocations(vertex1: Vertex, vertex2: Vertex): number[] {
    const indices: number[] = [];
    for (let i = 1; i < this.humanCreatedVertices.length; i++) {
      if (
        (this.humanCreatedVertices[i - 1].equals(vertex1) && this.humanCreatedVertices[i].equals(vertex2)) ||
        (this.humanCreatedVertices[i - 1].equals(vertex2) && this.humanCreatedVertices[i].equals(vertex1))
      ) {
        indices.push(i);
      }
    }
    return indices;
  }

  getPredecessor(vertex: Vertex): Vertex {
    const index = _.findIndex(this.humanCreatedVertices, v => v.equals(vertex));
    if (index >= 0) {
      let predecessorIndex = index - 1;
      if (predecessorIndex < 0) predecessorIndex += this.humanCreatedVertices.length - 1; // skip last vertex because it is the smae as the last vertex
      return this.humanCreatedVertices[predecessorIndex];
    }
    return undefined;
  }

  getSuccessor(vertex: Vertex): Vertex {
    const index = _.findIndex(this.humanCreatedVertices, v => v.equals(vertex));
    if (index >= 0) {
      let predecessorIndex = index + 1;
      if (predecessorIndex >= this.humanCreatedVertices.length) predecessorIndex = 1; // skip first vertex because it is the smae as the last vertex
      return this.humanCreatedVertices[predecessorIndex];
    }
    return undefined;
  }

  replaceVertex(toReplace: Vertex, replacement: Vertex): void {
    for (let i = 0; i < this.humanCreatedVertices.length; i++) {
      if (this.humanCreatedVertices[i].equals(toReplace)) {
        this.humanCreatedVertices[i] = replacement;
      }
    }
    this.onVertexUpdated(replacement);
  }

  containsPointUnrouted(x: number, y: number): boolean {
    const polygonCoordinates = this.getOpenlayersCoordinates();
    const geometry = new OlPolygon([polygonCoordinates]);
    return geometry.intersectsCoordinate([x, y]);
  }

  // TODO: make faster if used often
  containsPointRouted(x: number, y: number): boolean {
    // update geometry if necessary
    if (this.containsGeometryUpdatedRevision < this.revision) {
      this.routedGeometry = new OlPolygon([this.getOpenlayersRoutedCoordinates()]);
      this.containsGeometryUpdatedRevision = this.revision;
    }
    return this.routedGeometry.intersectsCoordinate([x, y]);
  }

  /** Removes instances of the same vertex occuring in direct succession */
  removeDuplicates(): void {
    for (let i = this.humanCreatedVertices.length - 1; i > 0; i--) {
      if (this.humanCreatedVertices[i - 1] === this.humanCreatedVertices[i]) {
        if (i > 1) {
          this.humanCreatedVertices.splice(i - 1, 1);
        } else {
          this.humanCreatedVertices.splice(i, 1); // never remove first/last vertex
        }
      }
    }
  }

  /**
   * Counts how often a vertex occures in the polygon. Does not count the start / endvertex twice
   * @param vertex
   */
  getVertexOccuranceCount(vertex: Vertex): number {
    let count = 0;
    for (let i = 1; i < this.humanCreatedVertices.length; i++) {
      if (this.humanCreatedVertices[i].id === vertex.id) count++;
    }
    return count;
  }

  onVertexUpdated(vertex: Vertex): void {
    for (let i = 0; i < this.humanCreatedVertices.length; i++) {
      if (this.humanCreatedVertices[i].equals(vertex)) {
        this.updateSegment(i - 1);
        this.updateSegment(i);
      }
    }
  }

  private updateSegment(index: number): void {
    if (index >= 0) {
      const from = this.humanCreatedVertices[index];
      this.routedVertexStrings[index] = [from];
      if (index < this.humanCreatedVertices.length - 1) {
        const to = this.humanCreatedVertices[index + 1];
        if (from.isOnRoutableGeometry && to.isOnRoutableGeometry) {
          this.updateRoutedSegment(index, from, to);
        }
      }
    }
  }

  private updateRoutedSegment(index: number, from: Vertex, to: Vertex): void {
    const path = this.polygonService.getRoutedPath(from, to);

    // remove first and last element of path
    path.shift();
    path.pop();

    this.routedVertexStrings[index] = [from].concat(path);
  }

  private asCoordinateArray(vertices: Vertex[]): [number, number][] {
    return vertices.map(vertex => [vertex.x, vertex.y] as [number, number]);
  }

  private removeAllVertexOccurances(vertex: Vertex): void {
    for (let i = 0; i < this.humanCreatedVertices.length; i++) {
      if (this.humanCreatedVertices[i].equals(vertex)) {
        this.humanCreatedVertices.splice(i, 1);
        this.routedVertexStrings.splice(i, 1);
        if (i > 0) this.onVertexUpdated(this.humanCreatedVertices[i - 1]);
      }
    }
  }
}

export interface GisException {
  id: string;
  perimeterId: string;
  studentId: number;
}

export interface GisExceptionWithPerimeter extends GisException {
  perimeter: Polygon;
}
