import {Injectable} from "@angular/core";

import {Observable, BehaviorSubject} from "rxjs";
import {tap, map} from "rxjs/operators";

import {Planningvariant} from "./providers/planningvariant-provider";
import {ApiPlanningvariantProvider} from "./providers/api-planningvariant-provider";

import * as _ from "lodash";

@Injectable({
  providedIn: "root"
})
export class PlanningvariantService {
  constructor(private planningvariantProvider: ApiPlanningvariantProvider) {}

  private planningvariants: Array<Planningvariant> = [];
  private planningvariantsObservable = new BehaviorSubject<Array<Planningvariant>>(this.planningvariants);
  private selectedPlanningvariantsObservable = new BehaviorSubject<Planningvariant>(null);

  private notifyObservers(): void {
    this.planningvariantsObservable.next(this.planningvariants);
  }

  public createPlanningvariant(name: string): Observable<string> {
    return this.planningvariantProvider.createPlanningvariant(name).pipe(
      tap(newID => {
        const newPlanningvariant = new Planningvariant(newID, name);
        this.planningvariants.push(newPlanningvariant);
        this.setActivePlanningvariant(newPlanningvariant.id);
        this.notifyObservers();
      })
    );
  }

  public renamePlanningvariant(id: string, newName: string): Observable<void> {
    return this.planningvariantProvider.renamePlanningvariant(id, newName).pipe(
      tap(() => {
        _.find(this.planningvariants, p => p.id === id).name = newName;
        this.notifyObservers();
      })
    );
  }

  public deletePlanningvariant(id: string): Observable<void> {
    return this.planningvariantProvider.deletePlanningvariant(id).pipe(
      tap(() => {
        this.planningvariants = this.planningvariants.filter(planningvariant => planningvariant.id !== id);
        this.notifyObservers();
      })
    );
  }

  public copyPlanningVariant(id: string, newName: string): Observable<string> {
    return this.planningvariantProvider.copyPlanningvariant(id, newName).pipe(
      map(newPlanningvariant => {
        this.planningvariants.push(newPlanningvariant);
        this.setActivePlanningvariant(newPlanningvariant.id);
        this.notifyObservers();

        return newPlanningvariant.id;
      })
    );
  }

  // get cached planningvariants
  // will ony yield results after getPlanningvariantsWithRefresh() was called at least once
  // observable will not complete
  public getPlanningvariants(): Observable<Array<Planningvariant>> {
    return this.planningvariantsObservable.asObservable();
  }

  // loads PV's from API and caches them in the service
  // subscribe to this to renew cache
  // observable will complete after reload
  public getPlanningvariantsWithRefresh(): Observable<Array<Planningvariant>> {
    return this.planningvariantProvider.getPlanningvariants().pipe(
      map(pvFromApi => {
        return _.orderBy(pvFromApi, [p => p.name.toLowerCase()]);
      }),
      tap(pvFromApi => {
        const currentlySelectedPV = _.find(this.planningvariants, p => p.selected);
        let selectedId: string;
        if (currentlySelectedPV) {
          selectedId = currentlySelectedPV.id;
        }

        this.planningvariants = pvFromApi;
        if (selectedId) this.setActivePlanningvariantWithoutNotification(selectedId);
        this.notifyObservers();
      })
    );
  }

  public getSelectedPlanningvariant(): Observable<Planningvariant> {
    return this.selectedPlanningvariantsObservable.asObservable();
  }

  public setActivePlanningvariant(id: string): void {
    this.setActivePlanningvariantWithoutNotification(id);
    this.selectedPlanningvariantsObservable.next(_.find(this.planningvariants, p => p.selected));
  }

  private setActivePlanningvariantWithoutNotification(id: string): void {
    this.planningvariants.forEach(p => (p.selected = false));
    if (id && this.planningvariants.some(p => p.id === id)) {
      _.find(this.planningvariants, p => p.id === id).selected = true;
    }
  }
}
