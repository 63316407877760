import {Injectable} from "@angular/core";
import {Observable, BehaviorSubject, Subject} from "rxjs";

import { ApiForecastProvider } from "./providers/api-forecast-provider";

@Injectable({
  providedIn: "root"
})
export class ForecastService {
  constructor(private periodProvider: ApiForecastProvider) {}

  public getYears(): Observable<Array<number>> {
    return this.periodProvider.getYears();
  }

}
