import {Component, OnInit, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges, ChangeDetectorRef, ElementRef} from "@angular/core";
import {Building, BuildingProvider} from "src/app/services/building/providers/building-provider";
import {BuildingMapService} from "src/app/services/building/building-map.service";
import {BuildingDataService} from "src/app/services/building/building-data.service";
import {TranslateService} from "@ngx-translate/core";
import {FormControl} from "@angular/forms";
import {Validators} from "@angular/forms";
import * as _ from "lodash";
import {trigger, transition, animate, style, group} from "@angular/animations";
import {debounceTime, distinctUntilChanged, map, filter} from "rxjs/operators";

@Component({
  selector: "app-onclick-building-panel",
  templateUrl: "./onclick-building-panel.component.html",
  styleUrls: ["./onclick-building-panel.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("showHide", [
      transition(":enter", [
        group([
          style({opacity: "0"}),
          animate("200ms cubic-bezier(0.4, 0.0, 1, 1)", style({opacity: "1"}))
        ])
      ]),
      transition(":leave", [animate("150ms cubic-bezier(0.4, 0.0, 1, 1)", style({opacity: "0"}))])
    ])
  ]
})
export class OnclickBuildingPanelComponent implements OnInit, OnChanges {
  private static minRadius = 0;
  private static maxRadius = 10;

  @Input()
  building: Building;
  buildingColor: string;

  @Input()
  position: [number, number];
  radius = new FormControl("", [Validators.min(OnclickBuildingPanelComponent.minRadius), Validators.max(OnclickBuildingPanelComponent.maxRadius)]);

  constructor(
    private cd: ChangeDetectorRef,
    private buildingDataServce: BuildingDataService,
    private buildingMapService: BuildingMapService,
    private translate: TranslateService,
    private el: ElementRef
  ) {}

  closePanel(): void {
    this.buildingMapService.setClicked(undefined);
  }

  resetRadius(): void {
    this.radius.reset();
  }

  errorMsg(): string {
    const minErrorMsg = this.translate.instant("infoPanel.onClickPanel.errors.min") + ": " + OnclickBuildingPanelComponent.minRadius.toString();
    const maxErrorMsg = this.translate.instant("infoPanel.onClickPanel.errors.max") + ": " + OnclickBuildingPanelComponent.maxRadius.toString();

    return this.radius.hasError("min") ? minErrorMsg : this.radius.hasError("max") ? maxErrorMsg : "";
  }

  ngOnInit(): void {
    this.radius.valueChanges
      .pipe(
        filter(val => val >= OnclickBuildingPanelComponent.minRadius && val <= OnclickBuildingPanelComponent.maxRadius),
        map(val => [this.building.id, val])
      )
      .pipe(
        debounceTime(150),
        distinctUntilChanged()
      )
      .subscribe(buildingIdAndRadius => {
        this.buildingDataServce.setRadius(buildingIdAndRadius[0], buildingIdAndRadius[1]);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.building && changes.building.currentValue) {
      this.radius.setValue(this.building.radius, {
        emitEvent: false
      });
      this.buildingColor = _.find(BuildingProvider.BUILDING_COLORS, c => c[0] === this.building.type)[1].hex();
    }

    if (changes.position && this.building) {
      // check if we fit on screen (after timeout, so the animation has run and we have a height)
      setTimeout(() => {
        const cardElement = this.el.nativeElement.children[0];
        const height = cardElement.offsetHeight;
        const width = cardElement.offsetWidth;

        if (this.position[0] + width > window.innerWidth) {
          this.position[0] = this.position[0] - width;
          this.cd.markForCheck();
        }

        if (this.position[1] + height > window.innerHeight) {
          this.position[1] = this.position[1] - height;
          this.cd.markForCheck();
        }
      });
    }
  }
}
