import {Injectable} from "@angular/core";

import {Observable} from "rxjs";
import {map} from "rxjs/operators";

import {Schoolclass, SchoolclassProvider} from "./schoolclass-provider";
import {KlassenGeneratedClient, Schoolclass as APISchoolclass} from "src/app/API/generated/clients";

import * as _ from "lodash";
import {AuthService} from "../../auth/auth.service";

@Injectable({
  providedIn: "root"
})
export class ApiSchoolclassProvider implements SchoolclassProvider {
  constructor(private apiClient: KlassenGeneratedClient, private authService: AuthService) {}

  getClasses(planKlasse?: boolean): Observable<Array<Schoolclass>> {
    // [code adaptation - dec'20] PlanKlasse?
    return this.apiClient.klassen_Get(
      this.authService.realmId,
      planKlasse
    )
    .pipe(map(classes => classes.map(c => this.mapApiMessage(c))));
  }

  private mapApiMessage(c: APISchoolclass): Schoolclass {
    return {
      id: c.id,
      name: c.name,
      teacher: c.teacher,
      buildingId: c.buildingId
    };
  }
}
