import {Injectable} from "@angular/core";

import {Observable, BehaviorSubject} from "rxjs";
import {tap} from "rxjs/operators";

import {Group} from "./providers/filter-provider";
import {ApiFilterProvider} from "./providers/api-filter-provider";

import * as _ from "lodash";
import { DynamicFilterEntry } from "src/app/API/generated/clients";


@Injectable({
  providedIn: "root"
})
export class FilterService {
  constructor(private filterProvider: ApiFilterProvider) {}

  private filter: Array<Group> = [];
  private selectedFilterObservable = new BehaviorSubject<Group>(null);

  public getGroup(): Observable<Group[]> {
    return this.filterProvider.getGroups().pipe(
      tap(apiFilter => {
        const currentlySelectedFilter = _.find(this.filter, f => f.selected);
        let selectedId: number;
        if (currentlySelectedFilter) {
          selectedId = currentlySelectedFilter.id;
        }
        this.filter = apiFilter;
        if (selectedId) this.setActiveGroupWithoutNotification(selectedId);
      })
    );
  }

  public getForeignLanguageFilterValues(): Observable<DynamicFilterEntry[]> {
    return this.filterProvider.getForeignLanguageFilterValues();
  }

  public getLevelFilterValues(): Observable<DynamicFilterEntry[]> {
    return this.filterProvider.getLevelFilterValues();
  }

  public getClassFilterValues(): Observable<DynamicFilterEntry[]> {
    return this.filterProvider.getClassFilterValues();
  }

  public getPlanClassFilterValues(): Observable<DynamicFilterEntry[]> {
    return this.filterProvider.getPlanClassFilterValues();
  }

  public getGenderFilterValues(): Observable<DynamicFilterEntry[]> {
    return this.filterProvider.getGenderFilterValues();
  }

  public getSchoolBuildingTypeFilterValues(): Observable<DynamicFilterEntry[]> {
    return this.filterProvider.getSchoolBuildingTypeFilterValues();
  }

  public getSelectedGroup(): Observable<Group> {
    return this.selectedFilterObservable.asObservable();
  }

  public setActiveGroup(id: number): void {
    this.setActiveGroupWithoutNotification(id);
    this.selectedFilterObservable.next(_.find(this.filter, f => f.selected));
  }

  private setActiveGroupWithoutNotification(id: number): void {
    this.filter.forEach(f => (f.selected = false));
    if (id && this.filter.some(f => f.id === id)) {
      _.find(this.filter, f => f.id === id).selected = true;
    }
  }
}
