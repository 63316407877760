import {
  Component,
  OnInit,
  OnDestroy,
  Renderer2,
  ElementRef,
  Input,
  ChangeDetectionStrategy
} from "@angular/core";

@Component({
  selector: "pmi-portalbar",
  templateUrl: "./portalbar.component.html",
  styleUrls: ["./portalbar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalbarComponent implements OnInit, OnDestroy {
  portalToggle: boolean;

  @Input() activePortal: number;

  private unlistenWindowClick: () => void;

  portals: Array<Portal> = [
    // {
    //    id: 1,
    //    name: "Kommunikation",
    //    bgColor: "#428BCA",
    //    color: "#FFF",
    //    URLPrefix: "kommunikation",
    //    icon: "fa-envelope"
    // },
    {
      id: 2,
      name: "Betreuung",
      bgColor: "#298e27",
      borderColor: "#188216",
      color: "#FFF",
      URLPrefix: "betreuung",
      icon: "people"
    },
    // {
    //    id: 3,
    //    name: "Dashboard",
    //    bgColor: "#6d10a7",
    //    color: "#FFF",
    //    URLPrefix: "dashboard",
    //    icon: "fa-pie-chart"
    // },
    {
      id: 4,
      name: "Musik",
      bgColor: "#2196F3",
      borderColor: "#218de2",
      color: "#FFF",
      URLPrefix: "musik",
      icon: "audiotrack"
    },
    {
      id: 5,
      name: "GIS",
      bgColor: "#00897b",
      borderColor: "#00796b",
      color: "#FFF",
      URLPrefix: "gis",
      icon: "map"
    }
  ];

  constructor(private renderer: Renderer2, private element: ElementRef) {}

  ngOnInit() {
    this.unlistenWindowClick = this.renderer.listen("window", "click", e => {
      this.portalToggle = false;
    });

    this.renderer.listen(
      this.element.nativeElement,
      "click",
      (e: MouseEvent) => {
        e.stopPropagation();
      }
    );
  }

  ngOnDestroy() {
    this.unlistenWindowClick();
  }

  getPortal(portalId: number): Portal {
    return this.portals.find(p => p.id === portalId);
  }

  get currentPortal(): Portal {
    return this.getPortal(this.activePortal);
  }

  get currentUser(): string {
    return window.sessionStorage.getItem("currentUser");
  }

  togglePortalMenu() {
    this.portalToggle = !this.portalToggle;
  }

  navigate(URLPrefix: string) {
    const currentHost = window.location.hostname;

    if (currentHost === "localhost") {
      console.warn("Can't switch location on localhost. Target:", URLPrefix);
      this.portalToggle = false;
    } else {
      const splittedHost = currentHost.split(".");
      splittedHost[0] = URLPrefix;
      window.location.href =
        window.location.protocol + "//" + splittedHost.join(".");
    }
  }

  portalBarStyle(portal?: Portal): BarStyle {
    if (!portal) {
      portal = this.currentPortal;
    }

    const styleObject: BarStyle = {
      "background-color": portal.bgColor,
      color: portal.color
    };

    return styleObject;
  }

  borderColor(): string {
    return this.currentPortal.borderColor;
  }
}

interface Portal {
  id: number;
  name: string;
  bgColor: string;
  color: string;
  borderColor?: string;
  URLPrefix: string;
  icon: string;
}

interface BarStyle {
  "background-color": string;
  color: string;
}
