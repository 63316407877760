import {Observable} from "rxjs";
import {Polygon, GisExceptionWithPerimeter} from "../../geometry/providers/geometry-provider";
import {Moment} from "moment";
import {Schoolclass} from "../../schoolclass/providers/schoolclass-provider";
import { Schulweg } from "src/app/API/generated/clients";

export interface StudentProvider {
  getStudents(filterId: number, foreignLanguage: boolean, klasse: boolean, classPlan: boolean, level: string,
              gender: string, schoolBuildingType: string): Observable<Student[]>;
  setSchoolclassAssignments(schoolclass: Schoolclass, studentIds: Array<number>, isClassPlan: boolean): Observable<void>;
  setPerimeters(newAssignments: Array<StudentsAndPerimeter>): Observable<void>;
  getSchoolroute(studentId: number, perimeterId: string): Observable<Schulweg[]>;
}

export class Student {
  private spatialPerimeter: Polygon;

  public get perimeter(): Polygon {
    if (this.perimeterException) {
      return this.perimeterException.perimeter;
    } else {
      return this.spatialPerimeter;
    }
  }

  constructor(
    public id: number,
    public lastName: string,
    public firstName: string,
    public birthdate: Moment,
    public gender: Gender,
    public foreignLanguage: boolean,
    public address: string,
    public zip: string,
    public town: string,
    public x: number,
    public y: number,
    public gisRemark: string,
    public perimeterException?: GisExceptionWithPerimeter,
    public schoolClassId?: number,
    public schoolClass?: Schoolclass,
    public schoolPlanClassId?: number,
    public schoolPlanClass?: Schoolclass
  ) {}

  public setSpatialPerimeter(polygon: Polygon): void {
    this.spatialPerimeter = polygon;
  }
}

export enum Gender {
  MALE = 1,
  FEMALE = 2,
  UNKNOWN = 3
}

export interface StudentsAndPerimeter {
  studentId: number;
  perimeterName?: string;
}
