import OlPolygon from "ol/geom/Polygon";
import Feature from "ol/Feature";
import RegularShape from "ol/style/RegularShape";
import TextStyle from "ol/style/Text";

import Style from "ol/style/Style";
import FillStyle from "ol/style/Fill";
import StrokeStyle from "ol/style/Stroke";

import { PolygonService } from "../services/geometry/polygon.service";
import { Polygon } from "../services/geometry/providers/geometry-provider";

import * as Color from "color";
import { AppstateService, AppState } from "../services/appstate.service";
import MultiPoint from "ol/geom/MultiPoint";
import { Painter, DrawingLayer } from "./drawing-layer";

export class PolygonRenderer implements Painter {

  private state: AppState;
  private polygons: Polygon[] = [];
  private hoveredPolygon: Polygon;
  private drawingLayer: DrawingLayer;

  private readonly polygonStyle = new Style({
    fill: new FillStyle({color: [255, 255, 255, 0.3]}),
    stroke: new StrokeStyle({color: "black", width: 2}),
    text: new TextStyle({
      font: "bold 14px \"Arial\"",
      fill: new FillStyle({color: "white"}),
      stroke: new StrokeStyle({ color: "#FFFFFF", width: 3 }),
      text: "Foobar",
      padding: [4, 4, 4, 4]
    })
  });

  private readonly whiteVerticesStyle = new Style({
    image: new RegularShape({
      fill: new FillStyle({color: "white"}),
      stroke: new StrokeStyle({color: "black", width: 1}),
      points: 4,
      radius: 6,
      angle: Math.PI / 4
    })
  });

  private readonly greyVerticesStyle = new Style({
    image: new RegularShape({
      fill: new FillStyle({color: "#c4c4c4"}),
      stroke: new StrokeStyle({color: "black", width: 1}),
      points: 4,
      radius: 6,
      angle: Math.PI / 4
    })
  });

  private readonly routedVerticesStyle = new Style({
    image: new RegularShape({
      // fill: new FillStyle({color: [150, 225, 120, 1]}),
      fill: new FillStyle({color: "white"}),
      stroke: new StrokeStyle({color: "black", width: 1.8}),
      // stroke: new StrokeStyle({color: [0, 121, 107, 1], width: 1.5}),
      points: 4,
      radius: 6,
      angle: Math.PI / 4
    })
  });

  private readonly greyRoutedVerticesStyle = new Style({
    image: new RegularShape({
      // fill: new FillStyle({color: [80, 170, 80, 1]}),
      fill: new FillStyle({color: "#c4c4c4"}),
      stroke: new StrokeStyle({color: "black", width: 1.8}),
      // stroke: new StrokeStyle({color: [0, 121, 107, 1], width: 1.5}),
      points: 4,
      radius: 6,
      angle: Math.PI / 4
    })
  });

  constructor(private polygonService: PolygonService, appstateService: AppstateService) {

    // subscribe to polygon changes
    polygonService.getPolygons(true).subscribe(polygons => {
      this.polygons = polygons;
      this.reRender();
    });

    // subscribe to hovered polygon
    polygonService.getPolygonHoveredObservable().subscribe(hoveredPolygon => {
      this.hoveredPolygon = hoveredPolygon;
      this.reRender();
    });

    // subscribe to current state
    appstateService.getStateObservable().subscribe((state) => {
      this.state = state;
      this.reRender();
    });
  }

  getFeatures(): Feature[] {
    const polygonFeatures: Feature[] = [];
    // Only render polygons that are not under construction. Render the hovered polygon last ==> It's lines are on top
    this.polygons.filter(polygon => !polygon.underConstruction).sort((a, b) => {
      if (a === this.hoveredPolygon) return 1;
      if (b === this.hoveredPolygon) return -1;
      return 0;
    }).forEach(polygon => {

      // get coordinates of polygon
      const polygonCoordinates = polygon.getOpenlayersRoutedCoordinates();
      const routedVertices = polygon.getOpenlayersCoordinates(undefined, v => v.isOnRoutableGeometry);
      const unroutedVertices = polygon.getOpenlayersCoordinates(undefined, v => {
        return !v.isOnRoutableGeometry && v.polygons.toArray()[0].equals(polygon);
      });

      // create polygon
      const feature = new Feature(new OlPolygon([polygonCoordinates]));
      const hovered = polygon === this.hoveredPolygon;
      feature.setProperties({type: "polygon", color: polygon.color, name: polygon.name, hovered: hovered});
      polygonFeatures.push(feature);

      // create vertices
      if (this.state === AppState.GisDraw ||
        this.state === AppState.GisNewPolygon ||
        this.state === AppState.AdminDraw ||
        this.state === AppState.AdminNewPolygon) {

        const routedVerticesFeature = new Feature(new MultiPoint(routedVertices));
        routedVerticesFeature.setProperties({type: "vertices", routed: "routed"});
        polygonFeatures.push(routedVerticesFeature);

        const unroutedVerticesFeature = new Feature(new MultiPoint(unroutedVertices));
        unroutedVerticesFeature.setProperties({type: "vertices"});
        polygonFeatures.push(unroutedVerticesFeature);
      }

    });
    return polygonFeatures;
  }

  setDrawingLayer(drawingLayer: DrawingLayer): void {
    this.drawingLayer = drawingLayer;
  }

  getStyle(feature: Feature): Style | Style[] {
    const type: string = feature.get("type");
    const routed: string = feature.get("routed");
    switch (type) {
      case "polygon":
        const color: Color = feature.get("color");
        const name: string = feature.get("name");
        const hovered: boolean = feature.get("hovered");
        const colorTransparent = color.alpha(0.3).array() as [number, number, number, number];
        const colorSemi = color.alpha(0.4).array() as [number, number, number, number];
        const colorSolid = color.alpha(0.8).array() as [number, number, number, number];
        if (hovered) {
          this.polygonStyle.getFill().setColor(colorSemi);
          this.polygonStyle.getStroke().setWidth(4);
        } else {
          this.polygonStyle.getFill().setColor(colorTransparent);
          this.polygonStyle.getStroke().setWidth(2);
        }
        this.polygonStyle.getStroke().setColor(colorSolid);
        const textStyle = this.polygonStyle.getText();
        textStyle.setText(name);
        if (color.luminosity() > 0.5) {
          textStyle.getFill().setColor(Color.rgb(80, 80, 80).array() as [number, number, number, number]);
        } else {
          textStyle.getFill().setColor(color.array() as [number, number, number, number]);
        }
        return this.polygonStyle;
      case "vertices":
        switch (this.state) {
          case AppState.GisNewPolygon:
          case AppState.AdminNewPolygon:
            if (routed) {
              return this.greyRoutedVerticesStyle;
            }
            return this.greyVerticesStyle;
          case AppState.GisDraw:
          case AppState.AdminDraw:
            if (routed) {
              return this.routedVerticesStyle;
            }
            return this.whiteVerticesStyle;
          default:
            throw Error("This should never be reached. Point geometry shall only be created when in drawing mode!");
        }
      default:
        throw Error("This should never be reached. feature type must be 'polygon' or 'vertices'");

    }
  }

  private reRender(): void {
    if (this.drawingLayer !== undefined) this.drawingLayer.triggerRepaint();
  }
}
