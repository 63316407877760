import { IStudentAppearanceStyler, StudentWithCoordinates } from "./student-appearence-styler";
import Style from "ol/style/Style";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import LineString from "ol/geom/LineString";
import { Student, Gender } from "../../../services/student/providers/student-provider";
import { ObjectBackedMap } from "../../../tools/object-backed-map";
import { RenderTools } from "../render-tools";

export abstract class ShapeStudentStyler implements IStudentAppearanceStyler {

  public static readonly STUDENT_X_SPACING = 2.5;
  public static readonly STUDENT_Y_SPACING = 0.8;

  abstract getStyle(feature: Feature, unitsPerPixel: number): Style;
  abstract getProperties(studentWithCoordinates: StudentWithCoordinates, hoveredStudent: Student): Object;
  protected abstract addStudentToLocation(student: Student, location: StudentLocation): void;

  getFeatures(students: Student[], unitsPerPixel: number, hoveredStudent: Student): Feature[] {

    const studentRadius = RenderTools.getStudentRadius(unitsPerPixel);
    const deltaY = studentRadius * ShapeStudentStyler.STUDENT_Y_SPACING * unitsPerPixel;
    const deltaX = studentRadius * ShapeStudentStyler.STUDENT_X_SPACING * unitsPerPixel;

    // stack students
    const groupedStudents = this.getStudentsGroupedByLocation(students);
    const studentsWithCoordinates: StudentWithCoordinates[] = [];
    const lineFeatures: Feature[] = [];
    groupedStudents.forEach((studentLocation) => {
      const numberOfGenders = this.getNumberOfClassTypes(studentLocation);
      let genderPos: number = -((numberOfGenders - 1) / 2);

      // organize students
      const perGenderStudentLists = [studentLocation.category1Students, studentLocation.category2Students, studentLocation.category3Students];
      perGenderStudentLists.forEach(s => {
        if (s.length > 0) {
          const studentYOffset = (numberOfGenders - 1) * deltaX / 2;
          const studentStack: StudentWithCoordinates[] = this.stackStudents(s, genderPos, deltaX, deltaY, studentYOffset);
          studentsWithCoordinates.push(...studentStack);
          genderPos += 1;
        }
      });

      // add line featuers
      const lastStudent = studentsWithCoordinates[studentsWithCoordinates.length - 1].student;
      const offsets = [];
      let offsetY: number;
      if (numberOfGenders === 3) {
        offsets.push(...[-1, 0, 1]);
        offsetY = deltaX;
      } else if (numberOfGenders === 2) {
        offsets.push(...[-0.5, 0.5]);
        offsetY = deltaX / 2;
      }
      offsets.forEach(offset => {
        lineFeatures.push(new Feature(new LineString([[lastStudent.x, lastStudent.y], [lastStudent.x + offset * deltaX, lastStudent.y + offsetY]])));
      });

    });

    // create a feature for each point
    const allFeatuers = studentsWithCoordinates.map(studentWithCoordinates => {
      const feature = new Feature(new Point(studentWithCoordinates.coordinates));
      feature.setProperties(this.getProperties(studentWithCoordinates, hoveredStudent));
      return feature;
    });
    allFeatuers.push(...lineFeatures);

    return allFeatuers;
  }

  protected getStudentsGroupedByLocation(students: Student[]): ObjectBackedMap<StudentLocation> {

    const groupedByLocation = students.reduce<ObjectBackedMap<StudentLocation>>((map, student) => {
      const key = student.x + "_" + student.y;
      if (map.contains(key)) {
        this.addStudentToLocation(student, map.get(key));
      } else {
        const location = new StudentLocation();
        this.addStudentToLocation(student, location);
        map.put(key, location);
      }
      return map;
    }, new ObjectBackedMap<StudentLocation>());

    return groupedByLocation;
  }

  private getNumberOfClassTypes(studentLocation: StudentLocation): number {
    const class1 = Math.min(studentLocation.category1Students.length, 1);
    const class2 = Math.min(studentLocation.category2Students.length, 1);
    const class3 = Math.min(studentLocation.category3Students.length, 1);
    return class1 + class2 + class3;
  }

  private stackStudents(students: Student[], xIndex: number, deltaX: number, deltaY: number, offsetY: number): StudentWithCoordinates[] {
    const studentStack: StudentWithCoordinates[] = students.map( (student, index) => {
      return {student: student, coordinates: [student.x + xIndex * deltaX, student.y + index * deltaY + offsetY] as [number, number], zIndex: index};
    });
    return studentStack;
  }
}

export class StudentLocation {
  public category1Students: Student[] = [];
  public category2Students: Student[] = [];
  public category3Students: Student[] = [];
}

