export class GeometryTools {

  /**
   * Finds the point closest to the given coordinate on the segment.
   * Ported from openlayers (ol/coordinate.js)
   * @param coordinate
   * @param segment
   */
  static closestOnSegment(coordinate: [number, number], segment: [number, number][]): [number, number] {
    const x0 = coordinate[0];
    const y0 = coordinate[1];
    const start = segment[0];
    const end = segment[1];
    const x1 = start[0];
    const y1 = start[1];
    const x2 = end[0];
    const y2 = end[1];
    const dx = x2 - x1;
    const dy = y2 - y1;
    const along = dx === 0 && dy === 0 ? 0 : (dx * (x0 - x1) + dy * (y0 - y1)) / (dx * dx + dy * dy || 0);
    let x: number, y: number;
    if (along <= 0) {
      x = x1;
      y = y1;
    } else if (along >= 1) {
      x = x2;
      y = y2;
    } else {
      x = x1 + along * dx;
      y = y1 + along * dy;
    }
    return [x, y];
  }

  static squaredDistanceToSegment(coordinate: [number, number], segment: [number, number][]) {
    return GeometryTools.squaredDistance(coordinate, GeometryTools.closestOnSegment(coordinate, segment));
  }

  static squaredDistance(a: [number, number], b: [number, number]) {
    const dX = a[0] - b[0];
    const dY = a[1] - b[1];
    return dX * dX + dY * dY;
  }
}
