import {Component, OnInit, Input, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef} from "@angular/core";
import {Vertex} from "../../services/geometry/providers/geometry-provider";
import {VertexTooltipService} from "../../services/vertex-tooltip.service";
import {MatMenuTrigger} from "@angular/material";
import {MousePositionService} from "../../services/mouse-position.service";
import {PolygonService} from "../../services/geometry/polygon.service";
import {fromEvent} from "rxjs";
import {take} from "rxjs/operators";

@Component({
  selector: "app-vertex-tooltip",
  templateUrl: "./vertex-tooltip.component.html",
  styleUrls: ["./vertex-tooltip.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VertexTooltipComponent implements OnInit {
  constructor(
    private polygonService: PolygonService,
    private vertexTooltipService: VertexTooltipService,
    private mousePositionService: MousePositionService,
    private cd: ChangeDetectorRef
  ) {}

  private currentVertex: Vertex;
  xyPos: Array<number> = [];
  private gonnaMove = false;

  @ViewChild(MatMenuTrigger)
  vertexMenu: MatMenuTrigger;

  ngOnInit(): void {
    this.vertexTooltipService.getObservable().subscribe(vertex => {
      this.xyPos = this.mousePositionService.currentCoordinates;
      if (this.vertexMenu.menuOpen) {
        this.gonnaMove = true;
        this.vertexMenu.closeMenu();
        setTimeout(() => {
          this.showMenu(vertex);
        }, 160);
      } else {
        this.showMenu(vertex);
      }
    });
  }

  private showMenu(vertex: Vertex): void {
    this.currentVertex = vertex;
    this.cd.markForCheck();
    setTimeout(() => {
      this.vertexMenu.openMenu();
      this.gonnaMove = false;
    });
  }

  get canSplit(): boolean {
    return this.currentVertex && this.currentVertex.polygons.length > 1;
  }

  delete(): void {
    this.polygonService.deleteVertex(this.currentVertex);
  }

  split(): void {
    this.polygonService.splitVertex(this.currentVertex);
  }

  menuOpened(): void {
    fromEvent(document, "click")
      .pipe(take(1))
      .subscribe(event => {
        if (!this.gonnaMove) this.vertexMenu.closeMenu();
      });
  }
}
