import {Injectable} from "@angular/core";
import {Observable, BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AppearanceService {
  constructor() {}

  private buildingAppearanceSubject: BehaviorSubject<BuildingAppearance> = new BehaviorSubject({redBuildingVisible: true,
                                                                                                blueBuildingVisible: true,
                                                                                                greenBuildingVisible: true,
                                                                                                orangeBuildingVisible: true});
  private state = StudentAppearence.PerimeterColor;
  private studentAppearenceBehaviorSubject = new BehaviorSubject<StudentAppearence>(this.state);

  getBuildingAppearanceObservable(): Observable<BuildingAppearance> {
    return this.buildingAppearanceSubject.asObservable();
  }

  setBuildingAppearance(buildingAppearance: BuildingAppearance): void {
    this.buildingAppearanceSubject.next(buildingAppearance);
  }

  getStudentAppearenceObservable(): Observable<StudentAppearence> {
    return this.studentAppearenceBehaviorSubject.asObservable();
  }

  setStudentAppearence(newState: StudentAppearence): void {
    if (this.state !== newState) {
      this.state = newState;
    }
    this.studentAppearenceBehaviorSubject.next(this.state);
  }
}

export class BuildingAppearance {
  redBuildingVisible: boolean;
  blueBuildingVisible: boolean;
  greenBuildingVisible: boolean;
  orangeBuildingVisible: boolean;
}

export enum StudentAppearence {
  PerimeterColor,
  GenderColor,
  GenderShape,
  ForeignColor,
  ForeignShape
}
