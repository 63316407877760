import {Component, OnInit, Inject, ViewChild, ElementRef, ChangeDetectionStrategy} from "@angular/core";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";
import {FormGroup, FormControl, Validators, ValidatorFn} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "app-combobox-dialog",
  templateUrl: "./combobox-dialog.component.html",
  styleUrls: ["./combobox-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComboboxDialogComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<ComboboxDialogComponent>, private translate: TranslateService, @Inject(MAT_DIALOG_DATA) public data: ComboboxDialogData) {}
    @ViewChild("combobox")
    mainInput: ElementRef;

    mainForm = new FormGroup({
    combobox: new FormControl("", [Validators.required])
  });

  selectedValue: string;

  get requiredError(): boolean {
    return this.mainForm.get("combobox").hasError("required");
  }

  get saveButtonLabel(): string {
    return this.data.saveButtonLabel || this.translate.instant("defaultDialogs.edit.buttons.save");
  }

  ngOnInit(): void {
    if(this.data.additionalInformation) {
      document.getElementById("additionalInformation").innerText = this.data.additionalInformation;
    }
  }

  getValues(): Array<string> {
    return this.data.values;
  }

  submit(): void {
    if (this.mainForm.valid) {
      this.dialogRef.close(this.mainForm.controls.combobox.value);
    }
  }
}

export interface ComboboxDialogData {
  dialogTitle: string;
  values: Array<string>;
  saveButtonLabel?: string;
  additionalInformation?: string;
}
